import React, { useEffect } from 'react'
import './TempleteFiltre.css'
import { Box, colors, Container, Grid, Typography } from "@mui/material";
import Footer from '../outil/Footer'
import { useParams, NavLink } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import NavFiltre from '../Navigation/NavFiltre'
import { useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const HorizontalLine = () => {
     return (
          <Box
               sx={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "divider",
                    mt: 3,
                    mb: 3,
               }}
          />
     );
};

export default function TempleteFiltre() {


     const mapContainerStyle = {
          height: "400px",
          width: "800px"
     };

     const center = {
          lat: 48.8566, // Paris
          lng: 2.3522
     };
     const [loading, setLoading] = useState(true);
     const { name, country, date } = useParams();

     const [results, setResults] = useState([]);
     const [filtrecon, setfiltre] = useState([]);
     const [error, setError] = useState('');

     const useQuery = () => {
          return new URLSearchParams(useLocation().search);
     };

     const query = useQuery();
     const param1 = query.get('name'); // Remplacez 'param1' par le nom du paramètre que vous recherchez
     const param2 = query.get('country'); // Même chose pour d'autres paramètres
     let param3 = query.get('date'); // Remplacez 'param1' par le nom du paramètre que vous recherchez
     const rith = document.querySelector('iframe')

     const param7 = query.get('categories');



     const filtrecat = async () => {

          try {
               const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/salons/filter/' + param7);
               setfiltre(response.data.data);
               setResults('')
               setError('');
               setLoading(false)
             //  console.log(response.data.data)
          } catch (error) {
               console.log(error.response.data.message)
               setError(error.response.data.message);
               setfiltre('');
          }

     };
     useEffect(() => {
          if (param7 !== null) {
               filtrecat()
              
          }
          setLoading(true)
     }, [param7])

     const [aucun, setaucun] = useState(false);
     function formatDate(inputDate) {
          // Vérifiez si la date est au bon format
          if (!/\d{4}-\d{2}-\d{2}/.test(inputDate)) {
               return 'Format de date invalide';
          }

          // Séparez les parties de la date
          const [year, month, day] = inputDate.split('-');

          // Formatez la date dans le format souhaité
          return `${day}-${month}-${year}`;
     }

     const handleSearch = async () => {
          setLoading(true)
          setError('');

          //console.log(typeof param3)

          try {
               const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/search-salons`, {
                    params: {
                         name: param1,
                         city: param2,
                         date: param3

                    }
               });
               /*     if(response.data.data.length=0){
                            setaucun(true);
                       }else{
                            setaucun(false);
                       }*/
               setfiltre('')
               setLoading(false);
               setResults(response.data.data);
               // console.log(response.data.data);
               if (rith) {

               }



               // Adaptez ceci à la structure de votre API
               // console.log(param1)
          } catch (err) {
               let juj = ''
               if (param1) {
                    juj = param1
               } else if (param2) {
                    juj = param2
               } else if (param3) {
                    juj = param3
               }

               console.error(err);
               setLoading(true);
               if (err.response) {
                    setError(err.response.data.message + "'" + juj + "'");
               }

               if (rith) {

               }
          }
     };


     useEffect(() => {

          handleSearch()

          // console.log('Params:', { name, country, date }); // Ajoutez cette ligne

     }, [param1, param2, param3])






     function showLocal() {
          const mapp = document.querySelector('.mapp')
          const btnfiltre = document.querySelector('.btnfiltre')
          if (mapp) {
               mapp.style.display = 'flex'
          }
          if (btnfiltre) {
               btnfiltre.style.display = 'none'
          }
     }
     useEffect(() => {
          window.scroll({ top: 0 })


     }, [])
     const [tabimage, settab] = useState([]);
     const [firstimg, setfirst] = useState('');
     const [second, settow] = useState('');
     const [free, setfree] = useState('');
     const [imageData, setImageData] = useState([]); // État pour les images
     /*  const gallery = async (id) => {
         let token = localStorage.getItem('token');
     
         // Check if token exists
         if (!token) {
           console.error('No authorization token found.');
           return; // Exit the function if there's no token
         }
     
         try {
           const response = await axios.get(
             `https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/images`,
             {}, // Request body
             {
               headers: {
                 'Authorization': `Bearer ${token}`,
               },
             }
           );
     
           console.log(response.data);
           // Optionally handle state update here
           settab(response.data)
           setfirst(response.data[0].image)
           settow(response.data[1].image)
           setfree(response.data[2].image)
         } catch (err) {
           console.error('Error occurred:', err);
         }
       };*/
     const gallery = async (id) => {
       
          let token = localStorage.getItem('token');
          if (!token) return;

          try {
               const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/images`, {
                    headers: { 'Authorization': `Bearer ${token}` },
               });
               setImageData(prevData => ({ ...prevData, [id]: response.data }));
          
          } catch (err) {
               console.error('Error occurred:', err);
          }
        
     };
     const handleImageLoad = () => {
         
        }
     return (
          <>
               <NavFiltre />
               <div className='TempleteFiltre' onLoad={handleImageLoad}>
               < div className='loading'> {loading ? <CircularProgress /> : ''}</div>
                    <p><i className='ti-home'></i>  Accueil  {results.length > 0 ? ' > ' + results[0].name : ''}</p>
                    <div className='redddd'>{error}</div>
                    {

                         filtrecon.length > 0 ?
                              filtrecon.map((salon, key) => {
                                   { gallery(salon.id) }
                                   const images = imageData[salon.id] || [];
                                   return (

                                        <div className='cardsFiltre' key={key}>

                                             <div className='leftFiltre'>
                                                  <h4>{salon.name}</h4>

                                                  <div className='imgFiltre'>
                                                       {images.length > 0 && (
                                                            <>
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[0]?.image}`} alt='' className='oneImg'     />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[1]?.image}`} alt='' className='towImg' />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[2]?.image}`} alt='' className='freeImg' />
                                                            </>
                                                       )}
                                                  </div>
                                                  <div className='descriptionfiltre'>
                                                       <p className='titre'>{salon.name}</p>
                                                       <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> {salon.notes_count} avis</p>
                                                       <p className='payse'>{salon.city} </p>
                                                       <p className='expert'><i className='ti-home'></i> Chez votre expert</p>

                                                  </div>
                                                  <div className='filtrejour'>
                                                       <div className='filtresem1'>
                                                            <h4>MATIN</h4>
                                                            <button className='select'>Mar 23</button>
                                                            <button className='select'>Mer 24</button>
                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>APRES-MIDI</h4>
                                                            <button className='select'>Mar 23</button>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>SOIR</h4>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                  </div>

                                                  <NavLink
                                                       className='plusdetaille'
                                                       to={`/detailsalon/${salon.id}`}

                                                  >


                                                       <p>

                                                            Je veux en savoir plus

                                                       </p>
                                                       <i className='ti-angle-down'></i>


                                                  </NavLink>






                                             </div>

                                        </div>



                                   )
                              })
                              : ''
                    }
                    {

                         results.length > 0 ?
                              results.map((salon, key) => {
                                   gallery(salon.id); // Appelle la fonction gallery ici
                                   const images = imageData[salon.id] || [];
                                   return (

                                        <div className='cardsFiltre' key={key}>

                                             <div className='leftFiltre'>
                                                  <h4>{salon.name}</h4>

                                                  <div className='imgFiltre'>
                                                  {images.length > 0 && (
                                                            <>
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[0]?.image}`} alt='' className='oneImg' />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[1]?.image}`} alt='' className='towImg' />
                                                                 <img src={`https://api-macerise.masovia-madagascar.com/storage/${images[2]?.image}`} alt='' className='freeImg' />
                                                            </>
                                                       )}
                                                  </div>
                                                  <div className='descriptionfiltre'>
                                                       <p className='titre'>{salon.name}</p>
                                                       <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> {salon.notes_count} avis</p>
                                                       <p className='payse'>{salon.city} </p>
                                                       <p className='expert'><i className='ti-home'></i> Chez votre expert</p>

                                                  </div>
                                                  <div className='filtrejour'>
                                                       <div className='filtresem1'>
                                                            <h4>MATIN</h4>
                                                            <button className='select'>Mar 23</button>
                                                            <button className='select'>Mer 24</button>
                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>APRES-MIDI</h4>
                                                            <button className='select'>Mar 23</button>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>SOIR</h4>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                  </div>

                                                  <NavLink
                                                       className='plusdetaille'
                                                       to={`/detailsalon/${salon.id}`}

                                                  >


                                                       <p>

                                                            Je veux en savoir plus

                                                       </p>
                                                       <i className='ti-angle-down'></i>


                                                  </NavLink>






                                             </div>

                                        </div>



                                   )
                              })
                              : ''
                    }
              

                    <div className='rightFiltre'>
                         <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9503398796586!2d-73.98531003867917!3d40.7594371793141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f711cf39%3A0x6c38ba916571686d!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1624681500506!5m2!1sen!2sus"
                              width="100%"
                              height="500px"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                         />
                    </div>
                    {aucun ? <p>salon non trouver</p> : ''}

               </div>
               <Footer />
          </>
     )
}
