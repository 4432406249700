import React from 'react'
import MenuFiltre from '../page_filtre/MenuFiltre'
import Logo from '../outil/Logo'
import Auth from '../outil/Auth'
import Filtre from '../page_filtre/Filtre'
export default function NavFiltre() {
  return (
    <header>
    <div className='simplemenu'>
              <Logo/>
              <MenuFiltre/> 
             <Auth/> 
      </div>
     <Filtre/> 
     </header>
  )
}
