import React from "react";
import './inscription4.css'

const Inscription4 =()=>{
    return(
        <>
            <div className="parent">
                {/* ligne 1 */}
                <div className="nox-1">
                    <div className="boxos-1"></div>
                    <div className="boxos-2"></div>
                </div>
                {/* ligne 2 */}
                <div className="nox-2">
                    <div className="boxos-3"></div>
                    <div className="boxos-4"></div>
                </div>
                {/* ligne 3 */}
                <div className="nox-3">
                    <div className="boxos-5"></div>
                    <div className="boxos-6"></div>
                </div>
                {/* ligne 4 */}
                <div className="nox-4">
                    <div className="boxos-7"></div>
                </div>
            
                    
                    
            </div>
        </>
    )
}
export default Inscription4