import React from 'react'
import './Footer.css'

import LogoFooter from './LogoFooter'
export default function Footer() {

  return (
    <footer>
      <div className="un">
            <div className="logof">
                  
                    <LogoFooter/>
            </div>
         <ul>
             <p>Macerise est la premier plateforme de reservation massage sensuelle à coté de chez vous! </p>
             <p>42 ,rue de la Mare aux Carats 93100 MONTREUIL </p>
             <p>contact@macerise.com</p>
         </ul>
            
         <p className='socio'>
                  <a href="/" className="link"><i className="ti-facebook"></i></a>
                  <a href="/" className="link"><i className="ti-twitter-alt"></i></a>
                  <a href="/" className="link"><i className="ti-google"></i></a>
                  <a href="/" className="link"><i className="ti-pinterest-alt"></i></a>
                  <a href="/" className="link"><i className="ti-instagram"></i></a>
                  <a href="/" className="link"><i className="ti-rss"></i></a>
                
          </p>
        </div>
      <div className="deux">
            <p className="tete">A propos de Macerise</p>
            <ul>
                <li><a href="/">Qui somme nous?</a></li>
                 <li><a href="/">Devenir pro</a></li>
                 <li><a href="/">CGV</a></li>
                 <li><a href="/">CGU</a></li>
                 <li><a href="/">Traitement de mes données</a></li>
                 <li><a href="/">FQA</a></li>
            </ul>
      </div>
      <div className="trois">
           <p className="tete">Nos services massages</p>
         <ul>
                <li><a href="/">Massage relaxant</a></li>
                <li><a href="/">Massage tantrique</a></li>
                <li><a href="/">Massage naturiste</a></li>
                <li><a href="/">Massage bodybody</a></li>
                <li><a href="/">Massage bodybody reciproque</a></li>
         </ul>
      </div>
      <div className="quatre">
              <p className="tete">Nos recherche frequents</p>
              <ul>
                <li><a href="/">Massage naturiste</a></li>
                <li><a href="/">Massage bodybody reciproque</a></li>
                <li><a href="/">Massage tantrique</a></li>
                <p>S'abonner à notrenewsletter</p>
              </ul>
             
              <form>
                  
                  <input type="text" placeholder="Adresse e-mail"/>
                  <button>Enregistrer</button>
              </form>
        
      </div>
  </footer>
  )
}
