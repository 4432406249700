import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./PMass.css";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  Input,
  IconButton,
} from "@mui/material";
import { AddAPhoto } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Configurer Axios pour pointer vers votre API
axios.defaults.baseURL = "https://www.api-macerise.masovia-madagascar.com/api";

export default function ProfilMass() {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [userData, setUserData] = useState({
    image: null, // Image from backend
    name: "",
    address: "",
    email: "",
    tel: "",
    city: "",
    country_id: "",
    jour: "",
    mois: "",
    annee: "",
  });
  useEffect(() => {
    fetchCountries();
    fetchUserData();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`/countries`);
      setCountries(response.data);
    } catch {
      console.error("Erreur lors du chargement des pays");
    }
  };

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`/edit/user/${userId}`);
      const data = response.data;
      const [year, month, day] = data.user.date_de_naissance.split("-");

      setUserData({
        name: data.user.name || "",
        address: data.user.address || "",
        email: data.user.email || "",
        tel: data.user.tel || "",
        city: data.user.city || "",
        country_id: data.user.country_id || "",
        jour: parseInt(day, 10),
        mois: months[parseInt(month, 10) - 1],
        annee: parseInt(year, 10),
        image: data.user.image || null, // Set the image URL
      });

      // Set the image preview to the user image from the database
      if (data.user.image) {
        setImagePreview(data.user.image); // Set the image preview from the backend data
      }
    } catch (error) {
      console.error(
        "Erreur lors du chargement des données utilisateur:",
        error
      );
    }
  };

  const token = localStorage.getItem("token");
  const takeSubmit = async (e) => {
    e.preventDefault();
    const { name, email, tel, address, annee, mois, jour, city, country_id } =
      userData;
    const date_de_naissance = `${jour}-${months.indexOf(mois) + 1}-${annee}`;

    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.put(
        `/update/user`,
        { name, email, tel, address, date_de_naissance, city, country_id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Réponse:", response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setImagePreview(fileURL); // Set the preview URL for the image

      // Prepare form data for image upload
      const formData = new FormData();
      formData.append("image", file);

      let token = localStorage.getItem("token");

      try {
        const response = await axios.post(`/update_ProfileImage`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Upload success:", response.data);

        // After successful upload, fetch updated user data including the new image
        fetchUserData(); // Refresh user data after image upload
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleUpload = async () => {
    // if (!selectedFile) return; // If no file selected, don't proceed
    // const formData = new FormData();
    // formData.append("image", selectedFile); // Append the image file to the form data
    // try {
    //   const response = await axios.post("YOUR_API_ENDPOINT_HERE", formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //   console.log("Upload success:", response.data);
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    // }
  };
  return (
    <>
      <div>
        <Box
          sx={{
            width: "200px",
            height: "200px",
            backgroundColor: "#D3D3D3",
            borderRadius: "110px",
            marginLeft: "550px",
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            overflow: "hidden",
          }}
          // onClick={() => document.getElementById('file-input').click()} // Trigger input click when box is clicked
        >
          <img
            src={`https://api-macerise.masovia-madagascar.com/storage/images/${userData.image}`}
            alt="User profile"
          />

          {/* Conditionally render the preview or user image */}
          {imagePreview ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(https://api-macerise.masovia-madagascar.com/storage/images/${userData.image})`, // Corrected backgroundImage
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "110px",
              }}
            />
          ) : (
            <AddAPhoto
              sx={{ color: "#F9194F", cursor: "pointer", fontSize: "24px" }}
            />
          )}

          <Input
            id="file-input"
            type="file"
            accept="image/*" // Accept only image files
            sx={{
              opacity: 0, // Hide the input
              position: "absolute",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onChange={handleFileChange} // Handle file selection and automatic upload
          />
        </Box>
      </div>
      <form onSubmit={takeSubmit}>
        <Box sx={{ marginTop: "50px" }}>
          <Typography variant="h5" gutterBottom sx={{ marginBottom: "20px" }}>
            Mon profil
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nom"
                name="name"
                variant="outlined"
                value={userData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresse email"
                name="email"
                variant="outlined"
                value={userData.email}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Adresse"
                name="address"
                variant="outlined"
                value={userData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Ville"
                name="city"
                variant="outlined"
                value={userData.city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Numéro de téléphone"
                name="tel"
                variant="outlined"
                value={userData.tel}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="country-label">Votre pays</InputLabel>
                <Select
                  labelId="country-label"
                  label="Votre pays"
                  name="country_id"
                  value={userData.country_id}
                  onChange={handleChange}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography>Date de naissance</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      name="jour"
                      value={userData.jour}
                      onChange={handleChange}
                    >
                      {days.map((d) => (
                        <MenuItem key={d} value={d}>
                          {d}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      name="mois"
                      value={userData.mois}
                      onChange={handleChange}
                    >
                      {months.map((m) => (
                        <MenuItem key={m} value={m}>
                          {m}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      name="annee"
                      value={userData.annee}
                      onChange={handleChange}
                    >
                      {years.map((y) => (
                        <MenuItem key={y} value={y}>
                          {y}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Enregistrer
          </Button>
        </Box>
      </form>
    </>
  );
}
