// @ts-nocheck
import {
  Typography,
  Stack,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Hidden,
  useTheme,
} from "@mui/material";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { daytoString, MonthToString } from "../utils/converDate";
import { MdAccessTimeFilled } from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CardEvent({ event }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("in Card", event);
  }, [event]);
  return (
    <Stack
      maxWidth={"700px"}
      minWidth={"300px"}
      sx={{
        width: {
          xs: "600px",
          sm: "100%",
          md: "100%",
          lg: "48%",
        },
      }}
      // flexGrow={"1"}
      direction={"row"}
      boxShadow={"0 0 10px rgba(0, 0, 0, 0.2)"}
    >
      <Box
        sx={{
          background: `center / cover no-repeat url("/image/2.png")`,
          width: {
            xs:"28%",
            sm: "28%",
            md: "38%",
            lg: "48%",
          },
          // height: "250px",
          flexGrow: "1",
        }}
      ></Box>
      <Stack p={2} gap={1} width={"55%"}>
        <Typography>{event.name}</Typography>
        <Divider></Divider>
        <Hidden smDown>
          <Typography fontSize={"14px"}>
            intervenants : Dr. Marie Dupont, Thérapeute; Alice Martin, Masseuse
            professionnelle, Dr Franck
          </Typography>
        </Hidden>
        <List sx={{ p: 0 }}>
          <ListItem sx={{ px: "0", py: "5px" }}>
            <ListItemIcon sx={{ minWidth: "25px" }}>
              <MdAccessTimeFilled size={20} />
            </ListItemIcon>
            <ListItemText>10h00 - 18h00</ListItemText>
          </ListItem>
          <ListItem sx={{ px: "0", py: "5px" }}>
            <ListItemIcon sx={{ minWidth: "25px" }}>
              <FaMapMarkerAlt size={20} />
            </ListItemIcon>
            <ListItemText>Zen Massage Center, Paris</ListItemText>
          </ListItem>
        </List>
        <Typography ml={"5px"}>prix : {event.price} €</Typography>
      </Stack>
      <Stack
        direction={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          m="10px 0px 0px 0px"
          direction={"column"}
          boxShadow={"0 0 10px rgba(0, 0, 0, 0.1)"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={"50%"}
          width={"100px"}
          height={"100px"}
        >
          <Typography fontWeight={600}>
            {daytoString(new Date(event.date).getDay())}{" "}
          </Typography>
          <Typography fontWeight={600} color={"#ff4dd8"} fontSize={"22px"}>
            {new Date(event.date).getDay()}{" "}
          </Typography>

          <Typography fontWeight={600} fontSize={"14px"}>
            {`${MonthToString(new Date(event.date).getMonth())}`}{" "}
            {new Date(event.date).getFullYear()}
          </Typography>
        </Stack>
        <Stack
          bottom={0}
          alignItems={"center"}
          justifyContent={"center"}
          m="0px 0px 10px 0px"
          //   border='1px solid black'
          gap={2}
        >
          <Typography textAlign={"center"}>
            {" "}
            {event.nombre_place} place disponible
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "#ff4dd8", "&:hover": { bgcolor: "black" } }}
            onClick={() => navigate("/evenement/" + event.id)}
          >
            Reservez
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
