import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

export default function Timer({ targetTime, targetDate }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    console.log("SM", smDown);

    const calculateTimeLeft = () => {
      // Crée la date cible à partir de la date et de l'heure données
      const [targetHours, targetMinutes, targetSeconds] = targetTime
        .split(":")
        .map(Number);
      const now = new Date();
      const futureDate = new Date(targetDate);
      futureDate.setHours(targetHours);
      futureDate.setMinutes(targetMinutes);
      futureDate.setSeconds(targetSeconds);

      // Calcule la différence en millisecondes
      const diffInMillis = futureDate - now;

      if (diffInMillis >= 0) {
        // Convertit la différence en jours, heures, minutes, et secondes
        const daysLeft = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((diffInMillis / (1000 * 60 * 60)) % 24);
        const minutesLeft = Math.floor((diffInMillis / (1000 * 60)) % 60);
        const secondsLeft = Math.floor((diffInMillis / 1000) % 60);

        // Met à jour les états
        setDays(daysLeft);
        setHours(hoursLeft);
        setMinutes(minutesLeft);
        setSeconds(secondsLeft);
      } else {
        // Si la date cible est déjà passée
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    // Nettoie l'intervalle à la fin
    return () => clearInterval(timer);
  }, [targetDate, targetTime]);

  return (
    <>
      <Box width={"fit-content"}>
        <Typography fontSize={smDown ?  "24px":"32px" }>
          {" "}
          {days} jour{days > 2 ? "s" : ""}
        </Typography>
        <Divider sx={{ backgroundColor: "white" }}></Divider>
      </Box>
      <Box width={"fit-content"}>
        <Typography fontSize={smDown ?  "24px":"32px" }> {hours} h</Typography>
        <Divider sx={{ backgroundColor: "white" }}></Divider>
      </Box>
      <Box width={"fit-content"}>
        <Typography fontSize={smDown ?  "24px":"32px" }>
          {" "}
          {minutes} min{" "}
        </Typography>
        <Divider sx={{ backgroundColor: "white" }}></Divider>
      </Box>
      <Box width={"fit-content"}>
        <Typography fontSize={smDown ?  "24px":"32px" }>
          {" "}
          {seconds} s{" "}
        </Typography>
        <Divider sx={{ backgroundColor: "white" }}></Divider>
      </Box>
    </>
  );
}
