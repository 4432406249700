import React from 'react';
/*import { Redirect, Route } from 'react-router-dom';*/
import { Navigate } from 'react-router-dom';
import Home from '../component/outil/Home';
import Accueil from '../component/accueilA/Accueil';
const RouteProteger = () => {
    let token = localStorage.getItem('token');
    let Role = localStorage.getItem('Role');
    let tokens = sessionStorage.getItem('token');
    let Roles = sessionStorage.getItem('Role');

   
   let   val=true;
   
    return (
        <>
      
            {  token !==null && token !=='undefined' && Role==='client'  || tokens !==null && tokens !=='undefined' && Roles==='client'  ?<Home />:<Accueil val={val}/> }
        </>
    );
};

export default RouteProteger;
