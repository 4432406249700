import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import './Myprofil.css'
import './Reservation.css'
export default function Reservation() {
  let token = ''
  const [events, setEvents] = useState([]);
  const gooo = async () => {
    try {
      const res = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/appointments_client",
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setEvents(res.data.reservations)
      console.log(res.data.reservations)
    } catch {
      console.log('erreur')
    }
  }
  function formatDate(inputDate) {
    // Vérifiez si la date est au bon format
    if (!/\d{4}-\d{2}-\d{2}/.test(inputDate)) {
      return 'Format de date invalide';
    }

    // Séparez les parties de la date
    const [year, month, day] = inputDate.split('-');

    // Formatez la date dans le format souhaité
    return `${day}-${month}-${year}`;
  }
  useEffect(() => {
    let statu = document.querySelectorAll('.status');
    statu.forEach(st => {
      if (st.textContent === 'refuser') {
        st.style.background = 'red'
        st.style.color = 'white'
      } else if (st.textContent === 'accepter') {
        st.style.background = 'green'
        st.style.color = 'white'
      }
    })

  })
  useEffect(()=>{

    let tokenx = localStorage.getItem('token')
    let tokens = sessionStorage.getItem('token')
    if (tokenx) {
      token = tokenx
    } else if (tokens) {
      token = tokens
    }
   },[])
  useEffect(() => {
    gooo()
  }, [])
  return (
    <div className="right">
      <p className='p'>Vos réservations</p>
      <table  >
        <thead>
          <tr>

            <th>Dates</th>
            <th>heurs</th>
            <th>Status</th>
            <th>services </th>
            <th>duration</th>
            <th>price</th>
          </tr>
        </thead>
        <tbody>



          {events ? events.map((event) => (
            <tr key={event.id}>
              <td style={{backgroundColor:'rgba(128, 128, 128, 0.384)'}}>{event.date}</td><td>{event.start_time}</td><td className='status'>{event.status === 'accepted' ? 'accepter' : event.status === 'canceled' ? 'refuser' : 'attente'}</td> <td> {event.service.name}</td><td>{event.service.duration}</td><td>{event.service.price}</td>


            </tr>

          )) : ''}





        </tbody>
      </table>
    </div>

  )
}
