import React from 'react'
import "./Menu.css"
import { NavLink } from 'react-router-dom'

export default function MenuConnecter() {
  return (
    <>
        <div className="menu">
                        <ul >
                          <li>
                          <NavLink
                            to="/"
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                          >
                          Votre métiers
                          </NavLink>
                          </li>
                            <li>
                            <NavLink
                            to="/blog"
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                          >Solutions et Fonctionnalités
                          </NavLink>
                            </li>
                          <li>
                          <NavLink
                            to="/tarif"
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }
                          >Tarifs
                          </NavLink>
                          </li>
                            <li><a href="/">Ressources</a></li>
                            <li>
                            <NavLink
                          
                            to="/contact"
                            className={({ isActive, isPending }) =>
                              isPending ? "pending" : isActive ? "active" : ""
                            }

                           >   Contact
                          </NavLink>
                          </li>
                          
                        </ul>
                </div>    
    </>
  )
}
