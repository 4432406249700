import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { TableContainer, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import { Scale } from "@mui/icons-material";

const Masse = () => {
  const useridc=localStorage.getItem('userId')
  const [userids, setiduserr] = useState("");
  const [name, setname] = useState("");
  const [liste, setliste] = useState("");
  const [events, setEvents] = useState([]);
  const [fav, setfav] = useState([]);
  const [content, setmessage] = useState();
  const [receiver_id, setreceiver_id] = useState();
  const fetmessage = async () => {
    let token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/messages/receiver",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setfav(response.data);
      console.log(response.data)
    } catch (err) {
      console.log(err);
    }
  };

  /* envouyer message*/

  const Envoyermessage = async (e) => {
    e.preventDefault();

    let token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/messages/send/" +
          userids,
        {
          content,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setfav(response.data.favorites_client);
      //  console.log(response)
      await lister(userids); // Récupérer la liste des messages après l'envoi
      setmessage(""); // Réinitialiser le champ de message
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetmessage();
  }, []);
  const CustomButton = styled(Button)(({ theme }) => ({
    "&:hover": {
      backgroundColor: "white", // Changez cela si vous voulez un effet différent
      boxShadow: "none", // Supprimez l'ombre
    },
  }));
  function convertir(dateString) {
    // Convertir la chaîne en objet Date
    const date = new Date(dateString);

    // Extraire l'heure et les minutes en temps local
    const y = date.getFullYear(); // Utiliser getHours pour le temps local
    const d = date.getDate(); // Utiliser getMinutes pour le temps local
    const m = date.getMonth() + 1; // Utiliser getMinutes pour le temps local
    // Ajouter un zéro devant les minutes si elles sont inférieures à 10
    //const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Retourner l'heure et les minutes au format hh:mm
    return d + ":" + m + ":" + y;
  }
  const lister = async (iduser) => {
    let token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/conversations/" +
          iduser,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setliste(response.data);
       console.log(response)
    } catch (err) {
      console.log(err);
    }
  };

  function goo(id, name) {
    setiduserr(id);
    setname(name);
    lister(id);
  }
  useEffect(() => {
    const goom = document.querySelector('.goom');
    if (goom) {
      goom.scrollTop = goom.scrollHeight; // Faire défiler vers le bas
    }
    const messp = document.querySelectorAll('.cardmm');
    messp.forEach(m => {
      if (m.getAttribute('id')!==useridc) { 
    
        m.style.justifyContent = 'flex-end';
        m.children[0].style.backgroundColor = '#fb174e';
      }else{
        m.style.justifyContent = 'flex-start';
        m.children[0].style.backgroundColor = 'gray';
      }
    });
}, [liste]);  // Ajoutez `liste` comme dépendance pour le défilement

  return (
    <div className="agenda">
     <h1>Messages</h1>
     
        <div className="messt">
          <div className="messagec">
            <div className="fixem">
              <p>Discussions</p>
            </div>
            <div className="messcrl">
              {fav.length>0
                ? fav.map((list, key) => {
                    return (
                      <div
                        key={key}
                        className="cardmess"
                        onClick={() =>
                          goo(list.sender.id, list.sender.name)
                        }
                      >
                        <img src="./image/logo3.png" alt=""></img>
                        <div>
                          <p>{list.sender.name}</p>
                        
                        </div>
                      </div>
                    );
                  })
                : <p className="ml10px">Auccun message</p>}
            </div>
          </div>
          <div className="affichem">
            <form className="sendm" onSubmit={Envoyermessage}>
              <input
                type="texte"
                value={content}
                onChange={(e) => setmessage(e.target.value)}
              ></input>
              <CustomButton
             
              disabled= { fav.length > 0 ? '' : 'disabled' }
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                sx={{
                  color: "#fb174e",
                  backgroundColor: "white",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "white", // Changez cela si vous voulez un effet différent
                    // Supprimez l'ombre
                  },
                }}
              ></CustomButton>
            </form>

            <div className="cardmessx">
              <img src="./image/logo3.png" alt=""></img>
              <div>
                <p>{name}</p>
                <p></p>
              </div>
            </div>
            <hr></hr>

            <div className="goom">
              {liste
                ? liste.map((list, key) => {
                    return (
                      <div className="cardemess" key={key}>
                        <p className="tempsm">{convertir(list.updated_at)}</p>
                        <div className="cardmm" id={list.receiver_id}>
                        
                          <p className="messlist">{list.content} </p>
                        </div>
                      </div>
                    );
                  })
                : "....."}
            </div>
          </div>
        </div>
   
    </div>
  );
};

export default Masse;
