import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DemoChart = ({ monthly }) => {
  const categories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ]

  const [chartState, setChartState] = useState({
    options: {
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: categories,
      },
      colors: ["#f91944"],
    },
    series: [
      {
        name: "Monthly",
        data: Array(12).fill(0),
      },
    ],
    title: {
      text: "Monthly Inflation in Argentina, 2002",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  });

  useEffect(() => {
    if (monthly) {
      const updatedData = [
        monthly?.["janvier"] || 0,
        monthly?.["fevrier"] || 0,
        monthly?.["mars"] || 0,
        monthly?.["avril"] || 0,
        monthly?.["mai"] || 0,
        monthly?.["juin"] || 0,
        monthly?.["juillet"] || 0,
        monthly?.["août"] || 0,
        monthly?.["septembre"] || 0,
        monthly?.["octobre"] || 0,
        monthly?.["novembre"] || 0,
        monthly?.["decembre"] || 0
      ];
      
      // Mettre à jour l'état du graphique avec les nouvelles données
      setChartState((prevState) => ({
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            data: updatedData,
          },
        ],
      }));
    }
  }, [monthly]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="bar"
            width="750"
            height={"400"}
          />
        </div>
      </div>
    </div>
  );
};

export default DemoChart;
