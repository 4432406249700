import {
  Typography,
  Stack,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavMain from "../Navigation/NavMain";
import NavPro from "../Navigation/NavPro";
import { daytoString, MonthToString } from "./utils/converDate";
import CardEvent from "./component/cardEvent";
import Slider from "react-slick";
import SliderEvent from "./component/sliderEvent";
import { useEffect, useState } from "react";
import { ListEvent } from "./utils/EventListapi";
import Footer from "../outil/Footer";

export default function Evenement() {
  const [dataEvent, setDataEvent] = useState([]);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    ListEvent()
      .then((res) => {
        setDataEvent(res.data["evenment"]);
      })
      .catch((error) => {
        console.log("Erreur Get List Event", error);
      });
  }, []);
  useEffect(() => {
    console.log("in Ev", dataEvent);
  }, [dataEvent]);
  return (
    <>
      <NavMain />
      {/* <Stack direction={"row"}> */}
      <SliderEvent dataEvent={dataEvent} />
      {/* </Stack> */}
      <Stack
        p={smDown ? "40px 10px" : "40px 40px"}
        direction={"column"}
        gap={2}
        // border={"1px solid black"}
      >
        <Typography fontWeight={600} fontSize={"23px"} width={"fit-content"}>
          Evenements disponible
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          gap={5}
          justifyContent={"space-between"}
        >
          {dataEvent &&
            dataEvent.map((event, i) => {
              return <CardEvent event={event} key={i} />;
            })}
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
