// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Menufiltre .recherche{
    z-index: 10;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    gap: 5px;
margin-top: 5px;
    padding: 10px;
    border:1px solid  rgba(128, 128, 128, 0.233) ;
    box-shadow: 1px 1px 2px  rgba(128, 128, 128, 0.233) ;;
}
.Menufiltre .que .gray{
    color: #4A4A4A;
    font-size:1vw ;
    background-color: #fff;
    margin: 0;
}

.Menufiltre .recherche button{

 
  
    background-color: #e6e0e0c4;
    color: black;
    border: none;
    border-radius: 4px;
    font-size:1.5vw;
    font-weight: 400;
    height: 50px;
    width: 50px;
    font-weight: bold;

}
.Menufiltre .recherche button:hover{
    cursor: pointer;
    background-color: #e6e0e0;
    scale: 0.99;
}
.recherche .que{
   font-size: 1.1vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-right:1px solid rgba(128, 128, 128, 0.233) ;
    padding-right: 5px;
    gap:5px;
}
.Menufiltre input{
    height: 40px;
    padding-left: 5px;
    border-radius:4px ;
    border:1px solid rgba(128, 128, 128, 0.233) ;
}
    
`, "",{"version":3,"sources":["webpack://./src/component/page_filtre/MenuFiltre.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;;IAE3B,QAAQ;AACZ,eAAe;IACX,aAAa;IACb,6CAA6C;IAC7C,oDAAoD;AACxD;AACA;IACI,cAAc;IACd,cAAc;IACd,sBAAsB;IACtB,SAAS;AACb;;AAEA;;;;IAII,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,iBAAiB;;AAErB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,WAAW;AACf;AACA;GACG,gBAAgB;IACf,aAAa;IACb,uBAAuB;IACvB,2BAA2B;IAC3B,sBAAsB;IACtB,kDAAkD;IAClD,kBAAkB;IAClB,OAAO;AACX;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,4CAA4C;AAChD","sourcesContent":["\n.Menufiltre .recherche{\n    z-index: 10;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n  \n    gap: 5px;\nmargin-top: 5px;\n    padding: 10px;\n    border:1px solid  rgba(128, 128, 128, 0.233) ;\n    box-shadow: 1px 1px 2px  rgba(128, 128, 128, 0.233) ;;\n}\n.Menufiltre .que .gray{\n    color: #4A4A4A;\n    font-size:1vw ;\n    background-color: #fff;\n    margin: 0;\n}\n\n.Menufiltre .recherche button{\n\n \n  \n    background-color: #e6e0e0c4;\n    color: black;\n    border: none;\n    border-radius: 4px;\n    font-size:1.5vw;\n    font-weight: 400;\n    height: 50px;\n    width: 50px;\n    font-weight: bold;\n\n}\n.Menufiltre .recherche button:hover{\n    cursor: pointer;\n    background-color: #e6e0e0;\n    scale: 0.99;\n}\n.recherche .que{\n   font-size: 1.1vw;\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    flex-direction: column;\n    border-right:1px solid rgba(128, 128, 128, 0.233) ;\n    padding-right: 5px;\n    gap:5px;\n}\n.Menufiltre input{\n    height: 40px;\n    padding-left: 5px;\n    border-radius:4px ;\n    border:1px solid rgba(128, 128, 128, 0.233) ;\n}\n    \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
