import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../authentification/Authentificationl";
import "./Dashb.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import ProfilDash from "./ProfilDash";
import Calendrier from "./Calendrier/Calendrier";
import TableRdv from "./TableRdv/TableRdv";
import ServiceMass from "../outil/serviMass/ServiceMass";
import Questions from "./FAQ/Questions";
// import Repon from "./FAQ/Repon";
const Dashbord = () => {
  const role = localStorage.getItem('Role');
  console.log(role)
  // Function to toggle the sidebar (assumes you have this function defined elsewhere)
  const toggelSidebar = () => {
    // Logic to toggle the sidebar
  };
  
  const [modal,setModal] = useState(false)
  const [token, Settoken] = useState(false);
  const navigate = useNavigate();

  function deconecter() {
  
    setModal(true)
  }
  const handledeconnection =()=>{
      logout();
    navigate("/tarif");
  }
  const handleCloseDel = () =>{
    setModal(false)
  }
  const [affichage, setAffichage] = useState(role === "admin" ? "profil" : "calendrier");

  const toogleComposantProf = () => {
    setAffichage("profil");
  };
  const toogleComposantCal = () => {
    setAffichage("calendrier");
  };
  const toogleComposantTab = () => {
    setAffichage("tableau");
  };
  const toogleComposantServM = () => {
    setAffichage("serviceMassage");
  };
  const toogleComposantquestion = () => {
    setAffichage("question");
  };

  return (
    <>
    <div className="app-admin-wrap layout-sidebar-compact sidebar-dark-purple sidenav-open clearfix">
      <div className="side-content-wrap" >
        <div
          className="sidebar-left-secondary rtl-ps-none"
          data-perfect-scrollbar
          data-suppress-scroll-x="true"

        >
          <i className="sidebar-close i-Close" onClick={toggelSidebar}></i>
          <header >
            <div
              class="sidebar-left-secondary rtl-ps-none"
              data-perfect-scrollbar
              data-suppress-scroll-x="true"
            // style={{background: "#F9194F"}}
            >
              <img
                style={{
                  width: "200px",
                  height: "160px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  position: "relative",
                  backgroundSize: "center",
                  top: "-40px",
                }}
                src="logomacerise.png"
                alt="Logo"
              />
              <ul
                className="navigation-left"
                style={{
                  background: "#F9194F",
                  display: "flex",
                  flexDirection: "column",
                  gap: "50px",
                  paddingTop: "50px",
                  marginTop: '-100px',
                  listStyleType: 'none',
                  height: '100vh'
                }}
              >
                {  role==='admin'?

                <li className={`nav-item ${affichage === "profil" ? "active" : ""}`} data-item="dashboard">
                  <div
                    className="nav-item-hold acc"
                    onClick={toogleComposantProf}
                  >
                    <div className="nav-text" style={{marginLeft:'50px',color:'white',cursor:'pointer',paddingTop:'10px'}}>Dashboard</div>
                  </div>
                  <div className="triangle"></div>
                </li>
                :''
                }
                <li className={`nav-item ${affichage === "calendrier" ? "active" : ""}`} data-item="dashboard">
                  <div
                    className="nav-item-hold calix"
                    onClick={toogleComposantCal}
                  >
                    <div className="nav-text" style={{marginLeft:'50px',color:'white',cursor:'pointer',paddingTop:'10px'}}>Calendrier</div>
                  </div>
                  <div className="triangle"></div>
                </li>
                <li className={`nav-item ${affichage === "tableau" ? "active" : ""}`} data-item="dashboard">
                  <div
                    className="nav-item-hold reservation"
                    onClick={toogleComposantTab}
                    style={{ width: '100%',cursor:'pointer' }}
                  >
                    <div className="nav-text" style={{marginLeft:'50px',color:'white',cursor:'pointer',paddingTop:'10px'}} >Vos reservations</div>
                  </div>
                  <div className="triangle"></div>
                </li>
                <li className={`nav-item ${affichage === "serviceMassage" ? "active" : ""}`} data-item="dashboard">
                  <div
                    className="nav-item-hold list_rdv"
                    onClick={toogleComposantServM}
                    style={{ width: '100%' ,cursor:'pointer'}}
                  >
                    <span className="nav-text" style={{ marginLeft: '50px', color: 'white' }}>Vos services</span>
                  </div>
                  <div className="triangle"></div>
                </li>
                {  role==='admin'?

                  <li className={`nav-item ${affichage === "question" ? "active" : ""}`} data-item="dashboard">
                    <div
                      className="nav-item-hold list_rdv"
                      onClick={toogleComposantquestion}
                    >
                      <span className="nav-text" style={{ marginLeft: '50px', color: 'white',cursor:'pointer' }}>FAQ  </span>
                    </div>
                    <div className="triangle"></div>
                  </li>
                    :''
                }

                <li className="nav-item" data-item="others">
                  <div
                    onClick={deconecter}
                    className="nav-item-hold power"
                    href="#"
                  >
                    <div className="nav-text" style={{marginLeft:'50px',color:'white',cursor:'pointer',paddingTop:'10px'}}>Exit</div>
                  </div>
                  <div className="triangle"></div>
                </li>
              </ul>
              
            </div>
          </header>
          <div className="submenu-area" data-parent="dashboard"></div>
        </div>
        <div>
          {/* affichage profil pour le dashboard  */}
          {affichage === "profil" ? <ProfilDash /> : ""}
          {/* affichage calendrier pour le dashboard  */}
          {affichage === "calendrier" ? <Calendrier /> : ""}
          {/* affichage tableau pour le dashboard  */}
          {affichage === "tableau" ? <TableRdv /> : ""}
          {/* affichage serviceMassage pour le dashboard  */}
          {affichage === "serviceMassage" ? <ServiceMass /> : ""}

          {affichage === "question" ? <Questions /> : ""}
        </div>
      </div>

    </div>
    <Dialog open={modal} onClose={handleCloseDel}>
        <DialogTitle>Deconnection</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Voulez vraiment vous Deconnectez
          </Typography> 
          <Button
          
          variant="contained"
          className="buton"
          sx={{ background: "#F9194F" }}
          onClick={handledeconnection}
        >
          oui
        </Button>
        <Button
          onClick={handleCloseDel}
          variant="contained"
          className="buton"
          sx={{ background: "#F9194F", marginLeft: "300px" }}
        >
          non
        </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Dashbord;
