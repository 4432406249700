// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact{
  
    height: auto;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: auto;
    gap: 2vw;
    background-color: #F3F3F3;
    padding-top:5vh;
    padding-bottom: 50px;
}
.contact .instruction{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 40vw;
    gap: 2vw;
}
.contact .formulaire{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 40vw;
    gap: 2vw;
}
.contact .formulaire input{
    height: 45px;
    width: 38vw;
    padding-left: 2VW;
    border:1px solid #D5E3EC;
}
.contact textarea{
    height: 148px;
    width: 38vw;
    padding: 2VW 0 0 2vw;
    border:1px solid #D5E3EC;
}
.contact button{
    height: 45px;
    width: 38vw;
    background-color: #F9194F;
    color:white;
    border-radius: 4px;
    border: none;
    font-weight: bold;
}
.contact i{
    padding-right: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/component/contact/Contact.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,QAAQ;IACR,yBAAyB;IACzB,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,QAAQ;AACZ;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,QAAQ;AACZ;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,WAAW;IACX,oBAAoB;IACpB,wBAAwB;AAC5B;AACA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".contact{\n  \n    height: auto;\n\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    margin: auto;\n    gap: 2vw;\n    background-color: #F3F3F3;\n    padding-top:5vh;\n    padding-bottom: 50px;\n}\n.contact .instruction{\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    width: 40vw;\n    gap: 2vw;\n}\n.contact .formulaire{\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    flex-direction: column;\n    width: 40vw;\n    gap: 2vw;\n}\n.contact .formulaire input{\n    height: 45px;\n    width: 38vw;\n    padding-left: 2VW;\n    border:1px solid #D5E3EC;\n}\n.contact textarea{\n    height: 148px;\n    width: 38vw;\n    padding: 2VW 0 0 2vw;\n    border:1px solid #D5E3EC;\n}\n.contact button{\n    height: 45px;\n    width: 38vw;\n    background-color: #F9194F;\n    color:white;\n    border-radius: 4px;\n    border: none;\n    font-weight: bold;\n}\n.contact i{\n    padding-right: 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
