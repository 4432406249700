import { React, useRef, useState, useEffect } from "react";
import "./Inscription2.css";
// import Inscription3 from "./Inscription3";
// import { useNavigate } from "react-router-dom";
import { registerr } from "../../../authentification/Authentificationl";
// import {verifi_code} from "../../../authentification/Authentificationl";
import axios from "axios";
const Inscription2 = ({ data }) => {
  // nom d'utilisateur dans est name
  const [name, setName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  // adrress mail
  const [email, setEmail] = useState("");
  // telephone
  const [tel, setTel] = useState("");
  // adress
  const [address, setAddress] = useState("");
  // date de naissance
  const [date_de_naissance, setDate_de_naissance] = useState("");
  //passwod
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [role_id, setRole] = useState(3);

  const [country_id, setCountry_id] = useState("");
  const [load, setload] = useState(false);
  const [error, setError] = useState("");
  const [succ, setSucc] = useState("");
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("/countries");
        setCountries(response.data);
       
      } catch (error) {
        console.error("Erreur de récupération des pays", error);
        setError("Erreur de récupération des pays.");
      }
    };
    fetchCountries();
  }, []);
  const takeSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setSucc("");
    setload(true);

    try {
      const userData = await registerr(
        name,
        email,
        tel,
        address,
        date_de_naissance,
        password,
        role_id,
        country_id
        // image
      );

      console.log("Connexion réussie", userData);
      data(3);
    } catch (error) {
      setload(false);
      setError(
        "Échec de la connexion. Veuillez vérifier vos informations d'identification."
      );
    }
  };
  function show() {
    data(1);
  }

  return (
    <>
      <div className="inscription-container">
        <div className="boxis-num-1">
          <div className="boxis-num-1-1">Bienvenue sur Macerise</div>
          <div className="boxis-num-1-2">
            Inscrivez-vous pour commencer Pro.
          </div>
        </div>
        <div className="boxis-num-2">
          <form onSubmit={takeSubmit}>
            <div className="block">
              <div className="block1">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="Nom d’utilisateur"
                />

                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  className="boxis-num-2-1-S"
                  placeholder="Adresse email"
                />
                <input
                  id="tel"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  required
                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="Numero téléphone"
                />
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="address"
                />
                <input
                  type="date"
                  id="date_de_naissance"
                  value={date_de_naissance}
                  onChange={(e) => setDate_de_naissance(e.target.value)}
                  placeholder="Date de naissance jj/mm/aa"
                  className="boxis-num-2-2"
                  required
                />
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Mot de passe"
                  className="boxis-num-2-2"
                />
                {/* <div className="boxis-num-2-1-S birth">
                  <p>Image</p>
                  <input
                    type="file"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div> */}
                <input
                  value={role_id}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  type="hidden"
                  className="boxis-num-2-1-S"
                  placeholder="role_id"
                />
                <select
                  id="country"
                  value={selectedCountry}
                  className="boxis-num-2-1-S"
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    setCountry_id(e.target.value);
                  }}
                >
                  <option value="">Sélectionnez un pays</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="block2">
              </div> */}
            </div>
            <button
              className="boxis-num-3 btnload "
              type="submit"
              //   onClick={show}
            >
              Suivant
            </button>
            <p className="error"> {error && <p>{error}</p>}</p>
            <p className="succ"> {succ && <p>{succ}</p>}</p>
          </form>
        </div>

        <div className="boxis-num-4">
          <div className="boxis-num-4-1"></div>
          <div className="boxis-num-4-2">Ou se connecter avec</div>
        </div>
        <div className="boxis-num-5">
          <div className="boxis-num-5-1">
            <div className="boxi-num-5-1-1">
              <img alt="" className="logo-google" src="./sary/logoG.png" />
              <div className="text-google">Google</div>
            </div>
            <div className="boxi-num-5-1-2">
              <img alt="" className="logo-facebook" src="./sary/facebook.jpg" />
              <div className="text-facebook">Facebook</div>
            </div>
          </div>
          <div className="boxis-num-5-2">
            <div className="boxi-num-5-2-1">
              <img alt="" className="logo-linkedin" src="./sary/link.png" />
              <div className="text-linkedin">Linkedin</div>{" "}
            </div>
            <div className="boxi-num-5-2-2">
              <img
                alt=""
                className="logo-instagram"
                src="./sary/téléchargement.jpg"
              />
              <div className="text-instagram">Instagram</div>
            </div>
          </div>
        </div>
        <div className="boxis-num-6">
          Vous avez déjà un compte ?
          <span className="boxis-style" onClick={show}>
            {" "}
            Se connecter
          </span>
        </div>
      </div>
    </>
  );
};
export default Inscription2;
