import React, { useState } from 'react'
import './NavMain.css'
import Logo from '../outil/Logo'
import Menu from '../outil/Menu'
import Auth  from '../outil/Auth'

export default function NavMain({val}) {
  return (
    <header>
              <div className='simplemenu'>
                        <Logo/>
                        <Menu   /> 
                       <Auth val={val} /> 
                </div> 
    </header>
  )
}
