import React from 'react'
import './Contact.css'
import axios from 'axios';
import Footer from '../outil/Footer'
import NavMain from '../Navigation/NavMain'
import { useEffect, useState } from 'react';
export default function Contact() {
   const [countries, setCountries] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
 
   useEffect(() => {
     const fetchCountries = async () => {
       try {
         const response = await axios.get('https://restcountries.com/v3.1/all');
         setCountries(response.data);
         setLoading(false);
       } catch (err) {
         setError(err);
         setLoading(false);
       }
     };
 
     fetchCountries();
   }, []);
 
   if (loading) return <div>Loading...</div>;
   if (error) return <div>Error: {error.message}</div>;
  return (
   <>
  <NavMain/>
  <ul>
      {countries.map(country => (
        <li key={country.cca3}>
          {country.name.common}
        </li>
      ))}
    </ul>
    <div className='contact'>
        <div className='instruction'>
         <p> <h4> Vous avez un question ?</h4>
            <h4>Laissez-vous un message.</h4>
         </p>  
            <p>Nous sommes ici pour vous aider. N'hésitez pas à nous contacter si vous avez besoin d'assistance.</p>
         <p>
         <h4>Contactez-nous</h4>
         <h4>Laissez un message  ou appelez-nous.</h4>
         </p>
              <p> <i className='ti-files'></i >25 , avenue de Bouvines 08200 SEDAN</p>
              <p><i className='ti-email'></i> contact@macerise.com</p>
              <p> <i className='ti-files'></i>06 xx xx xx xx</p>

        </div>
        <div className='formulaire'>
           <h4>Envoyez votre message.</h4>
           <input type='text' placeholder='Nom'></input>
           <input type='email' placeholder='Adresse email'></input>
           <textarea placeholder='Posez une question, ou dites simplement bonjour.'></textarea>
           <button>Send</button>
        </div>
    </div>


    <Footer/>
    </>
  )
}
