import axios from "axios";

export async function ListCountClient() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      "https://api-macerise.masovia-madagascar.com/api/client_count",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}
export async function ListCountMasseur() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      "https://api-macerise.masovia-madagascar.com/api/masseur_count",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function ListCountSallon() {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      "https://api-macerise.masovia-madagascar.com/api/salons_count",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}


export async function ListCountClientYear() {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://api-macerise.masovia-madagascar.com/api/client_count/month/2024",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }