// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header{
    position: sticky;
    top:0;
    max-width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
   
    box-shadow: 1px 1px 10px #D4D3D3;
    background-color: #fff;
    z-index: 100;
}
header .simplemenu{
  
    width: 97vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    background-color: #fff;
    z-index: 100;

}

`, "",{"version":3,"sources":["webpack://./src/component/Navigation/NavMain.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,KAAK;IACL,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,sBAAsB;;IAEtB,gCAAgC;IAChC,sBAAsB;IACtB,YAAY;AAChB;AACA;;IAEI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,sBAAsB;IACtB,YAAY;;AAEhB","sourcesContent":["header{\n    position: sticky;\n    top:0;\n    max-width: 100vw;\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    flex-direction: column;\n   \n    box-shadow: 1px 1px 10px #D4D3D3;\n    background-color: #fff;\n    z-index: 100;\n}\nheader .simplemenu{\n  \n    width: 97vw;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: auto;\n    background-color: #fff;\n    z-index: 100;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
