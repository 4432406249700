import React, { useEffect } from 'react'
import './Home.css'
import Myprofil from './Myprofil';
import { useState } from 'react';
import Message from './Message';
import Favoris from './Favoris';
import Address from './Address';
import Reservation from './Reservation';
import HistoriquePaiement from './HIistoriquePaiement';
import Securite from './Securite';
import Parametre from './Parametre';
import Footer from './Footer';
import NavMain from '../Navigation/NavMain';
import Navclient from '../Navigation/Navclient';
import Service from './Service';
export default function Home() {
  useEffect(() => {

    window.scroll({top:0})
  },[]);
            const [afficherComposantA, setAfficherComposantA] = useState('profil');
            const toggleComposantPro = () => {
              setAfficherComposantA('profil');
            };
          
            const toggleComposantfav = () => {
              setAfficherComposantA('favoris');
            };
            const toggleComposantMess = () => {
              setAfficherComposantA('message');
            };
            const toggleComposantReser = () => {
              setAfficherComposantA('reservation');
            };
            const toggleComposantHist = () => {
              setAfficherComposantA('paiement');
            };
            const toggleComposantSecur = () => {
              setAfficherComposantA('securite');
            };
            const toggleComposantParam = () => {
              setAfficherComposantA('parametre');
            };
            const toggleComposantService= () => {
              setAfficherComposantA('service');
            };
          
  return (
      <>
         <Navclient/>
               <div className="body">  
               <span>
                    <div className="left">
                    <p className="p">Mon compte</p>
                      <ul>
                   
                        <li onClick={toggleComposantPro}
                         className={afficherComposantA === 'profil' ?'activePro' : '' }
                        ><i className="ti-user"> </i> Profile</li>
                       
                        <li onClick={toggleComposantfav}
                        className={afficherComposantA === 'favoris' ? 'activePro' : '' }
                        ><i className="ti-heart"> </i> Vos favoris</li>
                        <li onClick={toggleComposantMess}
                        className=  {afficherComposantA === 'message' ? 'activePro': '' }  
                        ><i className="ti-comments"> </i> Messages</li>
                        <li onClick={toggleComposantReser}
                        className={afficherComposantA === 'reservation' ?'activePro' : '' }
                        ><i className="ti-calendar"></i>  Vos réservations</li>
                        <li onClick={toggleComposantHist}
                        className=  {afficherComposantA === 'paiement' ? 'activePro' : '' }
                        ><i className="ti-time"> </i> Historique de paiements</li>
                        <li onClick={toggleComposantSecur}
                        className={afficherComposantA === 'securite' ? 'activePro' : '' }
                        ><i className="ti-lock"> </i> Sécurité</li>
                       
                         <li onClick={toggleComposantParam}
                        className=   {afficherComposantA === 'parametre' ?  'activePro': '' }   
                        ><i className="ti-settings"> </i> Paramètres</li>
                    </ul>
                
                    </div>
                    {/* <button className="btn1">Se déconnecter</button> */}
                </span>
                    
                {afficherComposantA === 'profil' ?  <Myprofil/> : '' }
                {afficherComposantA === 'address' ?  <Address/> : '' }
                {afficherComposantA === 'favoris' ?  <Favoris/> : '' }
                {afficherComposantA === 'message' ?  <Message/> : '' }    
                {afficherComposantA === 'reservation' ?  <Reservation/> : '' }
                {afficherComposantA === 'paiement' ?  <HistoriquePaiement/> : '' }
                {afficherComposantA === 'securite' ?  <Securite/> : '' }
                {afficherComposantA === 'parametre' ?  <Parametre/> : '' }    
                {afficherComposantA === 'service' ?  <Service/> : '' }    
                      </div>
                      <Footer/>
    </>
  )
}
