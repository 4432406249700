import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import { servicemasseur } from "../../../authentification/Authentificationl";
import axios from "axios";
import { update } from "../../../authentification/Authentificationl";
import { duration } from "moment";
import Message from "./Message";

const ServiceMass = () => {
  const [error, setError] = useState("");
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [dur, setDur] = useState("");
  const [price, setPrice] = useState("");
  // const [cat_id, setCat_id] = useState("");
  const [selectC, setSelectC] = useState("");
  const [editingItem, setEditingItem] = useState(null);
  const [editName, setEditName] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [message,setMessage]=useState('')
  // modification :
  // const [nameMod, setnameMod] = useState("");
  // const [descMod, setDescMod] = useState("");
  // const [priceMod, setPriceMod] = useState("");
  const [cat_id, setCat_id] = useState("");
  // const [durMod, setDurMod] = useState("");
  const [names, setNames] = useState("");
  const [modification, setModification] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState();
  const [pivot, setPivot] = useState("");
  const userId = localStorage.getItem("userId");
  const [salon, setSalon] = useState([]);
  const [selectS, setSelectS] = useState("");
  const [salon_id, setSalon_id] = useState("");
  const [service, Setservice] = useState([]);
  const [tabvalue, setTabvalue] = useState({
    serid: "",
    name: "",
    names: "",
    description: "",
    price: "",
    duration: "",
    category_id: "",
  });

  const [openDel, setOpenDel] = useState(false);
  const [validation,setValidation] = useState(false)
  const [selectedId, setSelectedId] = useState(null);
  // affichage menu categorie dans les select
  const fetchCathegory = async () => {
    try {
      const response = await axios.get("/categories");
      setCategories(response.data.category);
   //   console.log("data cat :", response.data.category);
    } catch (error) {
      console.error("Erreur de récupération des pays", error);
      setError("Erreur de récupération des pays.");
    }
  };
  // fetchSalon
  const fetchSalon = async () => {
    try {
      const response = await axios.get(`/get/salon/user/${userId}`);
      setSalon(response.data.salons);
      console.log("data salon :", response.data.salons);
    } catch (error) {
      console.error("Erreur de récupération des pays", error);
      setError("Erreur de récupération des pays.");
    }
  };
  // fectService
  const fectService = async () => {
    try {
      const response = await axios.get(`/salons/${userId}/services`);
      Setservice(response.data.salons);
      setPivot(response.data.salons.services);
      console.log("service:", service);
    } catch {
      console.log("erreur ");
    }
  };

  //UPDATE

  const handleEditItem = (
    serid,
    names,
    category_id,
    name,
    description,
    duration,
    price
  ) => {
    setTabvalue((prevForm) => ({
      ...prevForm,
      serid: serid,
      names: names,
      category_id: category_id,
      name: name,
      description: description,
      duration: duration,
      price: price,
    }));
    setOpenEdit(true);
    console.log("serid", serid);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditingItem(null);
  };

  const token = localStorage.getItem("token");

  // console.log('tokene',token)

  const handleUpdateItem = async (e) => {
    e.preventDefault();
  const { serid,names,category_id, name, description, duration, price } = tabvalue;
    // console.log('tablveu',tabvalue)
    try {
      console.log('tablveu',tabvalue)
      const response = await axios.put(
        `https://api-macerise.masovia-madagascar.com/api/update/service/${serid}`,
        { 
          names,category_id, name, description, duration, price },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'application/json'
          },
        }
      );

      setOpenEdit(false);
      fetchCathegory();
      fectService();
      fetchSalon();
    } catch (error) {
      console.error("Erreur lors de la mise à jour :", error.response?.data || error.message);
    }
  };
  const [dataSup,setDataSup]=useState('')
  // DELETE
  // overture du modal
  const handleDeleteItem = (id) => {
    setSelectedId(id);
    setOpenDel(true);
  };
  // fonction qui se declenche lorsque le client appuie sur validation
  const handleValidation =()=>{
    setValidation(true)
    setOpenDel(false)
  }

  useEffect(() => {
    if (validation && selectedId) {
      handleDeleteItem2(selectedId);
      setValidation(false); // Réinitialiser la validation après suppression
    }
  }, [validation, selectedId]);
  const handleCloseDel = () =>{
    setOpenDel(false)
  }

  const handleDeleteItem2 = async (id) => {
    try {
      const response = await axios.delete(
        `https://api-macerise.masovia-madagascar.com/api/service/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Mettre à jour les données après suppression
      fetchCathegory();
      fectService();
      fetchSalon();
    } catch (error) {
      // Gestion des erreurs
      console.error("Erreur lors de la suppression :", error);
    }
  };
  
  // POST

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const handleAddItem = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const newData = await servicemasseur(
        salon_id,
        cat_id,
        name,
        desc,
        dur,
        price
      );
      console.log("newData:", newData);
      fetchCathegory();
      fectService();
      fetchSalon();
      setMessage('ok')
    } catch (error) {
      setError(
        "Échec de la connexion. Veuillez vérifier vos informations d'identification."
      );
    }
  };

  useEffect(() => {

    fetchCathegory();
    fectService();
    fetchSalon();
  }, []);

  return (
    <TableContainer
      className="main-content-wrap d-flex flex-column"
      component={Paper}
      sx={{
        width: "100%",
        position: "relative",
        top: "10px",
      }}
    >
      {/* {message === 'ok'? <Message/>:''} */}
    <Typography style={{fontSize:'25px'}}>Liste des services massage </Typography>
      <div style={{ padding: 16 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAdd(true)}
          sx={{ background: "#F9194F" }}
        >
          Ajouter
        </Button>
      </div>
      <Table>
  <TableHead>
    <TableRow>
      <TableCell>Salon de massage</TableCell>
      <TableCell>Categories</TableCell>
      <TableCell>Nom du service</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Duration</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {service.map((item) => (
    <React.Fragment key={item.salon.id}>
      {item.services.length > 0 ? (
        item.services.map((ser) => (
          <TableRow key={ser.id}>
            <TableCell>{item.salon.name}</TableCell>

            {/* Debugging and filtering categories */}
            {console.log("Service ID:", ser.id, "Category ID:", ser.category_id)}
            {categories.filter(cat => cat.id == ser.category_id).length > 0 ?
              categories.filter(cat => cat.id == ser.category_id).map((cat) => (
                <TableCell key={cat.id}>{cat.name}</TableCell>
              )) :
              <TableCell>No category found</TableCell>  // Debug output when no category matches
            }

            <TableCell>{ser.name}</TableCell>
            <TableCell>{ser.description}</TableCell>
            <TableCell>{ser.duration}</TableCell>
            <TableCell>{ser.price}</TableCell>
            <TableCell>
              <IconButton
                onClick={() =>
                  handleEditItem(
                    ser.id,
                    item.salon.id,
                    ser.category_id,  
                    ser.name,
                    ser.description,
                    ser.duration,
                    ser.price
                  )
                }
              >
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDeleteItem(ser.id)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      ) : ''}
    </React.Fragment>
  ))}
</TableBody>

</Table>


      {/* Modal pour l'ajout d'un nouvel élément */}
      <Dialog open={openAdd} onClose={handleCloseAdd}>
        <DialogTitle>Ajouter une nouvelle ligne</DialogTitle>
        <DialogContent>
          <form onSubmit={handleAddItem} className="dispa">
            <FormControl fullWidth>
              <InputLabel id="category-select-label">
                Salon de massage
              </InputLabel>
              <Select
                labelId="salon-select-label"
                id="salon-select"
                value={selectS}
                onChange={(e) => {
                  setSelectS(e.target.value);
                  setSalon_id(e.target.value);
                }}
                label="salon"

                // onChange={handleChange}
              >
                {salon.map((sal) => (
                  <MenuItem key={sal.id} value={sal.id}>
                    {sal.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Nom du service"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="outlined"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
            <TextField
              margin="dense"
              type="time"
              label="Durée"
              fullWidth
              variant="outlined"
              value={dur}
              onChange={(e) => setDur(e.target.value)}
            />
            <TextField
              margin="dense"
              type="number"
              label="Prix"
              fullWidth
              variant="outlined"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={selectC}
                onChange={(e) => {
                  setSelectC(e.target.value);
                  setCat_id(e.target.value);
                }}
                label="Category"

                // onChange={handleChange}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F" }}
              onClick={() => setOpenAdd(false)}
            >
              Ajouter
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* Modal pour la modification d'un élément existant */}

      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Modification</DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdateItem} className="dispa">
            <FormControl fullWidth>
              <InputLabel id="salon-select-label">Salon de massage</InputLabel>
              <Select
                labelId="salon-select-label"
                id="salon-select"
                value={tabvalue.names} // This value is now linked to the selected salon name
                onChange={(e) => {
                  setTabvalue((prev) => ({
                    ...prev,
                    names: e.target.value, // Update the `names` field in the `tabvalue` state
                  }));
                }}
                label="Salon"
              >
                {salon.map((sal) => (
                  <MenuItem key={sal.id} value={sal.id}>
                    {sal.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              margin="dense"
              label="Name"
              fullWidth
              variant="outlined"
              value={tabvalue.name}
              onChange={(e) =>
                setTabvalue((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              variant="outlined"
              value={tabvalue.description}
              onChange={(e) =>
                setTabvalue((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
            <TextField
              margin="dense"
              type="time"
              label="Durée"
              fullWidth
              variant="outlined"
              value={tabvalue.duration}
              onChange={(e) =>
                setTabvalue((prev) => ({
                  ...prev,
                  duration: e.target.value,
                }))
              }
            />
            <TextField
              margin="dense"
              type="number"
              label="Prix"
              fullWidth
              variant="outlined"
              value={tabvalue.price}
              onChange={(e) =>
                setTabvalue((prev) => ({
                  ...prev,
                  price: e.target.value,
                }))
              }
            />
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={tabvalue.category_id}
                onChange={(e) =>
                  setTabvalue((prev) => ({
                    ...prev,
                    category_id: e.target.value,
                  }))
                }
                label="Category"
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F" }}
            >
              Envoyer
            </Button>
            <Button
              onClick={handleCloseEdit}
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F", marginLeft: "300px" }}
            >
              Annuler
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={openDel} onClose={handleCloseDel}>
        <DialogTitle>Suppression</DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Voulez vraiment supprimer ?
          </Typography>
        
          <Button
          
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F" }}
            onClick={handleValidation}
          >
            Supprimer
          </Button>
          <Button
            onClick={handleCloseDel}
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F", marginLeft: "300px" }}
          >
            Annuler
          </Button>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};

export default ServiceMass;
