// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fermm{
    position: absolute;
    right: 5px;
    color: red;
    top: 5px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #8a757598;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    scale: 0.9;
}
.fermm:hover{
    scale: 1;
}`, "",{"version":3,"sources":["webpack://./src/component/Dashbord/salon/Salon.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,UAAU;AACd;AACA;IACI,QAAQ;AACZ","sourcesContent":[".fermm{\n    position: absolute;\n    right: 5px;\n    color: red;\n    top: 5px;\n    height: 30px;\n    width: 30px;\n    border-radius: 100%;\n    background-color: #8a757598;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    scale: 0.9;\n}\n.fermm:hover{\n    scale: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
