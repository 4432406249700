// src/App.js
import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
} from "@mui/material";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import axios from "axios";
import { Margin } from "@mui/icons-material";
import "./cal.css";
const Salonday = () => {
    const id = localStorage.getItem("idsalon");
    const [truee, settrue] = useState(false);
    const [validation, setvalidation] = useState(false);
    const [falsee, setfalsee] = useState(false);
    const [messagex, setmessagex] = useState("");
    const [messagexa, setmessagexa] = useState("");
    const [events, setEvents] = useState([]);
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const messagexx = document.querySelector(".popx");
    const messagexxf = document.querySelector(".popxf");
    const [jour, setjour] = useState("");
    const [selectrolef, setSelectrolef] = useState("");
    const [salonid, setsalonid] = useState([]);
    //const [midi, setmidi] = useState(false);
    const [valid, setvalid] = useState(false);
    const fetchEventssalon = async () => {
        let id_users = localStorage.getItem("userId");

        // console.log(id_salon)
        try {
            const response = await axios.get(
                "https://www.api-macerise.masovia-madagascar.com/api/get/salon/user/" +
                id_users
            );
            setsalonid(response.data.salons);
            // console.log(response.data.salons[0].id)
        } catch {
            console.log("erreur");
        }

        //console.log(response.data.salons[0].id)
    };
    const fetchEvents = async () => {
        const id = localStorage.getItem("idsalon");

        // let idsalon=salonid[0].id ;
        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/days/salons/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(response.data.data);
            // Vous pouvez ajouter une vérification si les données existent avant de les utiliser
            if (response.data) {
                // Décommentez la ligne ci-dessous si vous souhaitez définir l'état des événements
                setEvents(response.data.data);
                //  console.log(response.data);
            } else {
                //     console.error('Aucune donnée disponible dans la réponse.');
            }
        } catch (error) {
            // Gérer les erreurs ici
            console.error("Erreur lors de la récupération des événements:", error);
        }
    };
    useEffect(() => {
        fetchEventssalon();
        fetchEvents();
    }, []);

    const [form, setForm] = useState({
        day_id: "",
        start_time: "",
        end_time: "",

        salon_id: "",

        status: "",
    });

    const handleChangeday_id = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id = (e) => {
        //console.log(e.target.value)
        setForm((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus = (e) => {
        setForm((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    const [form2, setForm2] = useState({
        day_id: "",
        start_time: "",
        end_time: "",
        salon_id: "",
        status: "",
    });
    const handleChangeday_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm2((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm2((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    /*const handleChange2 = (e) => {
      const { name, value } = e.target;
      setForm2((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    };
  */
    const [form3, setForm3] = useState({
        timeid: "",
    });

    /* const handleSubmit = (e) => {
       e.preventDefault();
       // Vous pouvez ici traiter les données du formulaire ou les envoyer à une API
       console.log(form);
     };*/
    const handleSubmit = async (e) => {
        e.preventDefault();
        //  console.log(salonid)
        if (salonid) {
            // console.log(form)

            /*  const dataToSend = {
                date: selectedDate.format('YYYY-MM-DD'),  // Format de la date
                start_time: startTime.format('HH:mm'), // Format de l'heure de début
                end_time: endTime.format('HH:mm') // Format de l'heure de fin
              };
          */
            try {
                const res = await axios.post(
                    "https://www.api-macerise.masovia-madagascar.com/api/add_time",
                    {
                        day_id: form.day_id,
                        start_time: form.start_time,
                        end_time: form.end_time,
                        salon_id: id,
                        status: form.status,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setmessagex("ajout avec success ");
                setForm(() => ({

                    day_id: "",
                    start_time: "",
                    end_time: "",
                    salon_id: "",
                    status: "",
                }));
                setfalsee(false);
                if (messagexxf) {
                    messagexxf.style.display = "none";
                }
                settrue(true);
                if (messagexx) {
                    messagexx.style.display = "flex";
                }
                setTimeout(() => {
                    if (messagexx) {
                        messagexx.style.display = "none";
                    }
                    settrue(false);
                }, 6000);
                //  console.log(res)
            } catch (error) {
                console.error(
                    "Erreur lors de l'envoi des données à l'API:",
                    error.response.data.messages.end_time
                );
                setmessagexa(error.response.data.messages.end_time);
                settrue(false);
                setfalsee(true);
                if (messagexx) {
                    messagexx.style.display = "none";
                }
                if (messagexxf) {
                    messagexxf.style.display = "flex";
                }
                setTimeout(() => {
                    if (messagexxf) {
                        messagexxf.style.display = "none";
                    }
                    setfalsee(false);
                }, 6000);
            }

            fetchEvents();
        }
    };
    const forma = (time) => {
        // Vérifier si le format d'entrée est valide
        const timeParts = time.split(":");
        if (timeParts.length === 3) {
            return `${timeParts[0]}:${timeParts[1]}`; // Retourner les heures et les minutes
        }
        return time; // Retourner la chaîne d'origine si le format est incorrect
    };

    const handleEdit = async (eventid, start, end, status, idsalon, idtime) => {
        setmidi(true);
        // Using moment.js
        //const moment = require('moment'); // if using Node.js, otherwise just include via CDN
        //let startf = moment(start, 'HH:mm:ss'); // initializes with the time
        // let endf = moment(end, 'HH:mm:ss');
        // Now you can call format
        // console.log(startf.format('HH:mm:ss'), endf.format('HH:mm:ss'), eventid, status[0], idsalon[0]);

        // console.log(eventid, start, end, status, idsalon, idtime)
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        console.log(idsalon)
        setForm2((prevForm) => ({
            ...prevForm, // Conserver les valeurs précédentes
            day_id: parseInt(eventid), // mettre à jour la date
            start_time: start, // mettre à jour l'heure de début
            end_time: end, // mettre à jour l'heure de fin
            salon_id: parseInt(idsalon),
            status: status,
        }));
        setForm3((prevForm) => ({
            ...prevForm, // Conserver les valeurs précédentes
            timeid: idtime, // mettre à jour la date
            // mettre à jour l'heure de début
        }));
    };
    const formatTime = (time) => {
        // Añadir ":00" al final de la cadena de tiempo.
        return `${time}:00`;
    };
    const shandleEdit = async (e) => {
        e.preventDefault();
        setmidi(false);
        let start = JSON.stringify(form2.start_time).replace(/[\[\]"]/g, "");
        console.log(JSON.stringify(form2.start_time).replace(/[\[\]"]/g, ""));
        let end = JSON.stringify(form2.end_time).replace(/[\[\]"]/g, "");
        let eventidd = form3.timeid;
        console.log('edit :', form2);
        try {
            const res = await axios.put(
                "https://www.api-macerise.masovia-madagascar.com/api/update/time/" +
                eventidd,
                {
                    day_id: form2.day_id,
                    salon_id: id,
                    start_time: start,
                    end_time: form2.end_time,
                    status: form2.status,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setmessagex("modification avec success ");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
                settrue(false);
            }, 6000);
            console.log(res);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }
        fetchEvents();
    };
    const [idsup, setidsup] = useState();
    function handleDeletex(ideventx) {
        setvalid(true);
        setidsup(ideventx);
    }
    const handleDelete = async () => {
        //console.log(ideventx)
        setvalid(false);
        let ideventx = idsup;

        try {
            const res = await axios.delete(
                "https://www.api-macerise.masovia-madagascar.com/api/delete/time/" +
                ideventx,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès ");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }

            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
                settrue(false);
            }, 6000);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }

        // Rafraîchir les événements après la suppression
        fetchEvents();
    };

    const [midi, setmidi] = useState(false);
    const formatTimex = (time) => {
        // Utilise une expression régulière pour extraire l'heure et les minutes
        const match = time.match(/^(\d{2}:\d{2}):\d{2}$/);
        // Si le format correspond, retourne l'heure et les minutes
        return match ? match[1] : time;
      };
    return (
        <>
            <div className="transalon"></div>
            {valid ? (
                <div className="validation">
                    <span className="spann">
                        <p>Voulez-vous vraiment le supprimer? </p>
                        <span>
                            <button onClick={handleDelete}>OUI</button>
                            <button onClick={() => setvalid(false)}>NON</button>
                        </span>{" "}
                    </span>{" "}
                </div>
            ) : (
                ""
            )}
            {truee ? <div className="popx">{messagex}</div> : ""}
            {falsee ? <div className="popxf">{messagexa}</div> : ""}
            <Box className="agenda"

            >
                <h1 style={{ marginTop: '50px' }}>Gestionnaire d'Agenda</h1>
                <hr />
                <form onSubmit={handleSubmit} className="form4m"

                >
                    <Box >
                        <p> jour</p>
                        <select
                            required
                            name="day_id"
                            onChange={handleChangeday_id}
                            value={form.day_id}
                            className="boxis-num-2-1-S"
                        >
                            <option value="">jour</option>
                            <option value="1">Lundi</option>
                            <option value="2">Mardi</option>
                            <option value="3">Mercredi</option>
                            <option value="4">Jeudi</option>
                            <option value="5">Vendredi</option>
                            <option value="6">Samedi</option>
                            <option value="7">Dimanche</option>
                        </select>
                    </Box>
                    <div>
                        <p> status</p>
                        <select
                            required
                            name="status"
                            value={form.status}
                            className="boxis-num-2-1-S"
                            onChange={handleChangestatus}
                        >
                            <option value="">status</option>
                            <option value="ouvert">ouvert</option>
                            <option value="fermé">fermé</option>
                        </select>
                    </div>

                    <div>
                        <p>Heure d'ouverture</p>
                        <input
                            type="time"
                            name="start_time"
                            value={form.start_time}
                            onChange={handleChangestart_time}
                            required
                        />
                    </div>
                    <div>
                        {" "}
                        <p>Heure de Fermeture</p>
                        <input
                            type="time"
                            name="end_time"
                            value={form.end_time}
                            onChange={handleChangeend_time}
                            required
                        />
                    </div>

                    <button className="modif ajou" type="submit">
                        Ajouter
                    </button>
                </form>
                {midi ? (
                    <form onSubmit={shandleEdit} className="form3m">
                        <p className="fermerx" onClick={() => setmidi(false)}>
                            {" "}
                            x
                        </p>
                        <div>
                            <p> jour</p>
                            <select
                                disabled
                                required
                                name="day_id"
                                value={form2.day_id}
                                className="boxis-num-2-1-S"
                                onChange={handleChangeday_id2}
                            >
                                <option value="">jour</option>
                                <option value="1">Lundi</option>
                                <option value="2">Mardi</option>
                                <option value="3">Mercredi</option>
                                <option value="4">Jeudi</option>
                                <option value="5">Vendredi</option>
                                <option value="6">Samedi</option>
                                <option value="7">Dimanche</option>
                            </select>
                        </div>
                        <div>
                            <p> status</p>
                            <select
                                required
                                name="status"
                                value={form2.status}
                                className="boxis-num-2-1-S"
                                onChange={handleChangestatus2}
                            >
                                <option value="">status</option>
                                <option value="ouvert">ouvert</option>
                                <option value="fermé">fermé</option>
                            </select>
                        </div>

                        <div>
                            <p>Heure d'ouverture</p>
                            <input
                                type="time"
                                name="start_time"
                                value={form2.start_time}
                                onChange={handleChangestart_time2}
                                required
                            />
                        </div>
                        <div>
                            {" "}
                            <p>Heure de fermeture</p>
                            <input
                                type="time"
                                name="end_time"
                                value={form2.end_time}
                                onChange={handleChangeend_time2}
                                required
                            />
                        </div>
                        <div>
                            {" "}
                            <p>salon</p>
                            <select
                                disabled
                                required
                                name="salon_id"
                                value={form2.salon_id}
                                className="boxis-num-2-1-S"
                                onChange={handleChangesalon_id2}
                            >
                                <option value="">salon</option>

                                {salonid
                                    ? salonid.map((rol) => (
                                        <option key={rol.id} value={rol.id}>
                                            {rol.name}
                                        </option>
                                    ))
                                    : ""}
                            </select>
                        </div>

                        <button className="modif" type="submit">
                            Modifier
                        </button>
                    </form>
                ) : (
                    ""
                )}

                <Table sx={{
                    marginTop: "18px",

                    maxWidth: { xs: '50vw', sm: '90vw', md: '10vw' }// Table responsive

                }}>

                    <TableHead sx={{


                        maxWidth: { xs: '50vw', sm: '90vw', md: '10vw' },// Table responsive
                        border: 'none'
                    }}>
                        <TableRow>
                            <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' } }}>
                                <Typography sx={{ color: "black" }}>Jours</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '10vw', sm: '19vw', md: '19vw' } }}>
                                <Typography sx={{ color: "black" }}>Ouver</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '10vw', sm: '19vw', md: '19vw' } }}>
                                <Typography sx={{ color: "black" }}>Fermer</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '10vw', sm: '19vw', md: '19vw' } }}>
                                <Typography sx={{ color: "black" }}>Status</Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '10vw', sm: '19vw', md: '19vw' } }}>
                                <Typography sx={{ color: "black" }}>Actions</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ minWidth: { xs: '80vw', sm: '90vw', md: '10vw' }, maxWidth: { xs: '80vw', sm: '80vw', md: '10vw' } }} >
                        <TableRow>
                            <TableCell align="center" sx={{ padding: '0', border: 'none', minWidth: { xs: '10vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '10vw', sm: '19vw', md: '19vw' } }}>
                                {events && events.length > 0 ? (
                                    events.map((item, index) => {
                                        // Compter combien de lignes seront créées pour cet item
                                        const rowCount = item.times.length;

                                        return item.times.map((time, timeIndex) => (
                                            <TableRow key={`${index}-${timeIndex}`}>
                                                {timeIndex === 0 ? (
                                                    <TableCell align="center" sx={{ padding: '5px', height: `${rowCount * 50}px`, minWidth: { xs: '18vw', sm: '10vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' } }}>
                                                        <Typography sx={{ color: "black" }}>{item.name}</Typography>
                                                    </TableCell>
                                                ) : (
                                                    ''
                                                )}


                                            </TableRow>
                                        ));
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <Typography sx={{ color: "gray" }}>Aucun agenda disponible.</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '0', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, border: 'none' }}>
                                {events && events.length > 0 ? (
                                    events.map((item, index) => (
                                        item.times.map((time, timeIndex) => (
                                            <TableRow key={`${index}-${timeIndex}`}>

                                                <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' } }}>
                                                    <Typography sx={{ color: "black" }}>{formatTimex(time.start_time)}</Typography>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '16vw', sm: '19vw', md: '19vw' } }}>
                                                    <Typography sx={{ color: "black" }}>{formatTimex(time.end_time)}</Typography>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '16vw', sm: '19vw', md: '19vw' } }}>
                                                    <Typography sx={{ color: "black" }}>{time.status}</Typography>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '5px', height: '50px', minWidth: { xs: '18vw', sm: '19vw', md: '19vw' }, maxWidth: { xs: '18vw', sm: '19vw', md: '19vw' } }}>
                                                    <IconButton
                                                        sx={{ fontSize: { xs: '10px', sm: '15px', md: '15px' } }}
                                                        onClick={() =>
                                                            handleEdit(
                                                                item.id,
                                                                time.start_time,
                                                                time.end_time,
                                                                time.status,
                                                                id,
                                                                time.id
                                                            )
                                                        }
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ fontSize: { xs: '10px', sm: '15px', md: '15px' } }}
                                                        onClick={() => handleDeletex(time.id)}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ))
                                ) : (
                                    <TableRow>

                                    </TableRow>
                                )}
                            </TableCell>
                        </TableRow>

                    </TableBody>



                </Table>
            </Box>
        </>
    );
};

export default Salonday;
