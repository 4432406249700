import React from "react";

const Message = () => {
  return (
    <>
      <div
        style={{
          width: "300px",
          height: "31px",
          background: "#F9194F",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p style={{ color: "white" }}>Success!</p>
      </div>
    </>
  );
};
export default Message;
