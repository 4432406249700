import React from "react";
import About from "./About/About";
import Gallery from "./Gallery/Gallery";
import { Container } from "@mui/material";
import NavMain from "../Navigation/NavMain";
import Footer from '../outil/Footer'
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
const ShopDetail = ({ salonId }) => {



  const { id } = useParams(); // Récupérer l'ID de l'URL
const userid=localStorage.getItem('userId')

  const [salonp, setsalonp] = useState([])
  const [salonpp, setsalonpp] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [salonidd, setsalois] = useState(salonId)
  const [ok, setok] = useState('')
  const [fav, setfav] = useState([])
  const token = localStorage.getItem('token')
  useEffect(() => {
    const fetchquestion = async () => {
      try {
        const response = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/favoris_client", {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setfav(response.data.favorites_client);
        
        // Variable temporaire pour vérifier si le salon est dans les favoris
        const isFavorited = response.data.favorites_client.some(m => 
          m.pivot.user_id === userid && m.pivot.salon_id === id
        );
        
        setok(!isFavorited); // mettre à jour "ok" selon que l'élément est favori ou non
  
      } catch (err) {
        console.log(err);
      }
    };
  
    fetchquestion();
  }, []); // Notez que fetchquestion() n'a pas besoin de dépendances ici
  
  useEffect(() => {
    setok(true); // Initialisez ok à true ici ou dans un autre useEffect si nécessaire
   // Assurez-vous que fetchServices soit défini ici ou ailleurs.
  }, [id]); // id en dépendance parce que cela fait nécessairement un nouvel appel à fetchServices chaque fois que l'id change
  

  useEffect(() => {



    const fetchServices = async () => {

      try {
        const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/salon/${id}`);
        setsalonp(response.data);

        //   console.log(response)

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    setsalois(salonId)
    window.scroll({ top: 0 })
    fetchServices();
  }, [id]);

  function sett(val) {
    setok(val)
  }
  


  return (
    <>




      <NavMain />
      <Container maxWidth="xl" sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Gallery service={salonp} ok={ok} sett={sett} />
        <About service={salonp} />
      </Container  >
      <Footer />
    </>
  );
};
export default ShopDetail;