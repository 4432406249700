import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import axios from "axios";
import { role } from "../../authentification/Authentificationl";
const Admasseur = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalAjout, setOpenModalAjout] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [masseur, setmass] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");
  const messagexx = document.querySelector(".popx");
  const messagexxf = document.querySelector(".popxf");
  const [messagex, setmessagex] = useState("");
  const [messagexa, setmessagexa] = useState("");
  const [truee, settrue] = useState(false);

  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [address, setAddr] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [role_id, setRole] = useState("");
  const [listrole_id, setlistRole] = useState();
  const [selectrole, setSelectrole] = useState("");
  const [date_de_naissance, setDate_de_naissance] = useState("");
  const [datas, setDatas] = useState([]);

  const fetchCmass = async () => {
    try {
      const res = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/masseurs",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res.data.masseurs);
      setmass(res.data.masseurs);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }

    // Rafraîchir les événements après la suppression
  };
  useEffect(() => {
    fetchCmass();
    const fetchCountries = async () => {
      try {
        const response = await axios.get("/countries");
        setCountries(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error("Erreur de récupération des pays", error);
        setError("Erreur de récupération des pays.");
      }
    };
    fechrole();
    fetchOeil();
    fetchCountries();
  }, []);

  const [idsup, setidsup] = useState();
  const [valid, setvalid] = useState(false);
  function handleDeletex(ideventx) {
    setvalid(true);
    setidsup(ideventx);
  }
  const handleDelete = async () => {
    setvalid(false);
    let ideventx = idsup;

    try {
      const res = await axios.delete(
        "https://www.api-macerise.masovia-madagascar.com/api/delete_masseurs/" +
          ideventx,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res);
      setmessagex("supprimé avec succès id :" + ideventx);
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 6000);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }

    // Rafraîchir les événements après la suppression
    fetchCmass();
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedMasseur(null); // Clear the selected masseur when modal closes
  };

  const handleCloseAjout = () => {
    setOpenModalAjout(false);
  };

  const handleOpenAjout = () => {
    setOpenModalAjout(true);
  };
  const [form2, setForm2] = useState({
    name: "",
    tel: "",
    address: "",
    city: "",
    id: "",
  });
  const [selectedMasseur, setSelectedMasseur] = useState(null);
  const handleOpen = (id) => {
    const masseur = datas.find((item) => item.id === id); // Find the masseur by ID
    setSelectedMasseur(masseur); // Set the selected masseur
    setOpenModal(true); // Open the modal
  };

  // Fonction pour générer un mot de passe aléatoire
  const generatePassword = (length = 12) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedPassword = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      generatedPassword += charset[randomIndex];
    }
    return generatedPassword;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setPassword(newPassword);
    console.log("Mot de passe généré:", newPassword); // Afficher le mot de passe dans la console
  };
  const fechrole = async () => {
    try {
      const response = await role();
      setlistRole(response);
      // console.log(response.roles[0].name)
    } catch (error) {
      console.error("Erreur de récupération des pays", error);
      setError("Erreur de récupération des pays.");
    }
  };

  // Fonction appelée lors du clic sur le bouton "Ajouter"
  const handleAddMasseur = async (e) => {
    e.preventDefault();

    const dob = new Date(date_de_naissance);
    const formattedDate = `${dob.getDate()}-${
      dob.getMonth() + 1
    }-${dob.getFullYear()}`; // Ensure it's in YYYY-MM-DD format

    let age = new Date().getFullYear() - dob.getFullYear();
    console.log(age);

    try {
      const response = await axios.post(
        "/add_masseur",
        {
          name,
          email,
          tel,
          address,
          date_de_naissance: formattedDate, // Send in the expected format
          password,
          // role_id,
          country_id: selectedCountry, // Envoi de l'ID du pays sélectionné
          city,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchCmass();
      fetchOeil()
      handleCloseAjout();
      // console.log("Réponse:", response);
    } catch (error) {
      console.error("Erreur lors de l'ajout du masseur:", error);
    }
  };

  const fetchOeil = async () => {
    // e.preventDefault()
    try {
      const datis = await axios.get("/masseurs", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(datis.data.masseurs);
      console.log("get:", datas);
    } catch {
      console.log("erreursss");
    }
  };
  return (
    <>
      <TableContainer
        // className="main-content-wrap d-flex flex-column"
        component={Paper}
        sx={{ 
          width: { xs: '0%',sm:'75%', md: '100%' }, // Table responsive
          overflowX: 'auto',
        marginLeft:{xs:'0px',sm:'150px',md:'0px'}
        ,marginTop:{xs:'0px',sm:'30px',md:'0px'} // Taille de texte responsive dans la table
        }}
      >
        <Typography sx={{fontSize:{xs:'0px',sm:'20px',md:'20px'} ,marginLeft:{xs:'0px',sm:'20px',md:'15px'}}}>Liste des masseurs</Typography>
        <div style={{ padding: 16 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAjout}
            sx={{ background: "#F9194F" }}
          >
            Ajouter
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Nom</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Téléphone</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Adresse</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Ville</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Pays</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masseur
              ? masseur.map((event, key) => (
                  <TableRow key={key}>
                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>{event.name}</TableCell>
                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>{event.tel}</TableCell>
                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>{event.address}</TableCell>
                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>{event.city}</TableCell>

                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>
                      {" "}
                      {countries.map((country) =>
                        country.id == event.country_id ? country.name : ""
                      )}
                    </TableCell>
                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px',md:'15px' } }}>
                      <IconButton>
                        <Visibility
                          onClick={() =>
                            handleOpen(
                              event.id
                              // event.name,
                              // event.tel,
                              // event.address,
                              // event.city,
                              // event.country_id
                            )
                          }
                        />
                      </IconButton>
                      <IconButton onClick={() => handleDeletex(event.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
        {/* Dialog pour ajouter un masseur */}
        <Dialog open={openModalAjout} onClose={handleCloseAjout}>
          <DialogTitle>Ajouter Masseur</DialogTitle>
          <DialogContent>
            <TextField
              type="text"
              margin="dense"
              label="Entrer nom du masseur"
              fullWidth
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Entrer email du masseur"
              fullWidth
              type="email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type="text"
              margin="dense"
              label="Téléphone"
              fullWidth
              variant="outlined"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
            />
            <TextField
              type="text"
              margin="dense"
              label="Adresse"
              fullWidth
              variant="outlined"
              value={address}
              onChange={(e) => setAddr(e.target.value)}
            />
            <TextField
              type="text"
              margin="dense"
              label="Ville"
              fullWidth
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              margin="dense"
              id="date_de_naissance"
              label="Date de naissance"
              type="date"
              value={date_de_naissance}
              onChange={(e) => setDate_de_naissance(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="jj/mm/aa"
              className="boxis-num-2-2 reddd"
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="country-select-label">Pays</InputLabel>
              <Select
                labelId="country-select-label"
                id="country-select"
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                  setCountryId(e.target.value);
                }}
                label="Pays"
              >
                <MenuItem value="">Sélectionnez un pays</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="Mot de passe"
              fullWidth
              variant="outlined"
              type="text"
              value={password} // Mot de passe généré affiché ici
              InputProps={{
                endAdornment: (
                  <Button onClick={handleGeneratePassword} variant="contained">
                    Générer
                  </Button>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              className="buton"
              sx={{ background: "#F9194F" }}
              onClick={handleAddMasseur} // Appel de la fonction lors du clic
            >
              Ajouter
            </Button>
          </DialogContent>
        </Dialog>

        {/* Dialog pour voir les informations du masseur */}
        <Dialog open={openModal} onClose={handleClose}>
          <DialogTitle
            variant="h6"
            style={{ fontWeight: "bold", marginBottom: "15px" }}
          >
            Informations sur le masseur
          </DialogTitle>
          {selectedMasseur ? (
            <DialogContent>
              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Nom:</strong> {selectedMasseur.name}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Email:</strong> {selectedMasseur.email}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Téléphone:</strong> {selectedMasseur.tel}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Adresse:</strong> {selectedMasseur.address}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Date de naissance:</strong>{" "}
                {selectedMasseur.date_de_naissance}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Pays:</strong>
                {countries.length > 0 && selectedMasseur.country_id
                  ? countries.find(
                      (country) => country.id == selectedMasseur.country_id
                    )?.name
                  : "Pays non trouvé"}
              </Typography>

              <Typography variant="body1" style={{ marginBottom: "10px" }}>
                <strong>Ville:</strong> {selectedMasseur.city}
              </Typography>
            </DialogContent>
          ) : (
            <Typography>Aucune information disponible</Typography>
          )}
        </Dialog>
      </TableContainer>
      {valid ? (
        <div className="validation">
          <span className="spann">
            <p>Voulez-vous vraiment le supprimer? </p>
            <span>
              <button onClick={handleDelete}>OUI</button>
              <button onClick={() => setvalid(false)}>NON</button>
            </span>{" "}
          </span>{" "}
        </div>
      ) : (
        ""
      )}
      {truee ? <div className="popx">{messagex}</div> : ""}

    </>
  );
};

export default Admasseur;
