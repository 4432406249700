import {React,useEffect,useRef,useState} from "react";
import './Login.css'
import {useNavigate} from 'react-router-dom';
import Inscription3 from "../inscription/Inscription3";
import Inscription from '../inscription/Inscription';
import Inscription4 from "../inscription/Inscription4";
import { loging} from "../../../authentification/Authentificationl";
import axios from "axios";
const Login=()=>{
    const loginp=useRef(null)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [load, setload] = useState(false);
    const navigate = useNavigate();
    const [valinscri,setinscrire]=useState(1);
    useEffect(()=>{
        if(loginp){
            loginp.current.focus()
        }
        setinscrire(1)
    },[])
    const googleo = async () => {
        try {
              const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/auth/google');
         //   setEvents(response.data);
           console.log(response)
        } catch (error) {
              console.log(error)
        }

  };

    const handleSubmit = async (e) => {
               
                e.preventDefault();
                setError('');
                setload(true)
                try {
                    const userData = await loging(email, password);
                    console.log('Connexion réussie', userData);      
                        
                  
                    // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
                    
                    setload(false);
                  //  navigate('/dashboard');

                  
                     if(userData.token ) {
                        if(userData.role==='client'){
                            setTimeout(() => {
                                navigate('/');
                                }, 1000);
                               setTimeout(() => {
                                   navigate('/dashboard');
                                }, 1500);
                                
                        }else{
                            setTimeout(() => {
                                navigate('/');
                                }, 1000);
                               setTimeout(() => {
                                  navigate('/dashboardPro');
                                }, 1500);
                                
                        }
                   
                       //console.log(userData.token)
                     }else{
                      // setError(userData.message)
                    // console.log(userData.token)
                     }
                  console.log(userData)
                } catch (error) { 
                    setload(false)
                    setError('Échec de la connexion. Veuillez vérifier vos informations d\'identification.');
                }
       };



//affichage inscription
    
    function hide(val){
    setinscrire(val)
 
    }
    
    return(
        <>
    
        {valinscri==1? 
        
            <div className="login-container">
                <div className="boxi-num-1">
                    <div className="boxi-num-1-1">Connectez vous</div>
                    <div className="boxi-num-1-2">Connectez-vous pour accéder à votre compte.</div>
                </div>
                <div className="boxi-num-2">
                    <form  onSubmit={handleSubmit}>
                        <input type="email"
                        ref={loginp}
                          value={email} 
                          onChange={(e) => setEmail(e.target.value)} 
                          className="boxi-num-2-1" placeholder="Adresse email" required />
                        <input type="password"
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)} 
                          placeholder="Mot de passe" className="boxi-num-2-2" required/>
                        <div className="boxi-num-2-3"><input className="boxi-num-2-3-1" id="check"  type="checkbox"  /><label htmlFor='check' className="boxi-num-2-3-2" >Se souvenir de moi</label></div>
                    
                      
                         <button    className="boxi-num-3  btnload " type="submit">{load?'loading...':'Se connecter'}</button>
                    <p className="error"> {error && <p>{error}</p>}</p> 
                    </form>
                </div>
              
                <div className="boxi-num-4">
                        <div className="boxi-num-4-1"></div>
                        <div className="boxi-num-4-2">Ou se connecter avec</div>
                </div>
                <div className="boxi-num-5">
                    <div className="boxi-num-5-1">
                        <div onClick={()=>googleo()} className="boxi-num-5-1-1"><img className="logo-google"  src='../sary/logoG.png' alt=""/><div className="text-google">Google</div></div>
                        <div className="boxi-num-5-1-2"><img className="logo-facebook" src='../sary/facebook.jpg' alt=""/><div className="text-facebook">Facebook</div></div>
                    </div>
                    <div className="boxi-num-5-2">
                        <div className="boxi-num-5-2-1" ><img className="logo-linkedin" src='../sary/link.png' alt=""/><div className="text-linkedin">Linkedin</div> </div>
                        <div className="boxi-num-5-2-2" ><img className="logo-instagram" src="../sary/téléchargement.jpg" alt=""/><div className="text-instagram">Instagram</div></div>
                    </div>
                </div>
                <div className="boxi-num-6">Vous n'avez pas de compte ? <span className="boxi-style" onClick={()=>hide(2)}>S’inscrire</span></div>


            </div>
             : ""}
              {valinscri===2?  <Inscription data={hide}/>:''}
              {valinscri===3?  <Inscription3 data={hide}/>:''}
              {valinscri===4?  <Inscription4 data={hide}/>:''}
            
        </>
    )
}
export default Login