import { useState } from 'react';
import React from 'react'
import './Auth.css';
import Login2 from "../Compo/Login/Login2";
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
export default function AuthConnect() {
  const [dat,setDat] = useState(false)
  const navigate = useNavigate();
  const handleclck = () =>{
    navigate('/');

  }
  const hideLogin = () =>{
    setDat(false)
  }
  return (
    <>
         <div className="auth">
            <ul>
                <li><img alt='' src='image/france.png'/></li>
               <li><button className='devenir prodev ' onClick={handleclck}>Devenir client</button></li>
                <li className='conn'><a href="/"className="connecter">Essayer Macerise Pro</a></li>
            </ul>
        </div>
        {dat ?
    <div className="login" >
      <div className="transparent" onClick={hideLogin}></div>
        <Login2/>
    </div>:''
  }
    </>
  )
}
