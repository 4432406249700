import React from 'react'
import './VueAccueil.css'
import { NavLink } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';
export default function VueAccueil() {
  const [name, setname] = useState('');
  const [country, setcountry] = useState('');
  const [date, setdate] = useState('');
  function formatDate(inputDate) {
    // Vérifiez si la date est au bon format
    if (!/\d{4}-\d{2}-\d{2}/.test(inputDate)) {
      return 'Format de date invalide';
    }

    // Séparez les parties de la date
    const [year, month, day] = inputDate.split('-');

    // Formatez la date dans le format souhaité
    return `${day}-${month}-${year}`;
  }
  const basePath = '/filtre';

  let pathSegments = [];
  let queryParams = new URLSearchParams();
  
  if (name) {
      pathSegments.push(name);
      queryParams.append('name', name); // Remplacez 'value1' par ce que vous souhaitez
  }
  
  if (country) {
      pathSegments.push(country);
      queryParams.append('country', country); // Remplacez 'value2' par ce que vous souhaitez
  }
  
  if (date) {
      pathSegments.push(date);
      queryParams.append('date', formatDate(date)); // Remplacez 'value2' par ce que vous souhaitez
  }
  
  // Construire le chemin final
  const path = `${basePath}/${pathSegments.join('/')}`;
  // Ajouter les paramètres de requête s'il y en a
  const finalUrl = path + (queryParams.toString() ? '?' + queryParams.toString() : '');
  
  // Utilisation de l'URL dans votre déclaration "to"
 
    {/* to={`${name &&!country&&!date ? `filtre/${name}` : ''}${date&&!name&&!country ? `filtre/${date}` : ''}${country&&!name&&!date ? `filtre/${country}` : ''}${!name&&!date&&!country ? `/filtre` : ''}${country && name && date ? `filtre/${name}/${date}/${country}` : ''}${country && name && !date ? `filtre/${name}/${country}` : ''}${!country && name && date ? `filtre/${name}/${date}` : ''}${country && !name && date ? `filtre/${date}/${country}` : ''}`}*/}

  return (
    <div className='vueAccueil'>
      <div className='transparent'>
      </div>
      <h2>Laissez-vous guider par votre curiosité</h2>
      <h3>Découvrez et réservez le pro qui vous corresponds !!</h3>

      <div className='recherche'>

        <p>
          <i className='ti-time'></i>
          <input type='text' placeholder='Que cherchez-vous ?'
            value={name}
            onChange={(e) => setname(e.target.value)} />
        </p>
        <p>
          <i className='ti-location-pin'></i>
          <input type='text' placeholder='Où ?'

            value={country}
            onChange={(e) => setcountry(e.target.value)}
          />
        </p>
        <p>
          <i className='ti-time'></i>
          <input type='date' placeholder=' Quand ?'
              value={date}
              onChange={(e) => setdate(e.target.value)}
          />
        </p>
        <NavLink
       to={finalUrl}
 
        
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""

          }
        
        >
          <button  > <i className='ti-search' ></i> Rechercher</button>
        </NavLink>

      </div>

    </div>
  )
}
