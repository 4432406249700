import { Stack, Typography } from "@mui/material"
import GroupAddIcon from "@mui/icons-material/GroupAdd";

export default function CardNumber({name, number, children}){
    return(
        <Stack
              bgcolor={"white"}
              width={"250px"}
              flexGrow={'1'}
              p={2}
              py={3}
              borderRadius={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-around"}
              boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
              sx={{}}
            >
                {children}
              {/* <GroupAddIcon fontSize={'large'} sx={{ color: "#F9194F" }} /> */}
              <Stack
                direction={"column"}
                justifyContent={"center"}
                // alignItems={"center"}
                color={'gray'}
               
                // gap={2}
              >
                <Typography  >{name} </Typography>
                <Typography >{number}</Typography>
              </Stack>
            </Stack>
    )
}