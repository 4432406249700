import React, { useState } from "react";
import { Rating, Typography } from "@mui/material";

const RatingStars = ({ value = 0 }) => {

  const handleChange = (event, newValue) => {
    // setValue(newValue);
  };

  return (
    <div>
      <Rating name="simple-controlled" value={value} max={5} readOnly />
      {/*       
      <Rating
        name="simple-controlled"
        value={5}
        onChange={handleChange}
        precision={0.5}
        max={5}
      /> */}
    </div>
  );
};

export default RatingStars;
