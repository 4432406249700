// src/App.js
import React, { useEffect, useState } from "react";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import { Margin } from "@mui/icons-material";
import "../Calendrier/cal.css";
import Repon from "./Repon";
const Questions = () => {
  const [truee, settrue] = useState(false);
  const [validation, setvalidation] = useState(false);
  const [falsee, setfalsee] = useState(false);
  const [messagex, setmessagex] = useState("");
  const [messagexa, setmessagexa] = useState("");
  const [events, setEvents] = useState([]);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const messagexx = document.querySelector(".popx");
  const messagexxf = document.querySelector(".popxf");

  const fetchEvents = async () => {
    const response = await axios.get(
      "https://www.api-macerise.masovia-madagascar.com/api/questions"
    );
    setEvents(response.data.questions);
    //  console.log(response.data.questions)
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const [form, setForm] = useState({
    content: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const [form2, setForm2] = useState({
    id: "",
    question: "",
  });

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setForm2((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const [form3, setForm3] = useState({
    salonidd: "",
    eventidd: "",
  });

  /* const handleSubmit = (e) => {
       e.preventDefault();
       // Vous pouvez ici traiter les données du formulaire ou les envoyer à une API
       console.log(form);
     };*/
  const handleSubmit = async (e) => {
    e.preventDefault();
    /*  const dataToSend = {
            date: selectedDate.format('YYYY-MM-DD'),  // Format de la date
            start_time: startTime.format('HH:mm'), // Format de l'heure de début
            end_time: endTime.format('HH:mm') // Format de l'heure de fin
          };


      */
    let content = form.content;
    try {
      const res = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/add_question",
        { content },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setmessagex("ajout avec success quest");
      setForm(() => ({
      
       content: '',
      }));
      setfalsee(false);
      if (messagexxf) {
        messagexxf.style.display = "none";
      }
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }
      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";

        }
        settrue(false);
      }, 6000);
      // console.log(res)
    } catch (error) {
      console.error(
        "Erreur lors de l'envoi des données à l'API:",
        error.response.data.end_time
      );
      setmessagexa(error.response.data.end_time);
      settrue(false);
      if (messagexx) {
        messagexx.style.display = "none";
      }

      setfalsee(true);
      if (messagexxf) {
        messagexxf.style.display = "flex";
      }
      setTimeout(() => {
        if (messagexxf) {
          messagexxf.style.display = "none";
        }
        setfalsee(false)
      }, 6000);
    }
    fetchEvents();
  };

  const handleEdit = async (eventid, quest) => {
    setmidi(true);

    //console.log(typeof start, end)

    setForm2((prevForm) => ({
      ...prevForm, // Conserver les valeurs précédentes
      id: eventid, // mettre à jour l id
      question: quest, // mettre à jour l id
    }));
  };

  const shandleEdit = async (e) => {
    e.preventDefault();
    setmidi(false);
    let eventidd = form2.id;
    let content = form2.question;
    console.log(eventidd, content);
    try {
      const res = await axios.put(
        "https://www.api-macerise.masovia-madagascar.com/api/update/question/" +
          eventidd,
        { content },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setmessagex("modification avec success ");
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }
      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
      }, 6000);
      console.log(res);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }
    fetchEvents();
  };
  const [idsup, setidsup] = useState();
  function handleDeletex(ideventx) {
    setvalid(true);
    setidsup(ideventx);
  }

  const handleDelete = async () => {
    setvalid(false);
    let ideventx = idsup;

    try {
      const res = await axios.delete(
        "https://www.api-macerise.masovia-madagascar.com/api/delete/question/" +
          ideventx,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res);
      
      setmessagex("supprimé avec succès ");
      settrue(true);
      if (messagexx) {
        messagexx.style.display = "flex";
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = "none";
        }
        settrue(false);
      }, 6000);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données à l'API:", error);
    }

    // Rafraîchir les événements après la suppression
    fetchEvents();
  };

  const [midi, setmidi] = useState(false);
  const [valid, setvalid] = useState(false);
  const [qes, setques] = useState(1);
  return (
    <>
   
      <Box className="agenda"
      sx={{ 
        width: { xs: '0%',sm:'72%', md: '95%' }, // Table responsive
        overflowX: 'auto',
      marginLeft:{xs:'0px',sm:'150px',md:'0px'}
      ,marginTop:{xs:'0px',sm:'30px',md:'0px'} // Taille de texte responsive dans la table
      }}
      >
        <h1>Gestionnaire FAQ </h1>
        <button
          className="btnq"
          onClick={() => {
            setques(1);
          }}
        >
          Questions
        </button>{" "}
        <button
          className="btnq"
          onClick={() => {
            setques(2);
          }}
        >
          Reponses
        </button>
        {qes === 1 ? (
          <>
            <form className="marb" onSubmit={handleSubmit}>
              <input
                type="text"
                name="content"
                value={form.content}
                onChange={handleChange}
                required
                placeholder="question"
              />

              <button className="ajout" type="submit">
                Ajouter
              </button>
            </form>
            {truee ? <div className="popx">{messagex}</div> : ""}
            {falsee ? <div className="popxf">{messagexa}</div> : ""}
            {midi ? (
              <form onSubmit={shandleEdit} className="form2m">
                <p className="fermerx" onClick={() => setmidi(false)}>
                  {" "}
                  x
                </p>

                <div>
                  <p> Question id : {form2.id}</p>
                  <textarea
                    type="text"
                    name="question"
                    value={form2.question}
                    onChange={handleChange2}
                    required
                    placeholder="question"
                  />
                </div>

                <button className="modif" type="submit">
                  Modifier
                </button>
              </form>
            ) : (
              ""
            )}
            <table>
              <thead>
                <tr>
              
                  <th>Question</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {events
                  ? events.map((event) => (
                      <tr key={event.id}>
                   <td>{event.content}</td>
                        <td>
                          {" "}
                          <IconButton
                            onClick={() => handleEdit(event.id, event.content)}
                          >
                            <Edit />{" "}
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDeletex(event.id, event.salon_id)
                            }
                          >
                            {" "}
                            <Delete />
                          </IconButton>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </>
        ) : (
          <Repon />
        )}
      </Box>
      {valid ? (
        <div className="validation">
          <span className="spann">
            <p>Voulez-vous vraiment le supprimer? </p>
            <span>
              <button onClick={handleDelete}>OUI</button>
              <button onClick={() => setvalid(false)}>NON</button>
            </span>{" "}
          </span>{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Questions;
