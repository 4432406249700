import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SkeletonCardSlide from "./skeletonSlider";

function SliderEvent({ dataEvent }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  useEffect(() => {
    console.log("in Slider", dataEvent.length);
  }, [dataEvent]);
  return (
    <Stack>
      <Slider {...settings}>
        {dataEvent.length && dataEvent.length > 0
          ? dataEvent.map((event, i) => {
              return (
                <div key={i} >
                  <CardSlide event={event} />
                </div>
              );
            })
          : Array.from({ length: 3 }).map((_, i) => {
              return (
                <div key={i}>
                  <SkeletonCardSlide />
                </div>
              );
            })}
      </Slider>
    </Stack>
  );
}

function CardSlide({ event }) {
  const navigate = useNavigate();
  return (
    <Stack
      height={"88svh"}
      flexDirection={"row"}
      alignItems={"flex-end"}
      onClick={() => navigate("/evenement/" + event.id)}
      sx={{
        background:
          'linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url("/image/3.png") top center / cover no-repeat',
        "&:hover": {
          cursor: "pointer",
          opacity: "0.8",
        },
      }}
    >
      <Stack
        sx={{ bottom: "0", color: "white" }}
        alignSelf={"flex-end"}
        justifyContent={"center"}
        p={2}
      >
        <Typography
          color={"white"}
          fontSize={24}
          component={"h1"}
          sx={{
            "&:hover": {
              color: "#ff4dd8",
            },
          }}
        >
          {event.name}
        </Typography>
        <Typography fontSize={"14px"}>
          intervenants : {event.intervenant}
        </Typography>
        <Typography fontSize={"14px"}>
          organisateurs : {event.organisateur}
        </Typography>
      </Stack>
    </Stack>
  );
}
export default SliderEvent;
