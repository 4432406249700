import axios from 'axios';
// Configurer Axios pour pointer vers votre API
axios.defaults.baseURL = 'https://www.api-macerise.masovia-madagascar.com/api';

// Fonction de connexion
export const loging = async (email, password) => {
    try {
        const response = await axios.post('/login', { email, password });


        // Utiliser sessionStorage à la place de localStorage
     /*  sessionStorage.setItem('token', response.data.token); // Les données se réinitialiseront à la fermeture
        sessionStorage.setItem('userId', response.data.userId);
        sessionStorage.setItem('Role', response.data.role);  // Stocker rol*/
        localStorage.setItem('token', response.data.token);   // Stocker le token
     //  console.log('datas', response.data.userId);
        localStorage.setItem('userId', response.data.userId); 
        localStorage.setItem('Id', response.data.id); 
        localStorage.setItem('Role', response.data.role);  // Stocker rol
        console.log(response)
        return response.data;

    } catch (error) {
        console.error("Erreur de connexion", error);
        throw error;
    }
};
// Fonction de inscription
export const registerr = async (name, email, tel, address, date_de_naissance, password, role_id, country_id, image, city) => {
    try {
        const response = await axios.post('/register', { name, email, tel, address, date_de_naissance, password, role_id, country_id, image, city });
        return response.data;
    } catch (error) {
        console.error("Erreur d'inscription");
        throw error;
    }
};
// proble api 
// Fonction inscription 2
export const registerr3 = async (name, address, description, apropos, user_id, city, country_id) => {
    // console.log(country_id)
    try {
        const response = await axios.post('/register-salon', { name, address, description, apropos, user_id, city, country_id });
        //    console.log('Réponse reçue:', response.data);
        return response.data;
    } catch (error) {
        console.error("Erreur d'inscription", error);
        throw error;
    }

};
const token = localStorage.getItem('token');
export const servicemasseur = async (salon_id, category_id, name, description, duration, price) => {
    // console.log(country_id)
    try {
        const response = await axios.post(`/add_service/salon`, { salon_id, category_id, name, description, duration, price },
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }
        );
        console.log('Réponse reçue:', response.data);
        return response.data;
    } catch (error) {
        console.error("Erreur d'inscription", error);
        throw error;
    }

};

// Fonction de verification code
export const verifi_code = async (verification_code) => {
    try {
        const response = await axios.post('/verify-phone', { verification_code });

        localStorage.setItem('token', response.data.token);   // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};
// Fonction Pour créer une reservation
export const reservation = async (verification_code) => {
    try {
        const response = await axios.post('/verify-phone', { verification_code });

        localStorage.setItem('token', response.data.token);   // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error.data);
        throw error;
    }
};
// Fonction liste de contry
export const countries = async () => {
    try {
        const response = await axios.get('/countries');


        return response.data;
    } catch (error) {
        console.error("Erreur de liste contry", error);
        throw error;
    }
};
// Fonction liste de role
export const role = async () => {
    try {
        const response = await axios.get('/roles');


        return response.data;
    } catch (error) {
        console.error("Erreur de liste role", error);
        throw error;
    }
};
// Fonction pour avoir la liste des salons recommendés
export const recommendes = async () => {
    try {
        const response = await axios.get('/salons_recommended');
        // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};
// Fonction pour avoir la liste des salon_recent
export const salon_recent = async () => {
    try {
        const response = await axios.get('/salons_recent');
        // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};
// Fonction pour avoir la liste des salons recommendés
export const categories = async () => {
    try {
        const response = await axios.get('/categories');
        // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};

// Fonction pour avoir la liste des salons bd
export const salons = async () => {
    try {
        const response = await axios.get('/salons_a_la_une');
        // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};
// Fonction Pour lister les services dans un salon
export const servicessalon = async () => {
    try {
        const response = await axios.get('salons/${salon}/services');
        // Stocker le token
        return response.data;
    } catch (error) {
        console.error("Erreur de confirmation", error);
        throw error;
    }
};
// Fonction pour récupérer l'utilisateur connecté


export const fetchUser = () => {
    try {
        const token = localStorage.getItem('token');

        return token;
    } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur", error);
        throw error;
    }
};

// Fonction de déconnexion
export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem("Role");
    sessionStorage.removeItem('token'); // Les données se réinitialiseront à la fermeture
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('Role');  // Stocker rol
};
