// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f1f1f1;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Calle.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".App {\n    font-family: Arial, sans-serif;\n    margin: 20px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n  }\n  \n  th {\n    background-color: #f2f2f2;\n  }\n  \n  tr:hover {\n    background-color: #f1f1f1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
