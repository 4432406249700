import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Container, styled } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';

import './Gallery.css'
import { Scale } from '@mui/icons-material';
const CustomButton = styled(Button)(({ theme }) => ({

  textTransform: 'capitalize',
  backgroundColor: '#fff',
  color: '#000',
  ':hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#fff',
    cursor: 'pointer'
  }
}));

const ImagePanel = React.memo(({ src, alt, position, children }) => (
  <Paper elevation={2} sx={{ height: '220px', position: 'relative' }}>
    <img src={src} alt={alt} style={{ width: '100%', borderRadius: '4px', height: '100%' }} />
    <Box sx={{ position: 'absolute', display: 'flex', gap: 2, top: position.top, left: position.left, bottom: position.bottom, right: position.right }}>
      {children}
    </Box>
  </Paper>
));

function ImageGallery({ service, ok, sett }) {

  let { id } = useParams();

  let [tab, setrab] = useState([]);



  const token = localStorage.getItem('token')
  const ajout = async () => {
    let token = localStorage.getItem('token');
    sett(!ok)
    // Check if token exists
    if (!token) {
      console.error('No authorization token found.');
      return; // Exit the function if there's no token
    }

    try {
      const response = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/favoris-toggle/" + id,
        {}, // Request body
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      //  console.log(response.data);
      // Optionally handle state update here
    } catch (err) {
      console.error('Error occurred:', err);
    }
  };
  const [tabimage, settab] = useState([]);
  const [firstimg, setfirst] = useState('');
  const [second, settow] = useState('');
  const [free, setfree] = useState('');
  const gallery = async () => {
    let token = localStorage.getItem('token');

    // Check if token exists
    if (!token) {
      console.error('No authorization token found.');
      return; // Exit the function if there's no token
    }

    try {
      const response = await axios.get(
        `https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/pictures`,
       
      );

      console.log(response.data);
      // Optionally handle state update here
      settab(response.data)
      setfirst(response.data[0].image)
      settow(response.data[1].image)
      setfree(response.data[2].image)
    } catch (err) {
      console.error('Error occurred:', err);
    }
  };
  const navigate = useNavigate();
  const [voireg, setgallery] = useState(false);
  useEffect(() => {
    gallery()
  }, [voireg])
  function Retoure() {
    navigate('/')
  }
  const urlToShare = `https://macerise-front.masovia-madagascar.com/detailsalon/${id}`;
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;

  const handleSharefacebooock = () => {
    window.open(facebookShareLink, '_blank');
  };


  const handleShareInsta = () => {
    const url = `https://macerise-front.masovia-madagascar.com/detailsalon/${id}`;
    const message = encodeURIComponent('Découvrez ce lien !');
    
    // Instagram ne prend pas en charge le partage direct d'URL, nous redirigeons donc l'utilisateur
    // vers une page qui leur permettra de copier ce lien.
    const instagramShareUrl = `https://www.instagram.com/?url=${url}&text=${message}`;
    
    window.open(instagramShareUrl, '_blank');
  };

  const handleShareLinkedIn = () => {
    const pageUrl = `https://macerise-front.masovia-madagascar.com/detailsalon/${id}`;
    const title = 'Découvrez ceci!'; // Titre à partager.
    const summary = 'Venez découvrir ce salon incroyable!'; // Résumé à partager.
    const source = pageUrl; // Source du contenu à partager.

    const linkedInShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(pageUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(summary)}&source=${encodeURIComponent(source)}`;

    // Ouvre la page de partage LinkedIn
    window.open(linkedInShareLink, '_blank');
  };
  const [partage, setpartage] = useState(false);
  return (
    <Box >
      {voireg ? <div className='gallerry'><CloseIcon onClick={() => setgallery(false)} className='fermerg' />

        <img src={`https://api-macerise.masovia-madagascar.com/storage/${firstimg}`} alt=''
          style={{ objectFit: 'cover', width: '100%', borderRadius: '8px 8px 0 0', height: '80%' }} />

        <div className='contentg' style={{ width: '100%', height: '20%' }}>
          {tabimage && tabimage.map((rol, key) => (
            <img key={key} className='imagegg' src={`https://api-macerise.masovia-madagascar.com/storage/${rol.image}`} alt="" onClick={() => setfirst(rol.image)}
              style={{ width: '90px', borderRadius: '8px ', minHeight: '50px', maxHeight: '50px', cursor: 'pointer' }} />
          ))}


        </div>
      </div> : ''}
      {voireg ? <div onClick={() => setgallery(false)} className='tranpareg'></div> : ''}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="body1" >Accueil  &gt;  {service.salon ? service.salon.name : ''}</Typography>
      </Box>

      <Box display="flex" width="100%" flexDirection={['column', 'column', 'row']}>
        <Paper elevation={3} sx={{ position: 'relative', width: ['100%', '100%', '58%'], overflow: 'hidden', borderRadius: 2, height: ['220px', '220px', '455px'] }}>
          <img src={`https://api-macerise.masovia-madagascar.com/storage/${firstimg}`} alt=""
            style={{ width: '100%', borderRadius: '8px', height: '100%' }} />
          <Box sx={{ position: 'absolute', top: 16, left: 16 }}>
            <CustomButton onClick={() => Retoure()} startIcon={<ArrowBackIcon />}>Retour</CustomButton>
          </Box>
        </Paper>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: ['100%', '100%', '40%'], ml: 'auto', mt: [2, 2, 0] }}>
          <ImagePanel src={`https://api-macerise.masovia-madagascar.com/storage/${second}`} position={{ top: 16, left: 16 }} alt="">
            <CustomButton onClick={() => setpartage(true)} startIcon={<ShareIcon />}>Partager</CustomButton>
            <CustomButton sx={{ outline: 'none' }} startIcon={ok ? <FavoriteBorderIcon color="error" /> : <FavoriteIcon color="error" />} onClick={() => ajout()} >Ajouter aux favoris</CustomButton>
          </ImagePanel>

          <ImagePanel src={`https://api-macerise.masovia-madagascar.com/storage/${free}`} position={{ bottom: 16, right: 16 }} alt="">
            <CustomButton onClick={() => setgallery(true)} startIcon={<ZoomOutMapIcon />}>Ouvrir la galerie</CustomButton>
          </ImagePanel>
          {partage ? <div className='partager' >
            <IconButton onClick={() => setpartage(false)} aria-label="close" sx={{
            backgroundColor:'white' , position:'absolute',right:'10px',top:'10px', color:'red',    boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)'
            }}>
                <CloseIcon />
            </IconButton>
            <div>

              <div style={{fontSize:'20px',fontWeight:'900',padding:'25px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px',borderBottom:'1px solid black' }}>
               Partager sur
          
              </div>
              <div>
             

              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <IconButton onClick={() => handleSharefacebooock()} target="_blank" sx={{width:'50px',height:'50px'}}>
                <FacebookIcon sx={{ color: '#3b5998' ,width:'50px',height:'50px'}} /> {/* Couleur Facebook */}
              </IconButton>
              <IconButton onClick={() => handleShareInsta()} target="_blank">
                <InstagramIcon sx={{ color: '#C13584',width:'50px',height:'50px' }} /> {/* Couleur Instagram */}
              </IconButton>
              <IconButton onClick={() => handleShareLinkedIn()} target="_blank">
                <LinkedInIcon sx={{ color: '#0077b5' ,width:'50px',height:'50px'}} /> {/* Couleur LinkedIn */}
              </IconButton>
              </div>
            </div></div> : ''}
        </Box>
      </Box>


    </Box >)
};

export default ImageGallery;
