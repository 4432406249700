// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header .logo{
   width: 17vw;
   display: flex;
   align-items: center;
   justify-content: center;
   height:40px;
}
header img{
   width: 17vw;
}
@media screen and (max-width:800px) {
   header .logo{
      min-width: 40vw;
   } 
   header img{
      width: 40vw;
   }
}`, "",{"version":3,"sources":["webpack://./src/component/outil/Logo.css"],"names":[],"mappings":"AAAA;GACG,WAAW;GACX,aAAa;GACb,mBAAmB;GACnB,uBAAuB;GACvB,WAAW;AACd;AACA;GACG,WAAW;AACd;AACA;GACG;MACG,eAAe;GAClB;GACA;MACG,WAAW;GACd;AACH","sourcesContent":["header .logo{\n   width: 17vw;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n   height:40px;\n}\nheader img{\n   width: 17vw;\n}\n@media screen and (max-width:800px) {\n   header .logo{\n      min-width: 40vw;\n   } \n   header img{\n      width: 40vw;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
