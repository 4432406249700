import React, { useState } from 'react'
import './Etablissement.css'
import { NavLink } from 'react-router-dom'
import { salons } from '../../authentification/Authentificationl';
import { useEffect } from 'react';
export default function Etablissement({ fsalonId, loginv }) {

      function idsal(val) {
            fsalonId(val)
      }

      const [salonliste, setsalon] = useState([]);
  
      const handlsalon = async () => {
            try {
                  const userData = await salons();
                  setsalon(userData)
                  //   console.log('salon listee', userData);      
                // console.log(userData)
            } catch (error) {
                  console.log(error)
            }
      };
      useEffect(() => {
         
      console.log(localStorage.getItem('token'),sessionStorage.getItem('token'))

            handlsalon();
      },[])
     
      return (
            <div className='Etablissement'>
                  <h4>Etablissement à la une</h4>
                  <div className='EtablissementImg'>
                        { /* tab1.map((item, index) => {
                  return <div key={index}>{item}</div>; // This won't work
                  })*/}

                        {

                              salonliste.salons ?
                                    salonliste.salons.map((salon, key) => {
                                          return <div className='card' key={salon.id}>
                                                <img src='./image/cheri1.png' alt='' ></img>
                                                <div className='description'>
                                                      <p className='titre'>{salon.name}</p>
                                                      <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                                                      <p className='payse'>{salon.city}</p>
                                                      <p className='expert'>Chez votre expert</p>
                                                      <hr></hr>

                                                      <NavLink
                                                         className='plusdetaille'
                                                            to={`/detailsalon/${salon.id}`}
                                                          
                                                      >
                                                         

                                                                  <p>

                                                                        Je veux en savoir plus
                                                                 
                                                            </p>
                                                            <i className='ti-angle-down'></i>

                                               
                                                </NavLink>
                                          </div>

                                          </div>

                                    })
                  : 'loading....'
                        }
                  {  /*salonliste.salons?console.log(salonliste.salons):'' */}

                  {/*    <div className='card'>
                        <img src='./image/cheri1.png' alt='' ></img>
                        <div className='description'>
                         <p className='titre'>Massari Club</p>   
                          <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                          <p className='payse'>Javel, Paris</p>
                          <p className='expert'>Chez votre expert</p>
                          <hr></hr>
                          <div className='plusdetaille'><p><NavLink
                                    to="/detail"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                    >
                                     Je veux en savoir plus
                              </NavLink></p><i className='ti-angle-down'></i></div>
                        </div>    
                  </div>
                  <div className='card'>
                        <img src='./image/cherie2.png' alt='' ></img>
                        <div className='description'>
                                          <p className='titre'>Massari Club</p>   
                                          <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                                          <p className='payse'>Javel, Paris</p>
                                          <p className='expert'>Chez votre expert</p>
                                          <hr></hr>
                                          <div className='plusdetaille'><p><NavLink
                                    to="/detail"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                    >
                                     Je veux en savoir plus
                              </NavLink></p><i className='ti-angle-down'></i></div>
                        </div>    
                  </div>
                  <div className='card'>
                        <img src='./image/cherie3.jpg' alt='' ></img>
                        <div className='description'>
                         <p className='titre'>Massari Club</p>   
                          <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                          <p className='payse'>Javel, Paris</p>
                          <p className='expert'>Chez votre expert</p>
                          <hr></hr>
                          <div className='plusdetaille'><p>
                             
                              <NavLink
                                    to="/detail"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                    >
                                     Je veux en savoir plus
                              </NavLink>
                              
                              </p><i className='ti-angle-down'></i></div>
                        </div>    
                  </div>
                  <div className='card'>
                        <img src='./image/cherie4.png' alt='' ></img>
                        <div className='description'>
                         <p className='titre'>Massari Club</p>   
                          <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                          <p className='payse'>Javel, Paris</p>
                          <p className='expert'>Chez votre expert</p>
                          <hr></hr>
                          <div className='plusdetaille'><p><NavLink
                                    to="/detail"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                    >
                                     Je veux en savoir plus
                              </NavLink></p><i className='ti-angle-down'></i></div>
                        </div>    
                  </div>
                  <div className='card'>
                        <img src='./image/cherie5.png' alt='' ></img>
                        <div className='description'>
                         <p className='titre'>Massari Club</p>   
                          <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                          <p className='payse'>Javel, Paris</p>
                          <p className='expert'>Chez votre expert</p>
                          <hr></hr>
                          <div className='plusdetaille'><p><NavLink
                                    to="/detail"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                    >
                                     Je veux en savoir plus
                              </NavLink></p><i className='ti-angle-down'></i></div>
                        </div>    
                  </div> */}
            </div>
            </div >
      )
}
