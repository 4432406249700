import React, { useEffect } from 'react';
import ServicesMassages from './ServicesMassages'
import VueAccueil from './VueAccueil';
import Recommander from './Recommander';
import TrouverProf from './TrouverProf';
import Etablissement from './Etablissement';
import NouveauPro from './NouveauPro';
import QuestionFre from './QuestionFre';
import Footer from '../outil/Footer'
import NavMain from '../Navigation/NavMain';

export default function Accueil({val,fsalonId,fcategoId}) {
  useEffect(()=>{
     // window.scroll({top:0})
  },[])
  return (
     <>
        <NavMain val={val}/>
        
        <VueAccueil/>
     
        <ServicesMassages  fcategoId={fcategoId}/>
        <Recommander/>
        <TrouverProf/>
        <Etablissement fsalonId={fsalonId} loginv={val} />
        <NouveauPro/>
        <QuestionFre/>
        <Footer/>
   </>
  )
}

