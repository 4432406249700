import React, { useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from "@mui/material";


const Cardo = ({ data }) => {
    useEffect(() => {
       console.log(data)
    }, [])
    return (
        <>

            <Stack sx={{ marginTop: '10px', marginLeft: '25px' }}>
                {/*data ? data.map((cate, key) => {

                    <Accordion key={key}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography>{cate.name}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>
                              {cate.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                })
                    : 'loading....'
               */ }
              

             
             
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />} >
                            <Typography></Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>
               
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
           
             

             

            </Stack>
        </>
    )
}
export default Cardo