import { Button } from "@mui/material";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

const SecondaryButton = ({ children, size, sx }) => {
  const [contact,setContact] = useState(false)

const openModal =()=>{
  setContact(true)
}
const CloseModal =()=>{
  setContact(false)
}

  return (
    <>
      <Button
      elevation={0}
      variant="contained"
      size={size}
      sx={{
        ...sx,
        background: "#ebebeb",
        color: "#555555",
        fontWeight: "bold",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#ebebeb",
          boxShadow: "none",
        },
        textTransform: "none",
      }}
      onClick={openModal}
    >
      {children}
      
    </Button>
   <Dialog open={contact} onClose={CloseModal}>
  <DialogTitle>Contact l'agent</DialogTitle>
  <DialogContent>
    <Typography variant="h6">
      0347342140
    </Typography>
  </DialogContent>
 </Dialog>
    </>
  
  );

 
};
export default SecondaryButton;
