import React from 'react'
import MenuConnecter from '../outil/MenuConnecter'
import AuthConnect from '../outil/AuthConnect'
import Logo from '../outil/Logo'
export default function NavPro() {
  return (
    <header>
    <div className='simplemenu'>
              <Logo/>
              <MenuConnecter/> 
             <AuthConnect/> 
      </div> 
   </header>
  )
}
