import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { AddCircle } from "@mui/icons-material";
import { useState } from "react";
import ModalPaye from "./modalPayement";

export default function InputPlace({ nbPlace, price }) {
  const [open, setOpen] = useState(false);
  const [compteur, setCompteur] = useState(1);
  const increment = () => {
    setCompteur((prevCompteur) => prevCompteur + 1);
  };

  const decrement = () => {
    setCompteur((prevCompteur) => (prevCompteur > 1 ? prevCompteur - 1 : 1));
  };
  return (
    <Stack gap={2} mt={5}>
      <Stack
        direction={"row"}
        sx={{
          width: "200px",
          border: "1px solid rgba(0, 0, 0, 0.35)",
          alignItems: "center",
          borderRadius: "5px",
        }}
      >
        <Typography
          sx={{
            width: "55%",
            ml: "10px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {price * compteur} $
        </Typography>
        <Box
          sx={{
            width: "45%",
            bgcolor: "#F4F2FF",
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            borderRadius: "5px",
          }}
        >
          <IconButton>
            <RemoveCircleIcon
              onClick={decrement}
              sx={{ color: compteur === 1 ? "#AAA" : "#000" }}
              cursor="pointer"
            />
          </IconButton>
          <Input sx={{ display: "none" }} />
          <Typography> {compteur} </Typography>
          <IconButton cursor="pointer">
            <AddCircle
              onClick={increment}
              sx={{ color: compteur === nbPlace ? "#AAA" : "#000" }}
            />
          </IconButton>
        </Box>
      </Stack>
      <Button
        variant="contained"
        sx={{ width: "fit-content", bgcolor: "#ff4dd8", '&:hover':{bgcolor: "#000"} }}
        fullWidth={false}
        onClick={()=>setOpen(true)}
      >
        Payer
      </Button>
      <ModalPaye setOpen= {setOpen} unitPrice={price}  places={compteur} open={open} />
    </Stack>
  );
}
