import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Delete, Edit, CheckCircle, Visibility } from "@mui/icons-material";
import axios from "axios";

const Evenent = () => {
  const [openModal, setOpenModal] = useState(false);
  const [truee, settrue] = useState(false)
  const [openModalAjout, setOpenModalAjout] = useState(false);
  const [openModaledd, setOpenModaledd] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [masseur, setmass] = useState([]);
  const [countryId, setCountryId] = useState("");
  const [error, setError] = useState("");
  const token = localStorage.getItem('token')
  const messagexx = document.querySelector('.popx');
  const messagexxf = document.querySelector('.popxf');
  const [messagex, setmessagex] = useState('');
  const [messagexa, setmessagexa] = useState('');


  const [name, setName] = useState("");
  const [price, setprice] = useState("");
  const [date, setdate] = useState("");
  const [heure, setheure] = useState("");
  const [lieu, setlieu] = useState("");
  const [intervenant, setintervenant] = useState("");
  const [nombre_place, setnombreplace] = useState();
  const [organisateur, setorganisateur] = useState("");

  const [namex, setNamex] = useState("");
  const [pricex, setpricex] = useState("");
  const [datex, setdatex] = useState("");
  const [heurex, setheurex] = useState("");
  const [lieux, setlieux] = useState("");
  const [intervenantx, setintervenantx] = useState("");
  const [nombre_placex, setnombreplacex] = useState();
  const [organisateurx, setorganisateurx] = useState("");
  const [id, setid] = useState("");


  const fetchevent = async () => {
    try {
      const res = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/evenments"

      );

      console.log(res.data.evenment);
      setmass(res.data.evenment)


    } catch (error) {
      console.error('Erreur lors de l\'envoi des données à l\'API:', error);
    }
  };

  useEffect(() => {
    fetchevent()
  }, [])
  const handelClose = () => {
    setOpenModal(false)
  }
  const handleCloseedd = () => {
    setOpenModaledd(false);
  };

  const handleCloseAjout = () => {
    setOpenModalAjout(false);
  };


  const handelOpen = (id, names, price, date, heure, lieu, intervenant, nombre_place, organisateur) => {
    setOpenModal(true)
    setid(id)
    setNamex(names)
    setpricex(price)
    setdatex(date)
    setheurex(heure)
    setlieux(lieu)
    setintervenantx(intervenant)
    setnombreplacex(nombre_place)
    setorganisateurx(organisateur)


  };
  const handleOpenAjout = () => {
    setOpenModalAjout(true);
  };
  const handleOpenedd = (id, names, price, date, heure, lieu, intervenant, nombre_place, organisateur) => {

    setid(id)
    setNamex(names)
    setpricex(price)
    setdatex(date)
    setheurex(heure)
    setlieux(lieu)
    setintervenantx(intervenant)
    setnombreplacex(nombre_place)
    setorganisateurx(organisateur)
    setOpenModaledd(true)

  };
  const [idsup, setidsup] = useState();
  const [valid, setvalid] = useState(false);
  function handleDeletex(ideventx) {
    setvalid(true);
    setidsup(ideventx)

  }
  const handleDelete = async () => {

    setvalid(false);
    let ideventx = idsup;


    try {
      const res = await axios.delete("https://www.api-macerise.masovia-madagascar.com/api/delete_evenment/" + ideventx,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      console.log(res);
      setmessagex("supprimé avec succès");
      settrue(true)
      if (messagexx) {
        messagexx.style.display = 'flex';
      }

      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = 'none';
        }
        settrue(false)
      }, 6000);

    } catch (error) {
      console.error('Erreur lors de l\'envoi des données à l\'API:', error);
    }

    // Rafraîchir les événements après la suppression
    fetchevent();

  };
  // Fonction appelée lors du clic sur le bouton "Ajouter"
  const handleAddMasseur = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "/add_evnmt",
        {
          name,
          price,
          date,
          heure,
          lieu,
          intervenant,
          nombre_place,
          organisateur,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchevent();
      handleCloseAjout()
      setmessagex("ajout avec success ")
      settrue(true)
      if (messagexx) {
        messagexx.style.display = 'flex';
      }
      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = 'none';

        }
        settrue(false)
      }, 6000);
      // console.log("Réponse:", response);
    } catch (error) {
      console.error("Erreur lors de l'ajout du masseur:", error);
    }
  };
  // Fonction appelée lors du clic sur le bouton "update"
  const handleedd = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        "/update_evenment/" + id,
        {
          name: namex,
          price: pricex,
          date: datex,
          heure: heurex,
          lieu: lieux,
          intervenant: intervenantx,
          nombre_place: nombre_placex,
          organisateur: organisateurx,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchevent();
      handleCloseedd()
      setmessagex("mise àjour avec success ")
      settrue(true)
      if (messagexx) {
        messagexx.style.display = 'flex';
      }
      setTimeout(() => {
        if (messagexx) {
          messagexx.style.display = 'none';

        }
        settrue(false)
      }, 6000);
      // console.log("Réponse:", response);
    } catch (error) {
      console.error("Erreur lors de l'ajout du masseur:", error);
    }
  };
  return (
    <div className="rigt">
      {valid ? <div className='validation'><span className='spann'><p>Voulez-vous vraiment le supprimer?  </p><span><button onClick={handleDelete}>OUI</button><button onClick={() => setvalid(false)}>NON</button></span> </span> </div> : ''}
      {truee ? <div className='popx'>{messagex}</div> : ''}
      {/* Dialog pour ajouter un masseur */}
      <Dialog open={openModalAjout} onClose={handleCloseAjout}>
        <DialogTitle>Ajouter Evenement</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            margin="dense"
            label="Entrer nom d'evenement"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Entrer price"
            fullWidth
            type="number"
            variant="outlined"
            value={price}
            onChange={(e) => setprice(e.target.value)}
            required
          />
          <TextField
            type="date"
            margin="dense"
            label="date"
            fullWidth
            variant="outlined"
            value={date}
            onChange={(e) => setdate(e.target.value)}
            required
          />
          <TextField
            required
            type="time"
            margin="dense"
            label="heure"
            fullWidth
            variant="outlined"
            value={heure}
            onChange={(e) => setheure(e.target.value)}
          />
          <TextField
            type="text"
            margin="dense"
            label="lieu"
            fullWidth
            variant="outlined"
            value={lieu}
            onChange={(e) => setlieu(e.target.value)}
            required
          />
          <TextField
            fullWidth
            required
            label="intervenant"
            type="texte"
            value={intervenant}
            onChange={(e) => setintervenant(e.target.value)}

          />

          <TextField
            type="number"
            margin="dense"
            label="nombre place"
            fullWidth
            variant="outlined"
            value={nombre_place}
            onChange={(e) => setnombreplace(e.target.value)}
            required
          />
          <TextField
            fullWidth

            label="organisateur"
            type="texte"
            value={organisateur}
            onChange={(e) => setorganisateur(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F", marginTop: '5px' }}
            onClick={handleAddMasseur} // Appel de la fonction lors du clic
          >
            Ajouter
          </Button>
        </DialogContent>
      </Dialog>
      {/* Dialog pour edit un event */}
      <Dialog open={openModaledd} onClose={handleCloseedd}>
        <DialogTitle>Ajouter Evenement</DialogTitle>
        <DialogContent>
          <TextField
            type="text"
            margin="dense"
            label="Entrer nom d'evenement"
            fullWidth
            variant="outlined"
            value={namex}
            onChange={(e) => setNamex(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Entrer price"
            fullWidth
            type="number"
            variant="outlined"
            value={pricex}
            onChange={(e) => setpricex(e.target.value)}
            required
          />
          <TextField
            type="date"
            margin="dense"
            label="date"
            fullWidth
            variant="outlined"
            value={datex}
            onChange={(e) => setdatex(e.target.value)}
            required
          />
          <TextField
            required
            type="time"
            margin="dense"
            label="heure"
            fullWidth
            variant="outlined"
            value={heurex}
            onChange={(e) => setheurex(e.target.value)}
          />
          <TextField
            type="text"
            margin="dense"
            label="lieu"
            fullWidth
            variant="outlined"
            value={lieux}
            onChange={(e) => setlieux(e.target.value)}
            required
          />
          <TextField
            fullWidth
            required
            label="intervenant"
            type="texte"
            value={intervenantx}
            onChange={(e) => setintervenantx(e.target.value)}

          />

          <TextField
            type="number"
            margin="dense"
            label="nombre place"
            fullWidth
            variant="outlined"
            value={nombre_placex}
            onChange={(e) => setnombreplacex(e.target.value)}
            required
          />
          <TextField
            fullWidth

            label="organisateur"
            type="texte"
            value={organisateurx}
            onChange={(e) => setorganisateurx(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            className="buton"
            sx={{ background: "#F9194F" }}
            onClick={handleedd} // Appel de la fonction lors du clic
          >
            update
          </Button>
        </DialogContent>
      </Dialog>
      <TableContainer
        // className="main-content-wrap d-flex flex-column"
        component={Paper}
        sx={{
          width: { xs: '0%', sm: '75%', md: '100%' }, // Table responsive
          overflowX: 'auto',
          marginLeft: { xs: '0px', sm: '150px', md: '0px' }
          , marginTop: { xs: '0px', sm: '30px', md: '0px' }// Taille de texte responsive dans la table
        }}
      >
        {/* {message === 'ok'? <Message/>:''} */}
        <Typography sx={{ fontSize: { xs: '0px', sm: '20px', md: '20px' }, marginLeft: { xs: '0px', sm: '20px', md: '15px' } }}>Evenements </Typography>
        <div style={{ padding: 16 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAjout}
            sx={{ background: "#F9194F" }}
          >
            Ajouter
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Nom de L'evenement</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Date</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Organisateur</TableCell>
              <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              {masseur ? masseur.map((event, key) => (
                <TableRow key={key}>


                  <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{event.name}</TableCell>
                  <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{event.date}</TableCell>
                  <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{event.organisateur}</TableCell>
                  <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>
                    <IconButton onClick={() => handelOpen(event.id, event.name, event.price, event.date, event.heure, event.lieu, event.intervenant, event.nombre_place, event.organisateur)}>
                      <Visibility /> {/* Bouton en forme d'œil */}
                    </IconButton>
                    <IconButton onClick={() => handleOpenedd(event.id, event.name, event.price, event.date, event.heure, event.lieu, event.intervenant, event.nombre_place, event.organisateur)} >
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeletex(event.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )) : ''}
            </React.Fragment>
          </TableBody>
        </Table>
        <Dialog open={openModal} onClose={handelClose}>
          <DialogTitle  style={{ color: '#F9194F' }}>Information sur l'evenement</DialogTitle>
          <DialogContent>

            {
              <Typography>
                <hr style={{ marginTop: '5px' }} />
                <span style={{ fontWeight: '900' }}>Nom: </span>  {namex}<br></br>
                <span style={{ fontWeight: '900' }}> Price: </span>  {pricex}<br></br>
                <span style={{ fontWeight: '900' }}> Date: </span> {datex}<br></br>
                <span style={{ fontWeight: '900' }}> Heure: </span>  {heurex}<br></br>
                <span style={{ fontWeight: '900' }}>  Lieu: </span>   {lieux}<br></br>
                <span style={{ fontWeight: '900' }}>   Intervenant: </span>   {intervenantx}<br></br>
                <span style={{ fontWeight: '900' }}>   Nombre place: </span>   {nombre_placex}<br></br>
                <span style={{ fontWeight: '900' }}>   Organisateur:  </span>  {organisateurx}<br></br>
                <hr />
              </Typography>
            }

          </DialogContent>
        </Dialog>
      </TableContainer>
    </div>
  );
};

export default Evenent;
