import React from "react";
import "./Dashb.css";

const ProfilDash =()=>{
    return(
        <>
        <div className="main-content-wrap d-flex flex-column" id="main">
          <div className="main-header">
            <div className="logo">
              <img src="dist-assets/images/logo.png" alt="" />
            </div>
            <div className="menu-toggle">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="d-flex align-items-center">
              {/* Mega menu */}
              <div className="dropdown mega-menu d-none d-md-block">
                <a
                  href="#"
                  className="btn text-muted dropdown-toggle mr-3"
                  id="dropdownMegaMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Mega Menu
                </a>
                <div
                  className="dropdown-menu text-left"
                  aria-labelledby="dropdownMegaMenuButton"
                >
                  <div className="row m-0">
                    <div className="col-md-4 p-4 bg-img">
                      <h2 className="title">
                        Mega Menu <br /> Sidebar
                      </h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Asperiores natus laboriosam fugit, consequatur.
                      </p>
                      <p className="mb-4">
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Exercitationem odio amet eos dolore suscipit
                        placeat.
                      </p>
                      <button className="btn btn-lg btn-rounded btn-outline-warning">
                        Learn More
                      </button>
                    </div>
                    <div className="col-md-4 p-4">
                      <p className="text-primary text--cap border-bottom-primary d-inline-block">
                        Features
                      </p>
                      <div className="menu-icon-grid w-auto p-0">
                        <a href="#">
                          <i className="i-Shop-4"></i> Home
                        </a>
                        <a href="#">
                          <i className="i-Library"></i> UI Kits
                        </a>
                        <a href="#">
                          <i className="i-Drop"></i> Apps
                        </a>
                        <a href="#">
                          <i className="i-File-Clipboard-File--Text"></i> Forms
                        </a>
                        <a href="#">
                          <i className="i-Checked-User"></i> Sessions
                        </a>
                        <a href="#">
                          <i className="i-Ambulance"></i> Support
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4 p-4">
                      <p className="text-primary text--cap border-bottom-primary d-inline-block">
                        Components
                      </p>
                      <ul className="links">
                        <li>
                          <a href="accordion.html">Accordion</a>
                        </li>
                        <li>
                          <a href="alerts.html">Alerts</a>
                        </li>
                        <li>
                          <a href="buttons.html">Buttons</a>
                        </li>
                        <li>
                          <a href="badges.html">Badges</a>
                        </li>
                        <li>
                          <a href="carousel.html">Carousels</a>
                        </li>
                        <li>
                          <a href="lists.html">Lists</a>
                        </li>
                        <li>
                          <a href="popover.html">Popover</a>
                        </li>
                        <li>
                          <a href="tables.html">Tables</a>
                        </li>
                        <li>
                          <a href="datatables.html">Datatables</a>
                        </li>
                        <li>
                          <a href="modals.html">Modals</a>
                        </li>
                        <li>
                          <a href="nouislider.html">Sliders</a>
                        </li>
                        <li>
                          <a href="tabs.html">Tabs</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* / Mega menu */}
              <div className="search-bar">
                <input type="text" placeholder="Search" />
                <i className="search-icon text-muted i-Magnifi-Glass1"></i>
              </div>
            </div>
            <div style={{ margin: "auto" }}></div>
            <div className="header-part-right">
              {/* Full screen toggle */}
              <i
                className="i-Full-Screen header-icon d-none d-sm-inline-block"
                data-fullscreen
              ></i>
              {/* Grid menu Dropdown */}
              <div className="dropdown">
                <i
                  className="i-Safe-Box text-muted header-icon"
                  role="button"
                  id="dropdownMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className="menu-icon-grid">
                    <a href="#">
                      <i className="i-Shop-4"></i> Home
                    </a>
                    <a href="#">
                      <i className="i-Library"></i> UI Kits
                    </a>
                    <a href="#">
                      <i className="i-Drop"></i> Apps
                    </a>
                    <a href="#">
                      <i className="i-File-Clipboard-File--Text"></i> Forms
                    </a>
                    <a href="#">
                      <i className="i-Checked-User"></i> Sessions
                    </a>
                    <a href="#">
                      <i className="i-Ambulance"></i> Support
                    </a>
                  </div>
                </div>
              </div>
              {/* Notification */}
              <div className="dropdown">
                <div
                  className="badge-top-container"
                  role="button"
                  id="dropdownNotification"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="badge badge-primary" style={{background:'#F9194F'}}>3</span>
                  <i className="i-Bell text-muted header-icon"></i>
                </div>
                {/* Notification dropdown */}
                <div
                  className="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none"
                  aria-labelledby="dropdownNotification"
                  data-perfect-scrollbar
                  data-suppress-scroll-x="true"
                >
                  <div className="dropdown-item d-flex">
                    <div className="notification-icon">
                      <i className="i-Speach-Bubble-6 text-primary mr-1"></i>
                    </div>
                    <div className="notification-details flex-grow-1">
                      <p className="m-0 d-flex align-items-center">
                        <span>New message</span>
                        <span className="badge badge-pill badge-primary ml-1 mr-1">
                          new
                        </span>
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto">
                          10 sec ago
                        </span>
                      </p>
                      <p className="text-small text-muted m-0">
                        James: Hey! are you busy?
                      </p>
                    </div>
                  </div>
                  <div className="dropdown-item d-flex">
                    <div className="notification-icon">
                      <i className="i-Receipt-3 text-success mr-1"></i>
                    </div>
                    <div className="notification-details flex-grow-1">
                      <p className="m-0 d-flex align-items-center">
                        <span>New order received</span>
                        <span className="badge badge-pill badge-success ml-1 mr-1">
                          new
                        </span>
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto">
                          2 hours ago
                        </span> 
                        {/*  */}
                      </p>
                      <p className="text-small text-muted m-0">
                        1 Headphone, 3 iPhone x
                      </p>
                    </div>
                  </div>
                  <div className="dropdown-item d-flex">
                    <div className="notification-icon">
                      <i className="i-Empty-Box text-danger mr-1"></i>
                    </div>
                    <div className="notification-details flex-grow-1">
                      <p className="m-0 d-flex align-items-center">
                        <span>Product out of stock</span>
                        <span className="badge badge-pill badge-danger ml-1 mr-1">
                          3
                        </span>
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto">
                          10 hours ago
                        </span>
                      </p>
                      <p className="text-small text-muted m-0">
                        Headphone E67, R98, XL90, Q77
                      </p>
                    </div>
                  </div>
                  <div className="dropdown-item d-flex">
                    <div className="notification-icon">
                      <i className="i-Data-Power text-success mr-1"></i>
                    </div>
                    <div className="notification-details flex-grow-1">
                      <p className="m-0 d-flex align-items-center">
                        <span>Server Up!</span>
                        <span className="badge badge-pill badge-success ml-1 mr-1">
                          3
                        </span>
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto">
                          14 hours ago
                        </span>
                      </p>
                      <p className="text-small text-muted m-0">
                        Server rebooted successfully
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Notification End */}
              {/* User avatar dropdown */}
              <div className="dropdown">
                <div className="user col align-self-end">
                  <img
                    src="../../dist-assets/images/faces/1.jpg"
                    id="userDropdown"
                    alt=""
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="userDropdown"
                  >
                    <div className="dropdown-header">
                      <i className="i-Lock-User mr-1"></i> Timothy Carlson
                    </div>
                    <a className="dropdown-item">Account settings</a>
                    <a className="dropdown-item">Billing history</a>
                    <a className="dropdown-item" href="signin.html">
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ............................................................................... */}
          <div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2">Version 1</h1>
              <ul>
                <li>
                  <a href="">Dashboard</a>
                </li>
                <li>Version 1</li>
              </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
              {/* ICON BG */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div className="card-body text-center">
                    <i className="i-Add-User" style={{color:'#F9194F'}}></i>
                    <div className="content">
                      <p className="text-muted mt-2 mb-0">New Leads</p>
                      <p className="text-24 line-height-1 mb-2" style={{color:'gray'}}>
                        205
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div className="card-body text-center">
                    <i className="i-Financial" style={{color:'#F9194F'}}></i>
                    <div className="content">
                      <p className="text-muted mt-2 mb-0">Sales</p>
                      <p className=" text-24 line-height-1 mb-2" style={{color:'gray'}}>
                        $4021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div className="card-body text-center">
                    <i className="i-Checkout-Basket" style={{color:'#F9194F'}}></i>
                    <div className="content">
                      <p className="text-muted mt-2 mb-0">Orders</p>
                      <p className=" text-24 line-height-1 mb-2"  style={{color:'gray'}}>
                        80
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                  <div className="card-body text-center">
                    <i className="i-Money-2" style={{color:'#F9194F'}}></i>
                    <div className="content">
                      <p className="text-muted mt-2 mb-0">Expense</p>
                      {/* .............................................................................................................. */}
                      <p className=" text-24 line-height-1 mb-2" style={{color:'gray'}} >
                        $1200
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title">This Year Sales</div>
                    <div id="echartBar" style={{ height: "300px" }}>
                      {/* .................................................................... */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title">Sales by Countries</div>
                    <div id="echartPie" style={{ height: "300px" }}>
                      {/* ................................................................. */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="card card-chart-bottom o-hidden mb-4">
                      <div className="card-body">
                        <div className="text-muted">Last Month Sales</div>
                        <p className="mb-4 text-24" style={{color:'#F9194F'}}>$40250</p>
                      </div>
                      <div id="echart1" style={{ height: "260px" }}></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="card card-chart-bottom o-hidden mb-4">
                      <div className="card-body">
                        <div className="text-muted">Last Week Sales</div>
                        <p className="mb-4 text-warning text-24">$10250</p>
                      </div>
                      <div id="echart2" style={{ height: "260px" }}></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card o-hidden mb-4">
                      <div className="card-header d-flex align-items-center border-0">
                        <h3 className="w-50 float-left card-title m-0">
                          New Users
                        </h3>
                        <div className="dropdown dropleft text-right w-50 float-right">
                          <button
                            className="btn bg-gray-100"
                            id="dropdownMenuButton1"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="nav-icon i-Gear-2"></i>
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <a className="dropdown-item" href="#">
                              Add new user
                            </a>
                            <a className="dropdown-item" href="#">
                              View All users
                            </a>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="table-responsive">
                          <table className="table text-center" id="user_table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Avatar</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td>Smith Doe</td>
                                <td>
                                  <img
                                    className="rounded-circle m-0 avatar-sm-table"
                                    src="dist-assets/images/faces/1.jpg"
                                    alt=""
                                  />
                                </td>
                                <td>Smith@gmail.com</td>
                                <td>
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                </td>
                                <td>
                                  <a className="text-success mr-2" href="#">
                                    <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                  </a>
                                  <a className="text-danger mr-2" href="#">
                                    <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>Jhon Doe</td>
                                <td>
                                  <img
                                    className="rounded-circle m-0 avatar-sm-table"
                                    src="dist-assets/images/faces/1.jpg"
                                    alt=""
                                  />
                                </td>
                                <td>Jhon@gmail.com</td>
                                <td>
                                  <span className="badge badge-info">
                                    Pending
                                  </span>
                                </td>
                                <td>
                                  <a className="text-success mr-2" href="#">
                                    <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                  </a>
                                  <a className="text-danger mr-2" href="#">
                                    <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>Alex</td>
                                <td>
                                  <img
                                    className="rounded-circle m-0 avatar-sm-table"
                                    src="dist-assets/images/faces/1.jpg"
                                    alt=""
                                  />
                                </td>
                                <td>Otto@gmail.com</td>
                                <td>
                                  <span className="badge badge-warning">
                                    Not Active
                                  </span>
                                </td>
                                <td>
                                  <a className="text-success mr-2" href="#">
                                    <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                  </a>
                                  <a className="text-danger mr-2" href="#">
                                    <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>Mathew Doe</td>
                                <td>
                                  <img
                                    className="rounded-circle m-0 avatar-sm-table"
                                    src="dist-assets/images/faces/1.jpg"
                                    alt=""
                                  />
                                </td>
                                <td>Mathew@gmail.com</td>
                                <td>
                                  <span className="badge badge-success">
                                    Active
                                  </span>
                                </td>
                                <td>
                                  <a className="text-success mr-2" href="#">
                                    <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                  </a>
                                  <a className="text-danger mr-2" href="#">
                                    <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title">Top Selling Products</div>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                      <img
                        className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                        src="dist-assets/images/products/headphone-4.jpg"
                        alt=""
                      />
                      <div className="flex-grow-1">
                        <h5>
                          <a href="" style={{color:'#F9194F'}}>Wireless Headphone E23</a>
                        </h5>
                        <p className="m-0 text-small text-muted">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                        <p className="text-small text-danger m-0">
                          $450 <del className="text-muted">$500</del>
                        </p>
                      </div>
                      <div>
                      <button className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded" style={{color:'white',background:'#F9194F',width:'100px',height:'25px',fontSize:'10px',display:'flex',alignItems:'center',justifyContent:'center' }}>
                          View details
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                      <img
                        className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                        src="dist-assets/images/products/headphone-2.jpg"
                        alt=""
                      />
                      <div className="flex-grow-1">
                        <h5>
                          <a href="" style={{color:'#F9194F'}}>Wireless Headphone Y902</a>
                        </h5>
                        <p className="m-0 text-small text-muted">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                        <p className="text-small text-danger m-0">
                          $550 <del className="text-muted">$600</del>
                        </p>
                      </div>
                      <div>
                      <button className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded" style={{color:'white',background:'#F9194F',width:'100px',height:'25px',fontSize:'10px',display:'flex',alignItems:'center',justifyContent:'center' }}>
                          View details
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                      <img
                        className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                        src="dist-assets/images/products/headphone-3.jpg"
                        alt=""
                      />
                      <div className="flex-grow-1">
                        <h5>
                          <a href="" style={{color:'#F9194F'}}>Wireless Headphone E09</a>
                        </h5>
                        <p className="m-0 text-small text-muted">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                        <p className="text-small text-danger m-0">
                          $250 <del className="text-muted">$300</del>
                        </p>
                      </div>
                      <div>
                      <button className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded" style={{color:'white',background:'#F9194F',width:'100px',height:'25px',fontSize:'10px',display:'flex',alignItems:'center',justifyContent:'center' }}>
                          View details
                        </button>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                      <img
                        className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                        src="dist-assets/images/products/headphone-4.jpg"
                        alt=""
                      />
                      <div className="flex-grow-1">
                        <h5>
                          <a href="" style={{color:'#F9194F'}}>Wireless Headphone X89</a>
                        </h5>
                        <p className="m-0 text-small text-muted">
                          Lorem ipsum dolor sit amet consectetur.
                        </p>
                        <p className="text-small text-danger m-0">
                          $450 <del className="text-muted">$500</del>
                        </p>
                      </div>
                      <div>
                        <button className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded" style={{color:'white',background:'#F9194F',width:'100px',height:'25px',fontSize:'10px',display:'flex',alignItems:'center',justifyContent:'center' }}>
                          View details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body p-0">
                    <div className="card-title border-bottom d-flex align-items-center m-0 p-3">
                      <span>User activity</span>
                      <span className="flex-grow-1"></span>
                      <span className="badge badge-pill badge-warning">
                        Updated daily
                      </span>
                    </div>
                    <div className="d-flex border-bottom justify-content-between p-3">
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">
                          Pages / Visit
                        </span>
                        <h5 className="m-0">2065</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">New user</span>
                        <h5 className="m-0">465</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">Last week</span>
                        <h5 className="m-0">23456</h5>
                      </div>
                    </div>
                    <div className="d-flex border-bottom justify-content-between p-3">
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">
                          Pages / Visit
                        </span>
                        <h5 className="m-0">1829</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">New user</span>
                        <h5 className="m-0">735</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">Last week</span>
                        <h5 className="m-0">92565</h5>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-3">
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">
                          Pages / Visit
                        </span>
                        <h5 className="m-0">3165</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">New user</span>
                        <h5 className="m-0">165</h5>
                      </div>
                      <div className="flex-grow-1">
                        <span className="text-small text-muted">Last week</span>
                        <h5 className="m-0">32165</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-body p-0">
                    <h5 className="card-title m-0 p-3">Last 20 Day Leads</h5>
                    <div id="echart3" style={{ height: "360px" }}></div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer Start */}
            <div className="flex-grow-1"></div>
            <div className="app-footer">

          
            </div>
            {/* Footer end */}
          </div>
          <div />
          {/* ....................................................................................... */}
          <div className="search-ui">
            <div className="search-header">
              <img src="dist-assets/images/logo.png" alt="" className="logo" />
              <button className="search-close btn btn-icon bg-transparent float-right mt-2">
                <i className="i-Close-Window text-22 text-muted"></i>
              </button>
            </div>
            <input
              type="text"
              placeholder="Type here"
              className="search-input"
              autoFocus
            />
            <div className="search-title">
              <span className="text-muted">Search results</span>
            </div>
            <div className="search-results list-horizontal">
              {[1, 2, 3, 4].map((num) => (
                <div className="list-item col-md-12 p-0" key={num}>
                  <div className="card o-hidden flex-row mb-4 d-flex">
                    <div className="list-thumb d-flex">
                      <img
                        src={`dist-assets/images/products/headphone-${num}.jpg`}
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1 pl-2 d-flex">
                      <div className="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row">
                        <a href="" className="w-40 w-sm-100">
                          <div className="item-title">Headphone {num}</div>
                        </a>
                        <p className="m-0 text-muted text-small w-15 w-sm-100">
                          Gadget
                        </p>
                        <p className="m-0 text-muted text-small w-15 w-sm-100">
                          $300
                          <del className="text-secondary">$400</del>
                        </p>
                        <p className="m-0 text-muted text-small w-15 w-sm-100 d-none d-lg-block item-badges">
                          <span
                            className={`badge ${
                              num % 2 === 0 ? "badge-primary" : "badge-danger"
                            }`}
                          >
                            {num % 2 === 0 ? "New" : "Sale"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* PAGINATION CONTROL */}
            <div className="col-md-12 mt-5 text-center">
              <nav aria-label="Page navigation example">
                <ul className="pagination d-inline-flex">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            {/* CUSTOMIZER */}
            <div className="customizer">
              <div className="handle">
                <i className="i-Gear spin"></i>
              </div>
              <div
                className="customizer-body"
                data-perfect-scrollbar=""
                data-suppress-scroll-x="true"
              >
                <div className="accordion" id="accordionCustomizer">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <p className="mb-0">Sidebar Colors</p>
                    </div>
                    <div
                      className="collapse show"
                      id="collapseOne"
                      aria-labelledby="headingOne"
                      data-parent="#accordionCustomizer"
                    >
                      <div className="card-body">
                        <div className="colors sidebar-colors">
                          {[
                            "gradient-purple-indigo",
                            "gradient-black-blue",
                            "gradient-black-gray",
                            "gradient-steel-gray",
                            "dark-purple",
                            "slate-gray",
                            "midnight-blue",
                            "blue",
                            "indigo",
                            "pink",
                            "red",
                            "purple",
                          ].map((color) => (
                            <a
                              key={color}
                              className={`color ${color}${
                                color === "dark-purple" ? " active" : ""
                              }`}
                              data-sidebar-class={`sidebar-${color}`}
                            >
                              <i className="i-Eye"></i>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <p className="mb-0">RTL</p>
                    </div>
                    <div
                      className="collapse show"
                      id="collapseTwo"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionCustomizer"
                    >
                      <div className="card-body">
                        <label className="checkbox checkbox-primary">
                          <input id="rtl-checkbox" type="checkbox" />
                          <span>Enable RTL</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}
export default ProfilDash