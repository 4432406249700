import React from 'react'
export default function Blog() {
  return (
    <>
     <div>
     <p>blog</p>
    </div>
    </>
  )
}
