import React from "react";
import Test from "./HeadTarif/Test";
import './Tatif.css'
import Offres from "./suitTarif/Offres";
import Footer from "../../outil/Footer";
    import NavPro from '../../Navigation/NavPro'

const Tarif =()=>{
    return(
        <>
        <NavPro/>
        <div className="container-tarif">
            <Test/>
            <Offres/> 
        </div>
        <Footer/>
        </>
    )
}
export default Tarif