// src/App.js
import React, { useEffect, useState } from 'react';
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
  } from "@mui/material";
import axios from 'axios';
import { Margin } from '@mui/icons-material';
import '../Calendrier/cal.css'
const Repon = () => {
    const [truee, settrue] = useState(false)
    const [validation, setvalidation] = useState(false)
    const [falsee, setfalsee] = useState(false)
    const [messagex, setmessagex] = useState('');
    const [messagexa, setmessagexa] = useState('');
    const [events, setEvents] = useState([]);
    const [quest, setquestion] = useState([]);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token')
    const messagexx = document.querySelector('.popx');
    const messagexxf = document.querySelector('.popxf');
    const fetchquestion = async () => {

        const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/questions');
        setquestion(response.data.questions);
       // console.log(response.data.questions)
    };

    useEffect(() => {
        fetchquestion();
    }, []);
    const fetchEvents = async () => {

        const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/responses');
        setEvents(response.data.responses);
       // console.log(response.data.responses)
    };

    useEffect(() => {
        fetchEvents();
    }, []);



    const [form, setForm] = useState({
        content: '',
        id:'',
        idq:' '
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };



    const [form2, setForm2] = useState({
        id: '',
        qid: '',
        repo:''
    });

    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setForm2((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const [form3, setForm3] = useState({
        salonidd: '',
        eventidd: '',

    });

    /* const handleSubmit = (e) => {
       e.preventDefault();
       // Vous pouvez ici traiter les données du formulaire ou les envoyer à une API
       console.log(form);
     };*/
    const handleSubmit = async (e) => {
        e.preventDefault();
        /*  const dataToSend = {
            date: selectedDate.format('YYYY-MM-DD'),  // Format de la date
            start_time: startTime.format('HH:mm'), // Format de l'heure de début
            end_time: endTime.format('HH:mm') // Format de l'heure de fin
          };


      */
     let content =form.content
     let question_id =form.idq
        try {
            const res = await axios.post("https://www.api-macerise.masovia-madagascar.com/api/add_response",{content,question_id}
                ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            setmessagex("ajout avec success quest :" + content)
            setfalsee(false)
            if (messagexxf) {
                messagexxf.style.display = 'none';
            }
            settrue(true)
            if (messagexx) {
                messagexx.style.display = 'flex';
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = 'none';
                }
            }, 6000);
            console.log(res)
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données à l\'API:', error.response.data.end_time);
            setmessagexa(error.response.data.end_time)
            settrue(false)
            if (messagexx) {
                messagexx.style.display = 'none';
            }

            setfalsee(true)
            if (messagexxf) {
                messagexxf.style.display = 'flex';
            }
            setTimeout(() => {
                if (messagexxf) {
                    messagexxf.style.display = 'none';
                }
            }, 6000);
        }
        fetchEvents()
    };

    const handleEdit = async (eventid, repo,idq) => {
        setmidi(true)



        //console.log(typeof start, end)

        setForm2(prevForm => ({
            ...prevForm, // Conserver les valeurs précédentes
            id: eventid, // mettre à jour l id
            repo: repo, // mettre à jour l id
            qid: idq, // mettre à jour l id
        }));



    }

    const shandleEdit = async (e) => {
        e.preventDefault();
        setmidi(false)
        let eventidd = form2.id;
        let content = form2.repo;
        let question_id=form2.qid
       // console.log(eventidd,content)
        try {
            const res = await axios.put("https://www.api-macerise.masovia-madagascar.com/api/update/response/" +  eventidd , { content,question_id }
                ,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            setmessagex("modification avec success id:" + eventidd)
            settrue(true)
            if (messagexx) {
                messagexx.style.display = 'flex';
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = 'none';
                }
            }, 6000);
            console.log(res)
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données à l\'API:', error);
        }
        fetchEvents()

    };
    const [idsup, setidsup] = useState();
    function handleDeletex(ideventx) {
        setvalid(true);
        setidsup(ideventx)

    }

    const handleDelete = async () => {
        setvalid(false);
    let ideventx=idsup;


        try {
            const res = await axios.delete("https://www.api-macerise.masovia-madagascar.com/api/delete/response/"+ideventx,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès id :" + ideventx);
            settrue(true)
            if (messagexx) {
                messagexx.style.display = 'flex';
            }

            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = 'none';
                }
            }, 6000);

        } catch (error) {
            console.error('Erreur lors de l\'envoi des données à l\'API:', error);
        }

        // Rafraîchir les événements après la suppression
        fetchEvents();

    };

    const [midi, setmidi] = useState(false);
    const [valid, setvalid] = useState(false);

    return (
        <>
            
          {valid? <div className='validation'><span className='spann'><p>Voulez-vous vraiment le supprimer?  </p><span><button onClick={handleDelete}>OUI</button><button onClick={()=> setvalid(false)}>NON</button></span> </span> </div>  :''} 
            <div >
             
                <form onSubmit={handleSubmit} className='marb'>
                  
                       
                    <textarea 
                        className='inputr'
                        type="text"
                        name="content"
                        value={form.content}
                        onChange={handleChange}
                        required
                        placeholder='reponse'
                    />
                       <select 
                           value={form.idq}
                           className="selesct"
                           required
                           onChange={(e) => {
                            setForm((prevForm) => ({
                                ...prevForm,
                                idq: e.target.value,
                            }));
                           //  setSelectedidq(e.target.value);                  
                           }}
                       >
                           <option value=''>id question</option>
                       {quest ? quest.map((event) => (
                        
                        <option value={event.id}>{event.id}</option>
                    )) : ''}

                       </select>
                    
                   

                    <button className='ajout' type="submit">Ajouter</button>
                </form>
                {truee ? <div className='popx'>{messagex}</div> : ''}
                {falsee ? <div className='popxf'>{messagexa}</div> : ''}
                {midi ?

                    <form onSubmit={shandleEdit} className='form2m'>
                        <p className='fermerx' onClick={() => setmidi(false)}> x</p>
                      
                   
                            <p> Reponse id :  {form2.id}</p>
                            <textarea
                                type="text"
                                name="repo"
                                value={form2.repo}
                                onChange={handleChange2}
                                required
                                placeholder='reponse'
                            />
                             <select 
                           value={form2.qid}
                           className="selesct"
                           required
                           onChange={(e) => {
                            setForm2((prevForm) => ({
                                ...prevForm,
                                qid: e.target.value,
                            }));
                           //  setSelectedidq(e.target.value);                  
                           }}
                       >
                           <option value=''>id question</option>
                       {quest ? quest.map((event) => (
                        
                        <option value={event.id}>{event.id}</option>
                    )) : ''}

                       </select>
                             
                            
                          
                        <button className='modif' type="submit">Modifier</button>
                    </form>

                    : ""
                }
                  <table>
          <thead>
            <tr>
              <th>Id R</th>
              <th className='repo'>Réponses</th>
              <th>Id Q</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>

          {events ? events.map((event) => (
                        <tr key={event.id}>
                            <td >{event.id}</td> <td  className='repo'>{event.content}</td>  <td >{event.question_id}</td>
                              
                          
                           <td><IconButton onClick={() => handleEdit(event.id, event.content,event.question_id)}><Edit /> </IconButton>
                           <IconButton onClick={() => handleDeletex(event.id)}>  <Delete /> </IconButton>
                            </td> </tr>
                    )) : ''}
          </tbody>
</table>

              
            </div>


        </>
    );
};

export default Repon;
