import './MenuFiltre.css';
import React from 'react';
import { NavLink } from 'react-router-dom'
import { useState } from 'react';
function MenuFiltre() {
    const [name, setname] = useState('');
    const [country, setcountry] = useState('');
    const [date, setdate] = useState('');
    const basePath = '/filtre';

    let pathSegments = [];
    let queryParams = new URLSearchParams();

    if (name) {
        pathSegments.push(name);
        queryParams.append('name', name); // Remplacez 'value1' par ce que vous souhaitez
    }

    if (country) {
        pathSegments.push(country);
        queryParams.append('country', country); // Remplacez 'value2' par ce que vous souhaitez
    }

    if (date) {
        pathSegments.push(date);
        queryParams.append('date', date); // Remplacez 'value2' par ce que vous souhaitez
    }

    // Construire le chemin final
    const path = `${basePath}/${pathSegments.join('/')}`;
    // Ajouter les paramètres de requête s'il y en a
    const finalUrl = path + (queryParams.toString() ? '?' + queryParams.toString() : '');
    return (
        <>
            <div className="Menufiltre">
                <form>
                    <div className='recherche'>
                        <div className='que'>
                            <p className='gray'>Que cherchez-vous ?</p>
                            <input type='texte'
                                value={name}
                                onChange={(e) => setname(e.target.value)}
                                placeholder='Massage bodybody' />
                        </div>
                        <div className='que'>
                            <p className='gray'>OU ?</p>
                            <input type='texte' placeholder='Paris'
                                value={country}
                                onChange={(e) => setcountry(e.target.value)}
                            />
                        </div>
                        <div className='que'>
                            <p className='gray'> Quand ? </p>
                            <input type='texte' placeholder='juillet 2024'
                                value={date}
                                onChange={(e) => setdate(e.target.value)}

                            />
                        </div>
                        <NavLink
                            to={(finalUrl)}


                            className={({ isActive, isPending }) =>
                                isPending ? "pending" : isActive ? "active" : ""

                            }

                        >
                            <button><i className='ti-search'></i></button>    </NavLink>
                    </div>
                </form>
            </div>
        </>
    );
}
export default MenuFiltre; 