// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtre {
     padding: 1vw;
     display: flex;
     align-items: flex-start;
     justify-content: flex-start;
     gap: 1vw;
      padding-left: 26px;
     width:90%;
}
.filtre button{
    font-size: 1.2vw;
    padding: 1vw;
    border-radius: 8px;
   
    background-color: #fff;
    border:1px solid  rgba(128, 128, 128, 0.233) ;
    box-shadow: 1px 1px 2px  rgba(128, 128, 128, 0.233) ;;
}
.filtre i{
    font-size: 1vw;
}
.filtre .border{
    border: 2px solid black;
}`, "",{"version":3,"sources":["webpack://./src/component/page_filtre/Filtre.css"],"names":[],"mappings":"AAAA;KACK,YAAY;KACZ,aAAa;KACb,uBAAuB;KACvB,2BAA2B;KAC3B,QAAQ;MACP,kBAAkB;KACnB,SAAS;AACd;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;;IAElB,sBAAsB;IACtB,6CAA6C;IAC7C,oDAAoD;AACxD;AACA;IACI,cAAc;AAClB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".filtre {\n     padding: 1vw;\n     display: flex;\n     align-items: flex-start;\n     justify-content: flex-start;\n     gap: 1vw;\n      padding-left: 26px;\n     width:90%;\n}\n.filtre button{\n    font-size: 1.2vw;\n    padding: 1vw;\n    border-radius: 8px;\n   \n    background-color: #fff;\n    border:1px solid  rgba(128, 128, 128, 0.233) ;\n    box-shadow: 1px 1px 2px  rgba(128, 128, 128, 0.233) ;;\n}\n.filtre i{\n    font-size: 1vw;\n}\n.filtre .border{\n    border: 2px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
