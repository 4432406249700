import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Delete, Edit, CheckCircle, Token } from "@mui/icons-material";
// import { servicemasseur } from "../../../authentification/Authentificationl";
import axios from "axios";
// import { update } from "../../../authentification/Authentificationl";
// import { duration } from "moment";

const Service = () => {
    const [datas,setDatas] = useState([])
    const token = localStorage.getItem("token");

   const fetchget= async()=>{
        try {
      const response = await axios.get(`/appointments_client`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDatas(response.data.reservations);
    } catch  {
      console.log("Erreur de récupération des pays"); 
    }
    console.log('datass:',datas.price)
    }

    useEffect(()=>{
        fetchget()
    },[])

  return (
    <TableContainer
      className="main-content-wrap d-flex flex-column"
      component={Paper}
      sx={{
        width: "100%",
        position: "relative",
        top: "-70px",
      }}
    >
    <Typography style={{fontSize:'25px'}}>Services rendez-vous </Typography>
      <Table>
  <TableHead>
    <TableRow>
      <TableCell>Salon de massage</TableCell>
      <TableCell>Categories</TableCell>
      <TableCell>service</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Duration</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>Status</TableCell>
      
    </TableRow>
  </TableHead>
  <TableBody>
    <React.Fragment>
          <TableRow>
            <TableCell>1</TableCell>
            <TableCell>2</TableCell>
            <TableCell>3</TableCell>
            <TableCell>4</TableCell>
            <TableCell>5</TableCell>
            <TableCell>6</TableCell>
            <TableCell>7</TableCell>
          </TableRow>
    </React.Fragment>
</TableBody>

</Table>
    </TableContainer>
  );
};

export default Service;
