import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Box, Container } from "@mui/material";

const Success = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/dashboardPro"); // Redirige vers la page d'accueil
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Paiement réussi !
        </Typography>
        <Typography variant="body1" gutterBottom>
          Votre paiement a été traité avec succès. Merci pour votre achat.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleGoHome}
          sx={{
            backgroundColor: "#f9194f",
            '&:hover': { backgroundColor: "#d31644" },
            padding: "10px 20px",
            marginTop: "20px"
          }}
        >
          Retour à l'accueil
        </Button>
      </Box>
    </Container>
  );
};

export default Success;
