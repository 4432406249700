import { Typography } from "@mui/material";
import React from "react";

const CustomParagraph = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        ...sx,
        color: "#555555",
        fontSize: "1.1em"
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomParagraph;
