import { NavLink } from "react-router-dom";
import "./Menu.css";
import React, { useState, useEffect } from "react";
import { categories } from '../../authentification/Authentificationl';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import SpaIcon from '@mui/icons-material/Spa';
function Menu() {
  const navigate = useNavigate();
  const [catego, setcatego] = useState([]);
  const categorie = async () => {


    try {
      const userData = await categories();
      setcatego(userData);
      // console.log(userData.category)
      // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
    } catch (error) {
      console.log(error)

    }
  };
  useEffect(() => {
    categorie();
    // console.log(catego)
  }, [])


  const [Show, setShow] = useState(false);

  const [ShowM, setShowM] = useState(false);

  const [tokenn, Settoken] = useState(false);
  let val = localStorage.getItem('token')
  useEffect(() => {

    Settoken(val)
  }, [tokenn, val]);
  function DoShoow() {
    setShow(true);
  }
  function DoHidde() {
    setShow(false);
  }
  const berg = document.querySelector('.auth');
  const ferm = document.querySelector('.ferm');

  function showM() {

    if (berg) {
      berg.style.transform = 'translateX(3.1vw)';
    }
    if (ferm) {
      ferm.style.display = 'flex';
    }
  }
  function cate(id) {
    navigate(`/`)
    setTimeout(() => {
      navigate(`/details/${id}`)
    }, 0.1);


  }
  let Role = localStorage.getItem('Role');
  return (
    <>
      <div className="menu">
        <ul>

          <li>
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              <span className="disparu">     <HomeIcon /></span><span className="acca"> Accueil</span>
            </NavLink>

          </li>

          <li className="relative" onMouseLeave={DoHidde} onMouseEnter={DoShoow}>
            <div className="transition"></div>
            <span className="disparu"> <SettingsIcon  /></span>  <p className="acca">Nos Services <i className={Show ? "ti-angle-up" : "ti-angle-down"}></i></p>
            {Show ?
              <div className="services">

                {


                  catego.category ?
                    catego.category.map((cat, key) => {
                      return (

                        <p className='card' key={key}
                          onClick={() => { cate(cat.id) }}
                        >

                          {cat.name}

                        </p>



                      )
                    })
                    : ''

                }




              </div>
              : ''
            }

          </li >
          {tokenn !== null && tokenn !== 'undefined' && Role === 'client' ? <li id='eventt2'>
            <NavLink
              to="/dashboard"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              <span className="disparu">    <DashboardIcon /></span><span className="acca">Dashboard</span>

            </NavLink>

          </li>
            : ''}
          {tokenn !== null && tokenn !== 'undefined' && Role === 'masseur' ? <li id='eventt2'>
            <NavLink
              to="/dashbardPro"
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""
              }
            >
              <span className="disparu"> <DashboardIcon /></span><span className="acca">Dashboard</span>
            </NavLink>

          </li>
            : ''}
      
            <li id='eventt'>
              <NavLink
                to="/evenement"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
              >
                <span className="disparu"><EventIcon /></span><span className="acca"> Evenement</span>

              </NavLink>

            </li>

          
          <li  onClick={showM} ><span className="disparu"> <MenuIcon   /></span></li>
        </ul>
      </div>
    </>
  );
}
export default Menu;
