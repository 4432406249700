import React from "react";
import '../HeadTarif/Test.css'
const Test =()=>{
    return(
        <>
            <div className="Containe"> 
                {/* box a droit */}
                  <div className="box-1">
                        <div className="box-1-text-1">
                            <p>UN PRIX UNIQUE, UNE <br /> SOLUTION COMPLETE</p>
                        </div>
                        <div className="box-1-design-1">
                            <div className="box-1-design-1-1"></div>
                            <div className="box-1-design-1-2"></div>
                        </div>
                        <div className="box-1-text-2">
                            <p>Optez pour la simplicité et gagnez du temps pour ce qui compte vraiment : votre passion.</p>
                        </div>
                        <div className="box-1-button-1" style={{cursor:'pointer', paddingTop:'20px'}}>Voir nos offres</div>
                    </div>  
                    {/* box a gauche */}
                  <div className="box-2"></div>  
            </div>
        </>
    )
}
export default Test