import React from 'react'
import './Footer.css'
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { categories } from '../../authentification/Authentificationl';
import { useNavigate } from 'react-router-dom';
import LogoFooter from './LogoFooter'
export default function Footer() {
  const navigate = useNavigate();
  const [catego, setcatego] = useState([]);
  const categorie = async () => {


    try {
      const userData = await categories();
      setcatego(userData);
   //   console.log(userData.category)
      // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
    } catch (error) {
      console.log(error)

    }
  };
  useEffect(() => {
    categorie();
    // console.log(catego)
  }, [])


  const [Show, setShow] = useState(false);

  const [ShowM, setShowM] = useState(false);

  const [tokenn, Settoken] = useState(false);
  let val = localStorage.getItem('token')
  useEffect(() => {

    Settoken(val)
  }, [tokenn, val]);
  function DoShoow() {
    setShow(true);
  }
  function DoHidde() {
    setShow(false);
  }
  const berg = document.querySelector('.auth');
  const ferm = document.querySelector('.ferm');

  function showM() {

    if (berg) {
      berg.style.transform = 'translateX(3.1vw)';
    }
    if (ferm) {
      ferm.style.display = 'flex';
    }
  }
  function cate(id) {
    navigate(`/`)
    setTimeout(() => {
      navigate(`/details/${id}`)
    }, 0.1);


  }
  return (
    <footer>
      <div className="un">
        <div className="logof">

          <LogoFooter />
        </div>
        <ul>
          <p>Macerise est la premier plateforme de reservation massage sensuelle à coté de chez vous! </p>
          <p>42 ,rue de la Mare aux Carats 93100 MONTREUIL </p>
          <p>contact@macerise.com</p>
        </ul>

        <p className='socio'>
          <a href="/" className="link"><i className="ti-facebook"></i></a>
          <a href="/" className="link"><i className="ti-twitter-alt"></i></a>
          <a href="/" className="link"><i className="ti-google"></i></a>
          <a href="/" className="link"><i className="ti-pinterest-alt"></i></a>
          <a href="/" className="link"><i className="ti-instagram"></i></a>
          <a href="/" className="link"><i className="ti-rss"></i></a>

        </p>
      </div>
      <div className="deux">
        <p className="tete">A propos de Macerise</p>
        <ul>
          <li><a href="/">Qui somme nous?</a></li>
          <li >
                    <NavLink
                   
                      to="/tarif"
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                    >
                      Devenir Pro
                    </NavLink>
                  </li>
       
          <li><a href="/">CGV</a></li>
          <li><a href="/">CGU</a></li>
          <li><a href="/">Traitement de mes données</a></li>
          <li><a href="/">FQA</a></li>
        </ul>
      </div>
      <div className="trois">
        <p className="tete">Nos services massages</p>
        <ul>

        

            {
              catego.category ?
                catego.category.map((cat, key) => {
                  return (

                    <li key={key}
                      onClick={() => { cate(cat.id) }}
                    >

                      {cat.name}

                    </li>
                  )
                })
                : ''
            }



    



        </ul>
      </div>
      <div className="quatre">
        <p className="tete">Nos recherche frequents</p>
        <ul>
          <li><a href="/">Massage naturiste</a></li>
          <li><a href="/">Massage bodybody reciproque</a></li>
          <li><a href="/">Massage tantrique</a></li>
          <p>S'abonner à notrenewsletter</p>
        </ul>

        <form>

          <input type="text" placeholder="Adresse e-mail" />
          <button>Enregistrer</button>
        </form>

      </div>
    </footer>
  )
}
