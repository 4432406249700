import { Card, CardMedia, Stack, Typography, Button } from "@mui/material";
import Cardo from "./Cardo";
import "./Card.css";
import axios from "axios";
import NavMain from "../Navigation/NavMain";
import Footer from "../outil/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const Cardi = ({ categoId }) => {
  const { id } = useParams(); // Récupérer l'ID de l'URL

  const [category, setcategori] = useState([]);
  const [servicescategory, setservicescategory] = useState([]);
  const [error, setError] = useState("");
  const [name, setname] = useState("");
  const [tab, settab] = useState();
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `https://www.api-macerise.masovia-madagascar.com/api/category/${id}`
      );
      setcategori(response.data);

      setname(response.data.category.name)
    } catch (err) {
      setError(err);
    } finally {
    }
  };
  useEffect(() => {
    fetchServices();


    // setname(cate)
    window.scroll({ top: 0 });
  }, []);
  return (
    <div className="divm">
      <Stack spacing={15} orientation='vertical'
      >

        <NavMain />
        <Stack
          sx={{ display: "flex", flexDirection: "row", position: "relative", height: 'auto', minHeight: '400px' }}
        >
          <Stack >
            <Card
              className="cardbody"
              sx={{
                width: "45%",
                height: "500px",
                position: "absolute",
                filter: "blur(1px)",

                left: '20px'
                //   margin: "-10px",
              }}
            ></Card>
          </Stack>
          <Stack
            spacing={2}
            orientation="vertical"
            sx={{
              width: "45%",
              height: "100%",
              position: "relative",
              left: "50%",

            }}
          >
            <Stack sx={{ textAlign: "center" }} className="text-typo">
              <Typography variant="h5">
                {category.category ? category.category.name : ""}
              </Typography>
            </Stack>

            <Stack sx={{ position: "relative", top: "-30px" }}>
              <Stack sx={{ width: "100%" }} className="text-typo">
                <Typography variant="h6" sx={{ width: "100%", margin: "25px" }}>
                  {category.category ? category.category.description : ""}
                </Typography>
              </Stack>
              <NavLink


                
                to={`/filtr/${id}/${name}`}

              >

                <Button
                  elevation={2}
                  variant="contained"
                  sx={{
                    background: "#F9194F",
                    ":hover": { background: "red" },
                    width: "300px",
                    paddingTop: "10px",
                    position: "relative",
                    marginLeft: "25px",
                    marginBottom: "10px",
                  }}
                >
                  Voire plus
                </Button>

              </NavLink>

            </Stack>
            <Stack
              sx={{

                width: "75%",
                marginTop: "10px",
                position: "relative",
                top: "0px",
              }}
            >
              <Typography variant="h5" sx={{ marginLeft: "25px" }}>
                Listes des{" "}
                {category.category ? category.category.name : ""}
              </Typography>
              <Stack sx={{ marginTop: "10px", marginLeft: "25px" }}>
                {category.category_services
                  ? category.category_services.map((cate) => (
                    <Accordion key={cate.id}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{cate.name}</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Typography>{cate.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                  : " "}


              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Footer />
      </Stack>



    </div>
  );
};
export default Cardi;
