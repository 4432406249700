import React from 'react'
import './ServicesMassage.css'
import { categories } from '../../authentification/Authentificationl';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
export default function ServicesMassages({ fcategoId }) {
      const [catego, setcatego] = useState([]);
      const categorie = async () => {


            try {
                  const userData = await categories();
                  setcatego(userData);
                  // console.log(userData.category)
                  // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
            } catch (error) {
                  console.log(error)

            }
      };
      useEffect(() => {
            categorie();
            // console.log(catego)
      },[])
      return (
            <div className='servicesMassage'>
                  <h4>Nos Services Massages</h4>
                  <div className='servicesMassageImg'>

                        {

                              catego.category ?
                                    catego.category.map((cat, key) => {
                                          return (
                                                <NavLink to={`/details/${cat.id}`} className='card' key={key}
                                                >

                                                      <div className='transparent'></div>
                                                      <p>{cat.name}</p>
                                                      <img src='./image/massage1.png' alt='' ></img>
                                                </NavLink>



                                          )
                                    })
                                    : ''
                        }
                        {

                              !catego.category ? <>
                                    <div className='card'>

                                          <div className='imags'></div>
                                    </div>
                                    <div className='card'>

                                          <div className='imags'></div>
                                    </div>
                                    <div className='card'>

                                          <div className='imags'></div>
                                    </div>
                                    <div className='card'>


                                          <div className='imags'></div>
                                    </div>
                              </>
                                    : ''
                        }
                        {/*!catego.category ?
                              <>
                                    <div className='card'>
                                          <div className='transparent'></div>
                                          <p>Massages Relaxant</p>
                                          <img src='./image/massage1.png' alt='' ></img>
                                    </div>
                                    <div className='card'>
                                          <div className='transparent'></div>
                                          <p>Massages Tantrique</p>
                                          <img src='./image/massage2.png' alt='' ></img>
                                    </div>
                                    <div className='card'>
                                          <div className='transparent'></div>
                                          <p>Massages Naturiste</p>
                                          <img src='./image/massage3.png' alt='' ></img>
                                    </div>
                                    <div className='card'>
                                          <div className='transparent'></div>
                                          <p>Massages Bodybody / reciproque</p>
                                          <img src='./image/massage4.png' alt='' ></img>
                                    </div>: ''

                              </>
                 */ }
                  </div>
            </div>
      )
}
