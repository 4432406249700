import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Blog from './component/outil//Blog';
import Myprofil from './component/outil/Myprofil';
import TempleteFiltre from './component/page_filtre/TempleteFiltre';
import Contact from './component/contact/Contact';
import Tarif from './component/Compo/Tarif/Tarif';
import Login from './component/Compo/Login/Login';
import ShopDetail from './component/ShopDetail/ShopDetail';
import Accueil from './component/accueilA/Accueil';
import RouteProteger from './authentification/RouteProteger';
import Dashbord from './component/Dashbord/Dashbord';
import Cardi from './component/card/Cardi';
import Calle from './component/Calle';
import { useState } from 'react';
import TableRdv from './component/Dashbord/TableRdv/TableRdv';
import Calendrier from './component/Dashbord/Calendrier/Calendrier';
import Inscription4 from './component/Compo/inscription/Inscription4';
export default function App() {

  const [salonId, setload] = useState();
  function fsalonId(val) {
    setload(val)
  }

  const [categoId, setcatego] = useState();
  function fcategoId(val) {
    setcatego(val)
  }
  // simple commit
  // simple commit
  const isAuthenticated = localStorage.getItem('Role');
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path='/blog' element={<Blog />} ></Route>
            <Route path='/profil' element={<Myprofil />} ></Route>
            <Route path='/filtre/:name' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:name/:country' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:name/:date' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:date/:country' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:date' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:country' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre/:name/:date/:country' element={<TempleteFiltre />} ></Route>
            <Route path='/filtre' element={<TempleteFiltre />} ></Route>
            <Route path='/contact' element={<Contact />} ></Route>
            {/* <Route path='/' element={<Accueil fcategoId={fcategoId} fsalonId={fsalonId} />} ></Route> */}
            <Route path='/tarif' element={<Tarif />} ></Route>
            {/* <Route path='/login' element={<Login />} ></Route> */}
            <Route path='/detail' element={<ShopDetail salonId={salonId} />} ></Route>
            <Route path='/dashbardPro' element={<Dashbord />} ></Route>
            <Route path='/' element={<Accueil val={true} />} ></Route>
            <Route path='/det' element={<Cardi categoId={categoId} />} ></Route>
            <Route path='/loginn' element={isAuthenticated == 'admin' || isAuthenticated == 'masseur' ? /*<Navigate to="/dashboardPro" />*/<Tarif /> : <Accueil  fcategoId={fcategoId} fsalonId={fsalonId} />} ></Route>
            <Route path='/tarif' element={<Tarif />} ></Route>
            <Route path='/login' element={<Login />} ></Route>
            <Route path='/calendr' element={<Calle />} ></Route>
            <Route path='/dashboardPro' element={<Dashbord />} ></Route>
            <Route path='/tableRdv' element={<TableRdv/>} ></Route> 
            <Route path='/cali' element={<Calendrier/>} ></Route> 
            <Route path='/inscription4' element={<Inscription4/>} ></Route> 
            {/* <Route path='/det' element={<Cardi/>} ></Route>  */}
            <Route path="/details/:id" element={<Cardi />} /> {/*detait categorie*/}
            <Route path="/detailsalon/:id" element={<ShopDetail />} />  {/*detait salon*/}
            {/* Route protégée */}
            <Route path="/dashboard" element={
              <RouteProteger />
            } />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}
