import React, { useEffec, useEffect, useState } from "react";
import "./Dashb.css";
import {
  ListCountClient,
  ListCountClientYear,
  ListCountMasseur,
  ListCountSallon,
} from "./api/count";
import TickPlacementBars from "./component/barChart";
import SimpleCharts from "./component/barChart";
import DemoChart from "./component/barChart";
import { PieChart } from "@mui/icons-material";
import PieDemoChart from "./component/PieChart";

const ProfilDash = () => {
  const [clientCount, setClient] = useState(0);
  const [masseurCount, setMasseur] = useState(0);
  const [salonCount, setSallon] = useState(0);
  // const [clientCount, setClient] = useState(0);
  useEffect(() => {
    ListCountClient().then((res) => {
      console.log("List Cli", res.data["clients"]);
      setClient(res.data["clients"]);
    });
    ListCountMasseur().then((res) => {
      console.log("Sallons", res.data);
      setMasseur(res.data["masseurs"]);
    });
    ListCountSallon().then((res) => {
      console.log("Sallons", res.data);
      setSallon(res.data["salons"]);
    });
    ListCountClientYear().then((res) => {
      console.log("Yar", res.data);
    });
  }, []);

  return (
    <>
      <div
       
       style={{ 
         width: { xs: '0%',sm:'75%', md: '100%' }, // Table responsive
         overflowX: 'auto',
       marginLeft:{xs:'0px',sm:'150px',md:'0px'}
       ,marginTop:{xs:'0px',sm:'30px',md:'0px'} // Taille de texte responsive dans la table
       }}
      >
        
        {/* ............................................................................... */}
        <div className="main-content">
          <div className="breadcrumb">
            <h1 className="mr-2">Version 1</h1>
            <ul>
              <li>
                <a href="">Dashboard</a>
              </li>
              <li>Version 1</li>
            </ul>
          </div>
          <div className="separator-breadcrumb border-top"></div>
          <div className="row">
            {/* ICON BG */}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  <i className="i-Add-User" style={{ color: "#F9194F" }}></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0">Cleints</p>
                    <p
                      className="text-24 line-height-1 mb-2"
                      style={{ color: "gray" }}
                    >
                      {clientCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  {/* <i className="i-Money-2" style={{ color: "#F9194F" }}></i> */}
                  <i className="i-Add-User" style={{ color: "#F9194F" }}></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0">Masseurs</p>
                    {/* .............................................................................................................. */}
                    <p
                      className=" text-24 line-height-1 mb-2"
                      style={{ color: "gray" }}
                    >
                      {masseurCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  <i
                    className="i-Checkout-Basket"
                    style={{ color: "#F9194F" }}
                  ></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0">Salons</p>
                    <p
                      className=" text-24 line-height-1 mb-2"
                      style={{ color: "gray" }}
                    >
                      {salonCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  <i className="i-Financial" style={{ color: "#F9194F" }}></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0">Sales</p>
                    <p
                      className=" text-24 line-height-1 mb-2"
                      style={{ color: "gray" }}
                    >
                      $4021
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="card mb-4">
                <div className="card-body">
                  {/* <div className="card-title">This Year Sales</div> */}
                  {/* <div id="echartBar" style={{ height: "300px" }}> */}
                  <DemoChart />
                  {/* .................................................................... */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-title">Sales by Countries</div>
                  <div id="echartPie" style={{ height: "350px" }}>
                  <PieDemoChart />
                    {/* ................................................................. */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="card card-chart-bottom o-hidden mb-4">
                    <div className="card-body">
                      <div className="text-muted">Last Month Sales</div>
                      <p className="mb-4 text-24" style={{ color: "#F9194F" }}>
                        $40250
                      </p>
                    </div>
                    <div id="echart1" style={{ height: "260px" }}></div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="card card-chart-bottom o-hidden mb-4">
                    <div className="card-body">
                      <div className="text-muted">Last Week Sales</div>
                      <p className="mb-4 text-warning text-24">$10250</p>
                    </div>
                    <div id="echart2" style={{ height: "260px" }}></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card o-hidden mb-4">
                    <div className="card-header d-flex align-items-center border-0">
                      <h3 className="w-50 float-left card-title m-0">
                        New Users
                      </h3>
                      <div className="dropdown dropleft text-right w-50 float-right">
                        <button
                          className="btn bg-gray-100"
                          id="dropdownMenuButton1"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="nav-icon i-Gear-2"></i>
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <a className="dropdown-item" href="#">
                            Add new user
                          </a>
                          <a className="dropdown-item" href="#">
                            View All users
                          </a>
                          <a className="dropdown-item" href="#">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="table-responsive">
                        <table className="table text-center" id="user_table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Name</th>
                              <th scope="col">Avatar</th>
                              <th scope="col">Email</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Smith Doe</td>
                              <td>
                                <img
                                  className="rounded-circle m-0 avatar-sm-table"
                                  src="dist-assets/images/faces/1.jpg"
                                  alt=""
                                />
                              </td>
                              <td>Smith@gmail.com</td>
                              <td>
                                <span className="badge badge-success">
                                  Active
                                </span>
                              </td>
                              <td>
                                <a className="text-success mr-2" href="#">
                                  <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                </a>
                                <a className="text-danger mr-2" href="#">
                                  <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>Jhon Doe</td>
                              <td>
                                <img
                                  className="rounded-circle m-0 avatar-sm-table"
                                  src="dist-assets/images/faces/1.jpg"
                                  alt=""
                                />
                              </td>
                              <td>Jhon@gmail.com</td>
                              <td>
                                <span className="badge badge-info">
                                  Pending
                                </span>
                              </td>
                              <td>
                                <a className="text-success mr-2" href="#">
                                  <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                </a>
                                <a className="text-danger mr-2" href="#">
                                  <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>Alex</td>
                              <td>
                                <img
                                  className="rounded-circle m-0 avatar-sm-table"
                                  src="dist-assets/images/faces/1.jpg"
                                  alt=""
                                />
                              </td>
                              <td>Otto@gmail.com</td>
                              <td>
                                <span className="badge badge-warning">
                                  Not Active
                                </span>
                              </td>
                              <td>
                                <a className="text-success mr-2" href="#">
                                  <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                </a>
                                <a className="text-danger mr-2" href="#">
                                  <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">4</th>
                              <td>Mathew Doe</td>
                              <td>
                                <img
                                  className="rounded-circle m-0 avatar-sm-table"
                                  src="dist-assets/images/faces/1.jpg"
                                  alt=""
                                />
                              </td>
                              <td>Mathew@gmail.com</td>
                              <td>
                                <span className="badge badge-success">
                                  Active
                                </span>
                              </td>
                              <td>
                                <a className="text-success mr-2" href="#">
                                  <i className="nav-icon i-Pen-2 font-weight-bold"></i>
                                </a>
                                <a className="text-danger mr-2" href="#">
                                  <i className="nav-icon i-Close-Window font-weight-bold"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-title">Top Selling Products</div>
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                    <img
                      className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                      src="dist-assets/images/products/headphone-4.jpg"
                      alt=""
                    />
                    <div className="flex-grow-1">
                      <h5>
                        <a href="" style={{ color: "#F9194F" }}>
                          Wireless Headphone E23
                        </a>
                      </h5>
                      <p className="m-0 text-small text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                      </p>
                      <p className="text-small text-danger m-0">
                        $450 <del className="text-muted">$500</del>
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded"
                        style={{
                          color: "white",
                          background: "#F9194F",
                          width: "100px",
                          height: "25px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                    <img
                      className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                      src="dist-assets/images/products/headphone-2.jpg"
                      alt=""
                    />
                    <div className="flex-grow-1">
                      <h5>
                        <a href="" style={{ color: "#F9194F" }}>
                          Wireless Headphone Y902
                        </a>
                      </h5>
                      <p className="m-0 text-small text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                      </p>
                      <p className="text-small text-danger m-0">
                        $550 <del className="text-muted">$600</del>
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded"
                        style={{
                          color: "white",
                          background: "#F9194F",
                          width: "100px",
                          height: "25px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                    <img
                      className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                      src="dist-assets/images/products/headphone-3.jpg"
                      alt=""
                    />
                    <div className="flex-grow-1">
                      <h5>
                        <a href="" style={{ color: "#F9194F" }}>
                          Wireless Headphone E09
                        </a>
                      </h5>
                      <p className="m-0 text-small text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                      </p>
                      <p className="text-small text-danger m-0">
                        $250 <del className="text-muted">$300</del>
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded"
                        style={{
                          color: "white",
                          background: "#F9194F",
                          width: "100px",
                          height: "25px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-sm-center mb-3">
                    <img
                      className="avatar-lg mb-3 mb-sm-0 rounded mr-sm-3"
                      src="dist-assets/images/products/headphone-4.jpg"
                      alt=""
                    />
                    <div className="flex-grow-1">
                      <h5>
                        <a href="" style={{ color: "#F9194F" }}>
                          Wireless Headphone X89
                        </a>
                      </h5>
                      <p className="m-0 text-small text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                      </p>
                      <p className="text-small text-danger m-0">
                        $450 <del className="text-muted">$500</del>
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-outline mt-3 mb-3 m-sm-0 btn-sm btn-rounded"
                        style={{
                          color: "white",
                          background: "#F9194F",
                          width: "100px",
                          height: "25px",
                          fontSize: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        View details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-body p-0">
                  <div className="card-title border-bottom d-flex align-items-center m-0 p-3">
                    <span>User activity</span>
                    <span className="flex-grow-1"></span>
                    <span className="badge badge-pill badge-warning">
                      Updated daily
                    </span>
                  </div>
                  <div className="d-flex border-bottom justify-content-between p-3">
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">
                        Pages / Visit
                      </span>
                      <h5 className="m-0">2065</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">New user</span>
                      <h5 className="m-0">465</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">Last week</span>
                      <h5 className="m-0">23456</h5>
                    </div>
                  </div>
                  <div className="d-flex border-bottom justify-content-between p-3">
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">
                        Pages / Visit
                      </span>
                      <h5 className="m-0">1829</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">New user</span>
                      <h5 className="m-0">735</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">Last week</span>
                      <h5 className="m-0">92565</h5>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-3">
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">
                        Pages / Visit
                      </span>
                      <h5 className="m-0">3165</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">New user</span>
                      <h5 className="m-0">165</h5>
                    </div>
                    <div className="flex-grow-1">
                      <span className="text-small text-muted">Last week</span>
                      <h5 className="m-0">32165</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-body p-0">
                  <h5 className="card-title m-0 p-3">Last 20 Day Leads</h5>
                  <div id="echart3" style={{ height: "360px" }}></div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Footer Start */}
          {/* <div className="flex-grow-1"></div>
          <div className="app-footer"></div> */}
          {/* Footer end */}
        </div>
        <div />
        {/* ....................................................................................... */}
        
      </div>
    </>
  );
};
export default ProfilDash;
