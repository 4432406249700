import {React,useEffect,useState } from "react";
import "../suitTarif/Offres.css";
const Offres = () => {
  const [animate,setAnimate] = useState(1);
  const handleButtonClick= (buttonId) => {
    setAnimate(buttonId);
    // setAnimate(!isButtonActive)
  };
  
  
  
  return (
    <>
      <div className="cont-offre">
        <div className="text-offre">NOS OFFRES</div>
        <div className="case-offre">
          {/*  */}
          <div onClick={()=>handleButtonClick(1)} style={{width:'119px',
              height:'51px',
              borderRadius: '999999999px',
              // alignItems:'center',
              background: animate===1 ? '#F9194F':'white',
              display:'flex',
              justifycontent:'center',
              cursor:'pointer',
              
              } } >
            <div className="case-offre-1-1"  >
              <p className="text-offre-1-1"  style={{color:animate===1 ? 'white':'black'}}>Annuel</p>
            </div>
          </div>
          {/*  */}
          <div 
            onClick={() => handleButtonClick(2)} 
            style={{
              width: '119px',
              height: '51px',
              borderRadius: '999999999px',
              background: animate === 2 ? '#F9194F' : 'white',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer'
            }}>
            <div className="case-offre-1-2">
              <p className="text-offre-1-2" style={{color:animate===2 ? 'white':'black'}}>Mensuel</p>
            </div>
          </div>
        </div>
        <div className="case-offre-3">
          <div className="case-offre-3-1">
            <div className="case-offre-3-1-1">
              <div className="case-offre-3-1-1-T1">
                14 jours d'essai gratuit !
              </div>
            </div>
            <div className="case-offre-3-1-2">
              <div className="case-offre-3-1-2-T2">€</div>
              <div className="case-offre-3-1-2-T3 ">0</div>
            </div>
            <div className="case-offre-3-1-3">
              Pendant 14 jours, bénéficiez gratuitement de toutes les
              fonctionnalités de nos offres et découvrez comment elles peuvent
              transformer la gestion de votre salon. N'attendez plus, essayez
              dès maintenant !
            </div>
            <div className="case-offre-3-1-4" style={{cursor:'pointer'}}>
              <div className="case-offre-3-1-4-T1" >Découvrir l'offre</div>
            </div>
            <div className="case-offre-3-1-5">
              <div className="case-offre-3-1-5-t1">
                <div className="koche1"></div>
                <div className="text-text">Agenda et rendez vous</div>
              </div>
              <div className="case-offre-3-1-5-t2">
                <div className="koche2"></div>
                <div className="text-text-m">SMS / Email de rappel</div>
              </div>
              <div className="case-offre-3-1-5-t3">
                <div className="koche3"></div>
                <div className="text-text-m">Gestion de votre établissement</div>
              </div>
              <div className="case-offre-3-1-5-t4">
                <div className="koche4"></div>
                <div className="text-text-m">Outils Marketing</div>
              </div>
              <div className="case-offre-3-1-5-t5">
                <div className="koche5"></div>
                <div className="text-text-m">Logiciel de caisse</div>
              </div>
              <div className="case-offre-3-1-5-t6">
                <div className="koche6"></div>
                <div className="text-text-m">
                  Indicateurs de performances avancés pour votre salon
                </div>
              </div>
            </div>
          </div>
          <div className="case-offre-3-2">
            <div className="case-offre-3-1-1">
              <div className="case-offre-3-1-1-T1">
                Agenda
              </div>
            </div>
            <div className="case-offre-3-1-2">
              <div className="case-offre-3-1-2-T2">€</div>
              <div className="case-offre-3-1-2-T3 ">100</div>
            </div>
            <div className="case-offre-3-1-3">
            Choisissez notre offre Agenda pour référencer votre salon en ligne et proposer la prise de rendez-vous en toute autonomie.
            </div>
            <div className="case-offre-3-1-4" style={{cursor:'pointer'}}>
              <div className="case-offre-3-1-4-T1">Découvrir l'offre</div>
            </div>
            <div className="case-offre-3-1-5">
              <div className="case-offre-3-1-5-t1">
                <div className="koche1"></div>
                <div className="text-text-m">Agenda et rendez vous</div>
              </div>
              <div className="case-offre-3-1-5-t2">
                <div className="koche2"></div>
                <div className="text-text-m">SMS / Email de rappel</div>
              </div>
              <div className="case-offre-3-1-5-t3">
                <div className="koche3"></div>
                <div className="text-text-m">Gestion de votre établissement</div>
              </div>
              <div className="case-offre-3-1-5-t4">
                <div className="koche4-x"></div>
                <div className="text-text-x">Outils Marketing</div>
              </div>
              <div className="case-offre-3-1-5-t5">
                <div className="koche5-x"></div>
                <div className="text-text-x">Logiciel de caisse</div>
              </div>
              <div className="case-offre-3-1-5-t6">
                <div className="koche6-x"></div>
                <div className="text-text-x">
                  Indicateurs de performances avancés pour votre salon
                </div>
              </div>
            </div>
          </div>
          <div className="case-offre-3-3">
            <div className="case-offre-3-1-1">
              <div className="case-offre-3-1-1-T1">
              Agenda + caisse
              </div>
            </div>
            <div className="case-offre-3-1-2">
              <div className="case-offre-3-1-2-T2">€</div>
              <div className="case-offre-3-1-2-T3 ">150</div>
            </div>
            <div className="case-offre-3-1-3">
            Optez pour notre offre Agenda + Caisse pour simplifier la prise de rendez-vous et le paiement de vos clients.
            </div>
            <div className="case-offre-3-1-4" style={{cursor:'pointer'}}>
              <div className="case-offre-3-1-4-T1" >Découvrir l'offre</div>
            </div>
            <div className="case-offre-3-1-5">
              <div className="case-offre-3-1-5-t1">
                <div className="koche1"></div>
                <div className="text-text-m">Agenda et rendez vous</div>
              </div>
              <div className="case-offre-3-1-5-t2">
                <div className="koche2"></div>
                <div className="text-text-m">SMS / Email de rappel</div>
              </div>
              <div className="case-offre-3-1-5-t3">
                <div className="koche3"></div>
                <div className="text-text-m">Gestion de votre établissement</div>
              </div>
              <div className="case-offre-3-1-5-t4">
                <div className="koche4"></div>
                <div className="text-text-m">Outils Marketing</div>
              </div>
              <div className="case-offre-3-1-5-t5">
                <div className="koche5"></div>
                <div className="text-text-m">Logiciel de caisse</div>
              </div>
              <div className="case-offre-3-1-5-t6">
                <div className="koche6"></div>
                <div className="text-text-m">
                  Indicateurs de performances avancés pour votre salon
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Offres;
 