import React from "react";
import About from "./About/About";
import Reviews from "./About/Reviews/Reviews";
import Gallery from "./Gallery/Gallery";
import { Container } from "@mui/material";
import NavMain from "../Navigation/NavMain";
import Footer from "../outil/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import { Scale } from "@mui/icons-material";


const ShopDetail = ({ salonId }) => {

  

  const { id } = useParams(); // Récupérer l'ID de l'URL
  const userid = localStorage.getItem('userId')
  const useridc=localStorage.getItem('userId')
  const [liste, setliste] = useState('')
  const [salonp, setsalonp] = useState([]);
  const [salonpp, setsalonpp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [salonidd, setsalois] = useState(salonId)
  const [ok, setok] = useState('')
  const [userids, setuseridm] = useState('')
  const [fav, setfav] = useState([])
  const token = localStorage.getItem('token')
  const [tel, settel] = useState('')
  useEffect(() => {
    const fetchquestion = async () => {
      try {
        const response = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/favoris_client", {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setfav(response.data.favorites_client);

        // Variable temporaire pour vérifier si le salon est dans les favoris
        const isFavorited = response.data.favorites_client.some(m =>
          m.pivot.user_id === userid && m.pivot.salon_id === id
        );

        setok(!isFavorited); // mettre à jour "ok" selon que l'élément est favori ou non

      } catch (err) {
        console.log(err);
      }
    };

    fetchquestion();
  }, []); // Notez que fetchquestion() n'a pas besoin de dépendances ici

  useEffect(() => {
    // console.log('iddd5555:',salonidd)
    setok(true); // Initialisez ok à true ici ou dans un autre useEffect si nécessaire
    // Assurez-vous que fetchServices soit défini ici ou ailleurs.
  }, [id]); // id en dépendance parce que cela fait nécessairement un nouvel appel à fetchServices chaque fois que l'id change

  const [name, setname] = useState('')
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          `https://www.api-macerise.masovia-madagascar.com/api/salon/${id}`
        );
        setsalonp(response.data);
        settel(response.data.user[0].tel)
        setuseridm(response.data.user[0].id)
        setname(response.data.salon.name)
       

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    setsalois(salonId);
    window.scroll({ top: 0 });
    fetchServices();

    //console.log('gtgt',salonp.user[0].id)
    //setuseridm(salonp.user[0].id)
  }, [id]);

  function sett(val) {
    setok(val);
  }

  const [aff, setaff] = useState(false)
  function affiche(val) {
    setaff(val)
  }
  const CustomButton = styled(Button)(({ theme }) => ({
    '&:hover': {
      backgroundColor: 'white', // Changez cela si vous voulez un effet différent
      boxShadow: 'none', // Supprimez l'ombre

    },
  }));
  /* envouyer message*/
  const [content, setmessage] = useState('')
  
  const Envoyermessage = async (e) => {
    
    e.preventDefault();
    
    let token = localStorage.getItem('token')
    try {
      const response = await axios.post("https://www.api-macerise.masovia-madagascar.com/api/messages/send/" +userids,
        {
          content
        }
        ,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      // setfav(response.data.favorites_client);
    //  console.log(response)
      await lister();  // Récupérer la liste des messages après l'envoi
      setmessage('');  // Réinitialiser le champ de message
    } catch (err) {
      console.log(err)
    }


  };

  /* liste message*/

 
  const lister = async () => {

    let token = localStorage.getItem('token')
    try {
      const response = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/conversations/" +userids

        ,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setliste(response.data);
     // console.log(response)
    } catch (err) {
      console.log(err)
    }
 
  };

 useEffect(()=>{
if(userids){
  lister(); // Assurez-vous que cela met à jour la liste des messages
  const scrolll = document.querySelector('.contentmessbb');
  if (scrolll) {
      scrolll.scrollTop = scrolll.scrollHeight; // Faire défiler vers le bas
  }
 // console.log(userids)
}
 
 },[userids])
useEffect(() => {
 
  const scrolll = document.querySelector('.contentmessbb');
  if (scrolll) {
      scrolll.scrollTop = scrolll.scrollHeight; // Faire défiler vers le bas
  }
}, [liste]);  // Ajoutez `liste` comme dépendance pour le défilement

function convertir(dateString) {
  // Convertir la chaîne en objet Date
  const date = new Date(dateString);

  // Extraire l'heure et les minutes en temps local
  const hours = date.getHours(); // Utiliser getHours pour le temps local
  const minutes = date.getMinutes(); // Utiliser getMinutes pour le temps local

  // Ajouter un zéro devant les minutes si elles sont inférieures à 10
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Retourner l'heure et les minutes au format hh:mm
  return hours + ':' + formattedMinutes;
}
useEffect(() => {
  const goom = document.querySelector('.contentmessbb');
  if (goom) {
    goom.scrollTop = goom.scrollHeight; // Faire défiler vers le bas
  }
  const messp = document.querySelectorAll('.cardmm');
  messp.forEach(m => {
    if (m.getAttribute('id')!==useridc) { 
  
      m.style.justifyContent = 'flex-end';
      m.children[0].style.backgroundColor = '#fb174e';
    }else{
      m.style.justifyContent = 'flex-start';
      m.children[0].style.backgroundColor = 'gray';
    }
  });
});  // Ajoutez `liste` comme dépendance pour le défilement
const [difference, setDifference] = useState('');


//hello 

  useEffect(() => {
    // Heure fixe - 10:30
    if(token){
      if(liste.length>0){
     //   console.log(convertir(liste[liste.length-1].updated_at))
     
      const fixedTime = new Date(liste[liste.length-1].updated_at);
      // Heure locale actuelle
      const localTime = new Date(); // maintenant
  
      // Calculer la différence en millisecondes
      const diffInMillis = localTime - fixedTime; // localTime - fixedTime
  
      // Convertir en heures, minutes, secondes
      const absDiffInMillis = Math.abs(diffInMillis);
      
      const hours = Math.floor(absDiffInMillis / (1000 * 60 * 60));
      const minutes = Math.floor((absDiffInMillis % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((absDiffInMillis % (1000 * 60)) / 1000);
  
      // Mettre la différence dans un format lisible
      setDifference(` ${hours}h:${minutes}m `);
    }
    }
  
  },[liste]);



  return (
    <>
      <NavMain />
      <Container
        maxWidth="xl"
        sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 2 ,overflow:'auto'}}
      >
        <Gallery service={salonp} ok={ok} sett={sett} />
        <About service={salonp} tel={tel} affiche={affiche} />
      </Container  >
      <Footer />
      {aff ?
        <div className="cardmessagev">


          <form className='inpmess' onSubmit={Envoyermessage}>
            <input type='texte'
              value={content}
              required
              onChange={(e) => setmessage(e.target.value)}
              placeholder="message"
            ></input>
            <CustomButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              sx={{
                color: '#fb174e',
                backgroundColor: 'white',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'white', // Changez cela si vous voulez un effet différent
                  // Supprimez l'ombre
                },
              }}
            >


            </CustomButton>
          
          </form>

          <div className="entetem">
            <div className="tetemx">
              <div><img src='../image/logo3.png' alt='im' ></img></div>
              <div className="whitek" >
                <p>{name}</p>
                <p>il y a 
                  {difference}
                </p>
              </div>
            </div>
            <p className='fermerxx' onClick={() => affiche(false)}> x</p>
          </div>
          <div className="entred">
            <div className='contentmessbb'>
              {

                liste ?
                  liste.map((list, key) => {
                    return <div className='cardemessb' key={key}>
                      <p className="tempsm">{convertir(list.updated_at)}</p>
                      <div className="cardmm"  id={list.receiver_id} >
                   
                        <p className="messlist">{list.content} </p>
                      </div>

                    </div>

                  })
                  : ''
              }
           


            </div>
          </div>
        </div> : ''
      }

    </>
  );
};
export default ShopDetail;
