import React, { useState } from 'react';
import './Calle.css';

const Calle = () => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
   



  
  // Fonction pour générer le calendrier
  const generateCalendar = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const monthDates = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      monthDates.push({
        fullDate: date,
        day: day,
        month: month + 1, // Les mois commencent à 0 dans JS
        year: year,
        week: getWeekNumber(date),
      });
    }

    setDates(monthDates);
  };

  const getWeekNumber = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startOfYear.getDay() + 1) / 7);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date.fullDate.toLocaleDateString());
  };

  // Générer le calendrier pour le mois actuel
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  React.useEffect(() => {
    generateCalendar(currentYear, currentMonth);
  }, [currentYear, currentMonth]);

  return (
    <div className="App">
      <h1>Calendrier</h1>
      {selectedDate && <h2>Date sélectionnée : {selectedDate}</h2>}
      <table>
        <thead>
          <tr>
            <th>Jour</th>
            <th>Mois</th>
            <th>Année</th>
            <th>Semaine</th>
          </tr>
        </thead>
        <tbody>
          {dates.map((date, index) => (
            <tr key={index} onClick={() => handleDateClick(date)} style={{ cursor: 'pointer', backgroundColor: '#f0f0f0', height: '40px' }}>
              <td>{date.day}</td>
              <td>{date.month}</td>
              <td>{date.year}</td>
              <td>{date.week}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Calle;
