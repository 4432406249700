import { React, useState, useEffect } from "react";
import "./Myprofil.css";
import axios from "axios";
import { formatDate } from "date-fns";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  Input,
  IconButton,
} from "@mui/material";
// Configurer Axios pour pointer vers votre API
axios.defaults.baseURL = "https://www.api-macerise.masovia-madagascar.com/api";

export default function Myprofil() {
  // Array for days, months, and years
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const [countries, setCountries] = useState([]);

  const fetchcountry = async () => {
    try {
      const datas = await axios.get(`/countries`);
      setCountries(datas.data);
    } catch {
      console.log("Erreur lors du chargement des pays");
    }
  };

  const [userData, setUserData] = useState({
    name: "",
    address: "",
    email: "",
    tel: "",
    city: "",
    country_id: "",
    jour: "",
    mois: "",
    annee: "",
    img:''
  });

  useEffect(() => {
    fetchcountry();
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.get(`/edit/user/${userId}`);
      const data = response.data;
      const [year, month, day] = data.user.date_de_naissance.split("-");
      console.log(response.data)
      setUserData({
        name: data.user.name || "",
        address: data.user.address || "",
        email: data.user.email || "",
        city: data.user.city || "",
        tel: data.user.tel || "",
        country_id: data.user.country_id || "",
        jour: parseInt(day, 10),
        mois: months[parseInt(month, 10) - 1],
        annee: parseInt(year, 10),
          img:data.user.image
      });
    } catch (error) {
      console.error(
        "Erreur lors du chargement des données utilisateur:",
        error
      );
    }
  };

  const token = localStorage.getItem("token");
  const takeSubmit = async (e) => {
    e.preventDefault();

    const { name, email, tel, address, annee, mois, jour, city, country_id } =
      userData;
    // const date_de_naissance = `${annee}-${months.indexOf(mois) + 1}-${jour}`;
    const date_de_naissance = `${jour}-${months.indexOf(mois) + 1}-${annee}`;

    // const date_de_naissances = formatDate(date_de_naissance)
    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.put(
        `/update/user`,
        {
          name,
          email,
          tel,
          address,
          date_de_naissance,
          city,
          country_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Réponse:", response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const handleFileChange = async (event) => {
    
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setImagePreview(fileURL); // Set the preview URL for the image

      // Prepare form data for image upload
      const formData = new FormData();
      formData.append("image", file);

      let token = localStorage.getItem("token");

      try {
        const response = await axios.post(`/update_ProfileImage`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        fetchUserData()
        console.log("Upload success:", response.data);

        // After successful upload, fetch updated user data including the new image
      
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <form className="right" onSubmit={takeSubmit}>
      <p className="p">Mon profil</p>

      <div className="entetex">  <p className="pppp font600">Photo</p>
        <div className="ajoutimg"><Input
          id="file-input"
          type="file"
          accept="image/*" // Accept only image files
          sx={{
            opacity: '0',
            position: "absolute",
            width: "100%",
            height: "100%",
            cursor: "pointer",
            left: '0'
          }}
          onChange={handleFileChange}
        />Modifier</div>
      </div>
      <div className="entete"><img  src={`https://api-macerise.masovia-madagascar.com/storage/images/${userData.img}`} /></div>
      <hr style={{margin:'15px'}}></hr>
      <div className="nomPrenom">
        <div className="nom">
          <p className="pppp font600">Nom</p>
          <input
            type="text"
            name="name"
            placeholder="Entrer votre nom"
            value={userData.name}
            onChange={handleChange}
          />
        </div>
        <div className="prenom">
          <p className="pppp font600">Adresse email</p>
          <input
            type="text"
            name="email"
            placeholder="Entrer votre email"
            value={userData.email}
            onChange={handleChange}
          />
        </div>
        <div className="prenom">
          <p className="pppp font600">Adresse</p>
          <input
            type="text"
            name="address"
            placeholder="Entrer votre adresse"
            value={userData.address}
            onChange={handleChange}
          />
        </div>
        <div className="prenom">
          <p className="pppp font600">Ville</p>
          <input
            type="text"
            name="city"
            placeholder="Entrer votre ville"
            value={userData.city}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="numtel">
        <p className="pppp font600">Numéro de téléphone</p>
        <input
          type="text"
          name="tel"
          placeholder="Entrer votre numéro"
          value={userData.tel}
          onChange={handleChange}
        />
      </div>
      <div className="votrepays">
        <p className="pppp font600">Votre pays</p>
        <select
          name="country_id"
          value={userData.country_id}
          onChange={handleChange}
          className="pays"
        >
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="dateans">
        <p className="font600">Date de naissance</p>
        <div className="sel">
          <select name="jour" value={userData.jour} onChange={handleChange}>
            {days.map((d) => (
              <option key={d} value={d}>
                {d}
              </option>
            ))}
          </select>
          <select name="mois" value={userData.mois} onChange={handleChange}>
            {months.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
          <select name="annee" value={userData.annee} onChange={handleChange}>
            {years.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button className="btn2" type="submit">
        Enregistrer
      </button>
    </form>
  );
}
