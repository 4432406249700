import {React,useState} from "react";
import './Login2.css'
import {NavLink, useNavigate} from 'react-router-dom';
import Inscription3 from "../inscription/Inscription3";
import Inscription2 from '../inscription/Inscription2'
import { loging} from "../../../authentification/Authentificationl";

const Login2=()=>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [load, setload] = useState(false);
    const navigate = useNavigate();
    const takeSubmit = async (e) => {
                e.preventDefault();
                setError('');
                setload(true)
                try {
                    const userData = await loging(email, password);
                    console.log('Connexion réussie', userData);  
                    const token = localStorage.getItem('token');
                    const userId = localStorage.getItem('userId');
                    const role = localStorage.getItem('Role');    
                    // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici
                    // setload(false)
                    if (userData && token) {
                      setload(false);
                      // Redirection basée sur le rôle
                      console.log('role',role)
                      if (role === 'admin'|| role === 'masseur') { // Remplacez 'specificRole' par le rôle qui doit être redirigé vers la page tarif
                          setTimeout(() => {
                              navigate('/dashboardPro');
                          }, 1000);
                      } else if (role === 'client') { // Vérifie si userId existe pour rediriger vers le dashboard
                          setTimeout(() => {
                              navigate('/dashboard');
                          }, 1000);
                      }else{
                        navigate('/');
                      }
                  } else {
                      setError(userData.message);
                      console.log(userData.token);
                  }
                  console.log(userData)
                } catch (error) { 
                    setload(false)
                    setError('Échec de la connexion. Veuillez vérifier vos informations d\'identification.');
                }
       };
      
//affichage inscription
    const [valinscri,setinscrire]=useState(1);
    function hide(val){
    setinscrire(val)
 
    } 
    
    return(
        <>
    
        {valinscri===1? 
        
            <div className="login-container">
                <div className="boxi-num-1">
                    <div className="boxi-num-1-1">Connectez vous</div>
                    <div className="boxi-num-1-2">Connectez-vous pour accéder à votre compte Pro.</div>
                </div>
                <div className="boxi-num-2">
                    <form  onSubmit={takeSubmit}>
                        <input type="text"
                          value={email} 
                          onChange={(e) => setEmail(e.target.value)} 
                          className="boxi-num-2-1" placeholder="Adresse email" required />
                        <input type="password"
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)} 
                          placeholder="Mot de passe" className="boxi-num-2-2" required/>
                        <div className="boxi-num-2-3"><input className="boxi-num-2-3-1" id="check"  type="checkbox"  /><label htmlFor='check' className="boxi-num-2-3-2" >Se souvenir de moi</label></div> 
                       <> 
                            <button className="boxi-num-3  btnload " type="submit">Se connecter</button>
                       </>     
                       <p className="error"> {error && <p>{error}</p>}</p>
                    </form>
                </div>
                <div className="boxi-num-4">
                        <div className="boxi-num-4-1"></div>
                        <div className="boxi-num-4-2">Ou se connecter avec</div>
                </div>
                <div className="boxi-num-5">
                    <div className="boxi-num-5-1">
                        <div className="boxi-num-5-1-1"><img className="logo-google"  src='./sary/logoG.png' alt=""/><div className="text-google">Google</div></div>
                        <div className="boxi-num-5-1-2"><img className="logo-facebook" src='./sary/facebook.jpg' alt=""/><div className="text-facebook">Facebook</div></div>
                    </div>
                    <div className="boxi-num-5-2">
                        <div className="boxi-num-5-2-1" ><img className="logo-linkedin" src='./sary/link.png' alt=""/><div className="text-linkedin">Linkedin</div> </div>
                        <div className="boxi-num-5-2-2" ><img className="logo-instagram" src="./sary/téléchargement.jpg" alt=""/><div className="text-instagram">Instagram</div></div>
                    </div>
                </div>
                <div className="boxi-num-6">Vous n'avez pas de compte ? <span className="boxi-style" onClick={()=>hide(2)}>S’inscrire</span></div>


            </div>
             : ''}
           {valinscri===2?
           
           <Inscription2 data={hide}/>:''}
           {valinscri===3 ?
    
        <Inscription3 />
                    :''
  }
        </>
    )
}
export default Login2