import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";

const Failed = () => {
    return (
        <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    height: '100vh' 
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>
                    Paiement Échoué
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Votre paiement n'a pas pu être traité. Veuillez vérifier vos informations et réessayer.
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => window.history.back()} 
                    sx={{
                        backgroundColor: "#f9194f",
                        '&:hover': { backgroundColor: "#d31644" },
                        padding: "10px 20px",
                        marginTop: "20px"
                    }}
                >
                    Réessayer
                </Button>
            </Box>
        </Container>
    );
};

export default Failed;
