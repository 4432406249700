// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displ{
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    width: 95%;
}
.displx{
    display: flex;
    align-items: center;
    justify-content: space-between;
   
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/component/ShopDetail/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,UAAU;AACd;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;IAE9B,YAAY;AAChB","sourcesContent":[".displ{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n   \n    width: 95%;\n}\n.displx{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n   \n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
