import { Box, colors, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Accordeon from './Accordeon/Accordeon'
import Reviews from "./Reviews/Reviews";
import RightBar from "./RightBar/RightBar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './About.css'
const HorizontalLine = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "1px",
        backgroundColor: "divider",
        mt: 3,
        mb: 3,
      }}
    />
  );
};

const About = ({ service }) => {
  let services = service
  //console.log(service)
  useEffect(() => {
    services = service
  }, [])
  return (
    <Box>
      <Grid container pb={5} spacing={2}>
        <Grid item xs={12} lg={8}>
          <Box>
            <Typography sx={{ fontWeight: "bold" }} mb={2} mt={2} variant="h5">
              A propos
            </Typography>
            <Typography sx={{ fontWeight: "500" }} variant="body1">
              {service.salon ? service.salon.apropos : ''}

              {/*services.salon ? services.salon.description:'loadin....'*/}
              { /* service.salon!==null & service.salon !==undefined ?
         
          services.salon['apropos']
          :''*/

              }
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }} mb={2} mt={2} variant="h5">
              Description
            </Typography>
            <Typography sx={{ fontWeight: "500" }} variant="body1">
          
              {service.salon !== null & service.salon !== undefined ?

                services.salon['description']
                : ''

              }
            </Typography>
          </Box>
          <HorizontalLine />

          <Typography sx={{ fontWeight: "bold" }} mb={2} mt={2} variant="h5">
            Découvrer nos préstation
          </Typography>

         
          {service.salon_service ?
            service.salon_service.map((salon, key) => (
              <Accordion key={salon.id}>
              <AccordionSummary sx={{ display:'flex' ,alignItems:'center',justifyContent:'space-between' }} expandIcon={<ExpandMoreIcon />}>
               <div className="displ">
               <Typography >{salon.name}</Typography>
               
               <Typography><span  className="displx"><span>{salon.duration}</span><span> {salon.price}$</span></span></Typography>

               </div>
              


              </AccordionSummary>
  
              <AccordionDetails>
                <Typography>{salon.description}</Typography>
              </AccordionDetails>
            </Accordion>
             

            )
            )
            : ' loading....'

          }

          { /*<Accordeon />
          <Accordeon />*/}

          <HorizontalLine />

          <Box>
            <Typography sx={{ fontWeight: "bold" }} mb={2} mt={2} variant="h5">
              Localisation
            </Typography>
          </Box>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9503398796586!2d-73.98531003867917!3d40.7594371793141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f711cf39%3A0x6c38ba916571686d!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1624681500506!5m2!1sen!2sus"
            width="100%"
            height="500px"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />

          <HorizontalLine />

          <Box>
            <Typography sx={{ fontWeight: "bold" }} mb={2} mt={2} variant="h5">
              Avis des clients
            </Typography>
          </Box>

          <Reviews />
        </Grid>

        <Grid item xs={12} lg={4}>
          <RightBar service={service} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default About;
