import React, { useState } from "react";
import Chart from "react-apexcharts";

const PieDemoChart = () => {
  const [chartState, setChartState] = useState({
    options: {
      chart: {
        width: 240,
        type: "pie",
      },
      labels: ["Team A", "Team B", "Team C", "Team D"],
      colors: ["#f91944", "#33FF57", "#FF5733", "#00aaff"], // Ajout de plusieurs couleurs
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 100,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 13, 43, 22], // Série en dehors de l'objet `options`
  });

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="pie" // Utilise "pie" en minuscules
            width="300"
            height="800"
          />
        </div>
      </div>
    </div>
  );
};

export default PieDemoChart;
