import React from 'react'
import './Filtre.css'

 export default function Filtre() {
  return (
    <>
 
    <div  className='filtre'>
        <button className='border'> <i className='ti-filter'></i> Filtres</button>
        <button>Massage bodybody homme</button>
        <button>Massage bodybody femme</button>
        <button>Massage bodybody reciproque</button>
    </div>
    
    </>
  )
}
