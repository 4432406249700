import { Box, Stack, Typography } from "@mui/material";
import { FaUserCog } from "react-icons/fa";
import { daytoString, MonthToString } from "../../event/utils/converDate";

export default function CardVp() {
  return (
    <>
      <Stack
        boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.3)"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Box p={2}>
          <Typography> Deep Tissue Massage Workshop</Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <FaUserCog />
            <Typography>Body & Mind Wellness</Typography>
          </Box>
          <Typography>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, non sint officiis</Typography>
        </Box>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          bgcolor={"#ff4dd8"}
          color={"white"}
          px={2}
        >
          <Typography fontWeight={600}>
            {daytoString(new Date("2024-11-30").getDay())}{" "}
          </Typography>
          <Typography fontWeight={600} fontSize={"22px"}>
            {new Date("2024-11-30").getDay()}
          </Typography>

          <Typography fontWeight={600} fontSize={"14px"}>
            {`${MonthToString(new Date("2024-11-30").getMonth())}`}{" "}
            {new Date("2024-11-30").getFullYear()}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
