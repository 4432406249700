import './MenuFiltre.css';
import React from 'react';
import { NavLink } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
} from "@mui/material";
function MenuFiltre() {
    const [oui, srtoui] = useState(true)
    const windowWidth = window.innerWidth;
    useEffect(() => {
        if (windowWidth < 900) {
            srtoui(false)
        } else {
            srtoui(true)
        }

        function showrech() {

            srtoui(!oui)

        }
    }, [])
    function showrech() {

        srtoui(!oui)

    }
    function goo() {
        if (windowWidth < 900) {
            srtoui(!oui)
        } else {
            srtoui(true)
        }

    }
    function formatDate(inputDate) {
        // Vérifiez si la date est au bon format
        if (!/\d{4}-\d{2}-\d{2}/.test(inputDate)) {
            return 'Format de date invalide';
        }

        // Séparez les parties de la date
        const [year, month, day] = inputDate.split('-');

        // Formatez la date dans le format souhaité
        return `${day}-${month}-${year}`;
    }
    const [name, setname] = useState('');
    const [country, setcountry] = useState('');
    const [date, setdate] = useState('');
    const basePath = '/filtre';

    let pathSegments = [];
    let queryParams = new URLSearchParams();

    if (name) {
        pathSegments.push(name);
        queryParams.append('name', name); // Remplacez 'value1' par ce que vous souhaitez
    }

    if (country) {
        pathSegments.push(country);
        queryParams.append('country', country); // Remplacez 'value2' par ce que vous souhaitez
    }

    if (date) {
        pathSegments.push(date);
        queryParams.append('date', formatDate(date)); // Remplacez 'value2' par ce que vous souhaitez
    }

    // Construire le chemin final
    const path = `${basePath}/${pathSegments.join('/')}`;
    // Ajouter les paramètres de requête s'il y en a
    const finalUrl = path + (queryParams.toString() ? '?' + queryParams.toString() : '');
    return (
        <>
            <div className="Menufiltre">
                <p onClick={() => showrech()} className='rec'>{oui? <CloseIcon />:   <SearchIcon />}</p>
                {
                    oui ?
                        <form>
                            <div className='recherche'>
                                <div className='que'>
                                    <TextField
                                        type="text"
                                        margin="dense"
                                        label="Que cherchez-vous ?"
                                        fullWidth
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) => setname(e.target.value)}
                                    />
                                </div>
                                <div className='que'>
                                    <TextField
                                        type="text"
                                        margin="dense"
                                        label="OU ?"
                                        fullWidth
                                        variant="outlined"
                                        value={country}
                                        onChange={(e) => setcountry(e.target.value)}
                                    />
                                </div>
                                <div className='que'>
                                    <p className='gray'> Quand ? </p>
                                    <input type='date' placeholder='juillet 2024'
                                        value={date}
                                        onChange={(e) => setdate(e.target.value)}
                                    />
                                </div>
                                <NavLink
                                    to={(finalUrl)}
                                    onClick={() => goo()}
                                    className={({ isActive, isPending }) =>
                                        isPending ? "pending" : isActive ? "active" : ""
                                    }
                                >
                                    <button><i className='ti-search'></i></button></NavLink>
                            </div>
                        </form>
                        : ''}
            </div>
        </>
    );
}
export default MenuFiltre; 