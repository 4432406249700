import { React, useEffect, useRef, useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import Inscription3 from "../inscription/Inscription3";
import Inscription from "../inscription/Inscription";
import Inscription4 from "../inscription/Inscription4";
import Inscription5 from "../inscription/Inscription5";
import Mot from "./Mot";
import { loging } from "../../../authentification/Authentificationl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
const Login = ({dat}) => {
  const loginp = useRef(null);
  const [email, setEmail] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [load, setload] = useState(false);
  const navigate = useNavigate();
  const [valinscri, setinscrire] = useState(1);
  useEffect(() => {
    if (loginp) {
      loginp.current.focus();
    }
    setinscrire(1);
    setinscrire(dat);
    console.log(dat)
  }, []);
  const googleo = async () => {
    try {
      const response = await axios.get(
        "https://www.api-macerise.masovia-madagascar.com/api/auth/google"
      );
      //   setEvents(response.data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setload(true);
    try {
      const userData = await loging(email, password);
      console.log("Connexion réussie", userData);

      // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici

      setload(false);
      //  navigate('/dashboard');

      if (userData.token) {
        if (userData.role === "client") {
          setTimeout(() => {
            navigate("/");
          }, 1000);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } else {
          setTimeout(() => {
            navigate("/");
          }, 1000);
          setTimeout(() => {
            navigate("/dashboardPro");
          }, 1500);
        }

        //console.log(userData.token)
      } else {
        // setError(userData.message)
        // console.log(userData.token)
      }
      console.log(userData);
    } catch (error) {
      setload(false);
      setError(
        "Échec de la connexion. Veuillez vérifier vos informations d'identification."
      );
    }
  };

  //affichage inscription

  function hide(val) {
    setinscrire(val);
  }
  const [passwordz, setPasswordz] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  function fermer(){
    const login=document.querySelector('.login')
    login.style.display='none';
     setinscrire(1)
}

  return (
    <>

<div onClick={() => fermer()} className=" fermerx" ></div>
<i onClick={() => fermer()} className="ti-close fermerxxx" ></i>
      {valinscri == 1 ? (
        <div className="login-container">
          <div className="boxi-num-1">
            <div className="boxi-num-1-1">Connectez vous</div>
            <div className="boxi-num-1-2">
              Connectez-vous pour accéder à votre compte.
            </div>
          </div>
          <div className="boxi-num-2">
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                ref={loginp}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="boxi-num-2-1"
                placeholder="Adresse email"
                required
              />
              <div   className="boxi-num-2-2 "> 
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Mot de passe"
                  className="boxi-num-2-2r "
                  required
                  style={{
                   fontWeight:'400'
                  }}
                />

                <span
                  onClick={() => setShowPassword(!showPassword)}
                     style={{
                    cursor: "pointer",
                    color: "#999",
                   
                    position: "absolute",
                    right:'10px',
                    zIndex:'90'
                  }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>

              <div className="boxi-num-2-3">
                <input className="boxi-num-2-3-1" id="check" type="checkbox" />
                <label htmlFor="check" className="boxi-num-2-3-2">
                  Se souvenir de moi
                </label>
              </div>

              <button className="boxi-num-3  btnload " type="submit">
                {load ? "loading..." : "Se connecter"}
              </button>
              <p className="error"> {error && <p>{error}</p>}</p>
            </form>
          </div>
          <div className="motpp" onClick={()=>hide(6)}>Mot de passe oublié ?</div>
          <div className="boxi-num-4">
            <div className="boxi-num-4-1"></div>
            <div className="boxi-num-4-2">Ou se connecter avec</div>
          </div>
          <div className="boxi-num-5">
            <div className="boxi-num-5-1">
              <div onClick={() => googleo()} className="boxi-num-5-1-1">
                <img className="logo-google" src="../sary/logoG.png" alt="" />
                <div className="text-google">Google</div>
              </div>
              <div className="boxi-num-5-1-2">
                <img
                  className="logo-facebook"
                  src="../sary/facebook.jpg"
                  alt=""
                />
                <div className="text-facebook">Facebook</div>
              </div>
            </div>
            <div className="boxi-num-5-2">
              <div className="boxi-num-5-2-1">
                <img className="logo-linkedin" src="../sary/link.png" alt="" />
                <div className="text-linkedin">Linkedin</div>{" "}
              </div>
           
                {" "}
                <div className="boxi-num-5-2-2">
                  <img
                    className="logo-instagram"
                    src="../sary/téléchargement.jpg"
                    alt=""
                  />
                  <div className="text-instagram">Instagram</div>
                </div>
             
            </div>
          </div>
          <div className="boxi-num-6">
            Vous n'avez pas de compte ?{" "}
            <span className="boxi-style" onClick={() => hide(2)}>
              S’inscrire
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {valinscri === 2 ? <Inscription data={hide} /> : ""}
      {valinscri === 3 ? <Inscription3 data={hide} /> : ""}
      {valinscri === 4 ? <Inscription4 data={hide} /> : ""}
      {valinscri === 5 ? <Inscription5 data={hide} /> : ""}
      {valinscri===6?  <Mot data={hide}/>:''}
    </>
  );
};
export default Login;
