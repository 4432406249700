
import React, { useState, useEffect } from 'react';
import ServicesMassages from './ServicesMassages'
import VueAccueil from './VueAccueil';
import Recommander from './Recommander';
import TrouverProf from './TrouverProf';
import Etablissement from './Etablissement';
import NouveauPro from './NouveauPro';
import QuestionFre from './QuestionFre';
import Footer from '../outil/Footer'
import NavMain from '../Navigation/NavMain';
import { CircularProgress } from '@mui/material';

export default function Accueil({ val, fsalonId, fcategoId }) {
   useEffect(() => {
      // window.scroll({top:0})
   }, [])
   const [isOnline, setIsOnline] = useState(navigator.onLine);
   const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
   };
   useEffect(() => {
      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);

      // Nettoyage de l'effet
      return () => {
         window.removeEventListener('online', updateOnlineStatus);
         window.removeEventListener('offline', updateOnlineStatus);
      };
   }, []);

   return (
      <>
         <NavMain val={val} />
         <VueAccueil />
         <p>{isOnline ? <> 

            <ServicesMassages fcategoId={fcategoId} />
            <Recommander />
            <TrouverProf />
            <Etablissement fsalonId={fsalonId} loginv={val} />
            <NouveauPro />
            <QuestionFre /> </> : < div className='loading'> <CircularProgress sx={{
               position: 'fixed',
               top: '100px',
               zIndex: '100'
            }} /></div>}</p>

         <Footer />
      </>
   )
}

