import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CustomParagraph from "../../CustomParagraph/CustomParagraph";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const BarContainer = styled(Box)(() => ({
  border: "1px solid #dddddd",
  padding: 20,
  borderRadius: 3,
}));

const FlexArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 15,
}));

const RightBar = ({ service }) => {
  let token = localStorage.getItem("token");

  return (
    <>
      <BarContainer>
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          Réservation
          {service.salon ? "   " + service.salon.name : "loadin...."}
        </Typography>

        <FlexArea my={2}>
          <CalendarTodayIcon style={{ color: "#555555" }} />
          <CustomParagraph sx={{ marginTop: 1, lineHeight: 1 }}>
            Réserver des aujourd'hui
          </CustomParagraph>
        </FlexArea>

        <FlexArea my={2}>
          <ChatBubbleOutlineIcon style={{ color: "#555555" }} />
          <CustomParagraph sx={{ marginTop: 1, lineHeight: 1 }}>
            Pour tout autre question tu peut contacter ASHLEY
          </CustomParagraph>
        </FlexArea>

        <PrimaryButton
          service={service}
          size={"large"}
          sx={{ width: "100%", marginTop: 2 }}
        >
          {token ? "Choisir une date" : " Connectez-vous pour reserver "}
        </PrimaryButton>

        <SecondaryButton size={"large"} sx={{ width: "100%", marginTop: 2 }}>
          Contacter l'agent
        </SecondaryButton>
        <Table sx={{ marginTop: "18px" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={{ color: "black" }}>Jours</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ color: "black" }}>Ouverture</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ color: "black" }}>Fermeture</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ color: "black" }}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell >
                <Typography sx={{ color: "black" }}>Lundi</Typography>
                <Typography sx={{ color: "black" }}>Mardi</Typography>
                <Typography sx={{ color: "black" }}>Mercredi</Typography>
                <Typography sx={{ color: "black" }}>Jeudi</Typography>
                <Typography sx={{ color: "black" }}>Vendredi</Typography>
                <Typography sx={{ color: "black" }}>Samedi</Typography>
                <Typography sx={{ color: "black" }}>Dimanche</Typography>
              </TableCell>
              <TableCell >
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
              </TableCell>
              <TableCell >
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
                <Typography sx={{ color: "black" }}>00:00</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </BarContainer>
    </>
  );
};

export default RightBar;
