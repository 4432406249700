import React, { useState } from 'react'
import './NavMain.css'
import Logo from '../outil/Logo'
import Menu from '../outil/Menu'
import Au  from '../outil/Auth'
import Authclient from  '../outil/Authclient'
export default function Navclient({val}) {
  return (
    <header>
              <div className='simplemenu'>
                        <Logo/>
                        <Menu   /> 
                       <Authclient val={val} /> 
                </div>
    </header>
  )
}
