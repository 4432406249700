import './Logo.css'
import { NavLink } from 'react-router-dom';
function Logo() {
  return (
   <div className='logo'>
        <NavLink to="/">
              <img  src="/logomacerise.png" alt="photo_macerise"/>
        </NavLink>
    </div>
  );
}
export default Logo;