// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer .logof{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    margin-left: 3px;
    margin-bottom: 20px;
 }
 footer img{
   width: 200px;
 }
 `, "",{"version":3,"sources":["webpack://./src/component/outil/Logofooter.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;CACtB;CACA;GACE,YAAY;CACd","sourcesContent":["footer .logof{\n    width: 200px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height:50px;\n    margin-left: 3px;\n    margin-bottom: 20px;\n }\n footer img{\n   width: 200px;\n }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
