import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import Call from "../../calendrier/Call";
import '../../calendrier/call.css'
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PrimaryButton = ({ children, size, sx ,service }) => {
  
  const navigate = useNavigate();
  const [show,setshow]=useState(false);
  const [message,setmessage]=useState('');

  function showcalendrier(val,mess){
    if(localStorage.getItem('token')){
   setshow(val)
    setmessage(mess)
  }else{
    const login=document.querySelector('.login')
    login.style.display='flex';
  }
  }
  
  function hidecalendar(){

  
      setshow(false)
 
  
   
   }
 
  return (
    <>
 
       { show? <div className="absolut">
               <div className="transpar" onClick={hidecalendar}></div>
                  <Call  showcalendrier={showcalendrier} service={service}/>
       </div>:''}

       
    <Button onClick={()=>showcalendrier(true,'')}
      elevation={0}
      variant="contained"
      size={size}
      sx={{
        ...sx,
        background: "#fb174e",
        fontWeight: "bold",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#fb174e",
          boxShadow: "none",
        },
        textTransform: "none",
      }}
    >
      {children}
    </Button>
     <p className="mess">{message}</p>
   
    </>
  );
};
export default PrimaryButton;
