import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavMain from "../Navigation/NavMain";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { daytoString, MonthToString } from "../event/utils/converDate";
import Timer from "./component/timer";
import { useEffect, useState } from "react";
import { FaUserCog } from "react-icons/fa";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Footer from "../outil/Footer";
import CardVp from "./component/voirpluEvent";
import { AddCircle } from "@mui/icons-material";
import InputPlace from "./component/inputPlace";
import { useParams, useSearchParams } from "react-router-dom";
import { getOneEvent } from "./api/singleEvent";
// function parseTimeString(timeString) {
//   const [hours, minutes, seconds] = timeString.split(":").map(Number);
//   return { hours, minutes, seconds };
// }
export default function SingleEvent() {
  const [targetDate, setTargetDate] = useState();
  const [targetTime, setTargetTime] = useState("0:0:0");
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [event, setEvent] = useState({});
  const params = useParams();

  // const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timeString = "14:00:00"; // Heure cible
    const date = "2024-11-30";
    getOneEvent({ id: params["id"] })
      .then((res) => {
        console.log("Hours", res.data["evenment"]["heure"]);
        setTargetDate(res.data["evenment"]["date"]);
        setTargetTime(res.data["evenment"]["heure"]);
        setEvent(res.data["evenment"]);
      })
      .catch((err) => console.log("Erreur", err));
  }, []);
  return (
    <>
      <NavMain />
      {/* <Stack direction={"row"}> */}
      <Stack
        p={smDown ? "20px 0px 0 20px" : "50px 0px 0 50px"}
        direction={"column"}
        width={"100%"}
        flexDirection={"column"}
        position={"relative"}
        height={"88svh"}
        sx={{
          background: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/image/1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          color: "white",
        }}
      >
        <Typography component={"h1"} fontSize={"32px"} color="white">
          {event.name}
        </Typography>
        <List sx={{ mt: "40px" }}>
          <ListItem sx={{}}>
            <ListItemIcon sx={{ minWidth: "25px", mr: "20px" }}>
              <FaCalendarAlt color="white" size={32} />
            </ListItemIcon>
            <ListItemText sx={{ fontSize: "26px" }}>
              <Typography sx={{ fontSize: "20px" }}>
                {" "}
                {daytoString(new Date(event.date).getDay())}{" "}
                {new Date(event.date).getDay()}{" "}
                {`${MonthToString(new Date(event.date).getMonth())}`}{" "}
                {new Date(event.date).getFullYear()} 10h00 - 18h00
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem sx={{}}>
            <ListItemIcon sx={{ minWidth: "25px", mr: "20px" }}>
              <FaMapMarkerAlt color="white" size={32} />
            </ListItemIcon>
            <ListItemText>
              {" "}
              <Typography sx={{ fontSize: "20px" }}>{event.lieu}</Typography>
            </ListItemText>
          </ListItem>
        </List>
        <Typography component={"h1"} fontSize={"26px"} color="white">
          organisateurs : {event.organisateur}
        </Typography>
        <Stack
          p={smDown ? 2 : 5}
          position={"absolute"}
          bottom={0}
          width={"100%"}
          direction={"row"}
          alignSelf={"flex-end"}
          gap={smDown ? 2 : 5}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography component={"h1"} fontSize={"32px"} color= "white">
            {event.name}
          </Typography>
          <List sx={{ mt: "40px" }}>
            <ListItem sx={{}}>
              <ListItemIcon sx={{ minWidth: "25px", mr: "20px" }}>
                <FaCalendarAlt color="white" size={32} />
              </ListItemIcon>
              <ListItemText sx={{ fontSize: "26px" }}>
                <Typography sx={{ fontSize: "20px" }}>
                  {" "}
                  {daytoString(new Date(event.date).getDay())}{" "}
                  {new Date(event.date).getDay()}{" "}
                  {`${MonthToString(new Date(event.date).getMonth())}`}{" "}
                  {new Date(event.date).getFullYear()} 10h00 - 18h00
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={{}}>
              <ListItemIcon sx={{ minWidth: "25px", mr: "20px" }}>
                <FaMapMarkerAlt color="white" size={32} />
              </ListItemIcon>
              <ListItemText>
                {" "}
                <Typography sx={{ fontSize: "20px" }}>
                  {event.lieu}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Typography component={"h1"} fontSize={"26px"} color= "white">
            organisateurs : {event.organisateur}
          </Typography>
          <Stack
            p={5}
            position={"absolute"}
            bottom={0}
            width={"100%"}
            direction={"row"}
            alignSelf={"flex-end"}
            gap={5}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Timer targetDate={targetDate} targetTime={targetTime} />
          </Stack>
        </Stack>
      </Stack>
      {/* </Stack> */}

      <Typography mt={"40px"} mb={"20px"} fontSize={"24px"} px={smDown ? 2 : 5}>
        {event.name}
      </Typography>
      <Stack
        direction={mdDown ? "column" : "row"}
        px={smDown ? 2 : 5}
        mb={5}
        gap={5}
      >
        <Stack
          boxShadow={"0px 0px 10px rgba(0, 0, 0, 0.3)"}
          width={mdDown ? "100%" : "55%"}
          direction={"row"}
        >
          <Box
            component={"img"}
            src="/image/2.png"
            sx={{
              width: {
                xs: "125px",
                sm: "150px",
                md: "150px",
                lg: "250px",
              },
            }}
          ></Box>

          <Stack p={2} gap={2} direction={"column"}>
            <Box display="flex" alignItems="center" gap={2}>
              <FaUserCog size={"22px"} />
              <Typography>{event.organisateur}</Typography>
            </Box>
            <Divider></Divider>
            <Typography fontSize={"18px"}>
              intervenants : Dr. Marie Dupont, Thérapeute; Alice Martin,
              Masseuse professionnelle, Dr Franck
            </Typography>
            <Typography>{event.nombre_place} places disponible</Typography>
            <InputPlace nbPlace={event.nombre_place} price={event.price} />
          </Stack>
        </Stack>

        <Stack direction={"column"} width={mdDown ? "100%" : "50%"}>
          <Typography fontWeight={600} mb={2}>
            Voir aussi
          </Typography>
          <Stack direction={"column"} gap={2} flexWrap={"wrap"}>
            <CardVp />
            <CardVp />
            <CardVp />
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
