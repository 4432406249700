import { React, useEffect, useState } from "react";
import "./Inscription2.css";
import axios from "axios";
// import Inscription3 from "./Inscription3";
// import { useNavigate } from "react-router-dom";
import { registerr3 } from "../../../authentification/Authentificationl";
import { useNavigate } from "react-router-dom";
// import {verifi_code} from "../../../authentification/Authentificationl";

const Inscription3 = ({ data, userid }) => {
  // nom d'utilisateur dans est name
  const [name, setName] = useState("");
  // adrress mail
  const [email, setEmail] = useState("");
  // telephone
  const [tel, setTel] = useState("");
  // adress
  const [address, setAddress] = useState("");
  // date de naissance
  const [date, setDate] = useState("");
  const [start_time, setStart_time] = useState("");
  const [end_time, setEnd_time] = useState("");
  //passwod
  const [password, setPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [user_id, setUser_id] = useState( localStorage.getItem('userId'));
  // const [images, setImage] = useState("");
  const [role_id, setRole] = useState(2);
  const [country_id, setCountry_id] = useState("");
  const [load, setload] = useState(false);
  const [error, setError] = useState("");
  const [succ, setSucc] = useState("");
  const [countries, setCountries] = useState([]);
  const [description, setDescription] = useState('');
  const [city, setville] = useState('');
  const [apropos, setApropos] = useState('');
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("/countries");
        setCountries(response.data);
      } catch (error) {
        console.error("Erreur de récupération des pays", error);
        setError("Erreur de récupération des pays.");
      }
    };
    fetchCountries();
  }, []);
  const navigate = useNavigate();


  const takeSubmit = async (e) => {
    e.preventDefault();
  
    setError("");
    setSucc("");
    setload(true);
  
    try {
      const id_count = country_id;
      // Call the registration function and retrieve the response
      const userData = await registerr3(name, address, description, apropos, user_id, city, id_count);
      
      // Log the response to confirm the structure
      console.log('Confirmation réussie:', userData);
  
      // Check and retrieve salonId from the response
      const salonId = userData.salon.id;
      if (salonId) {
        // Store salonId in localStorage for the next step
        localStorage.setItem('salonId', salonId);
        console.log("Salon ID:", salonId);
  
        // Move to the next step
        data(4); // Assuming data(4) proceeds to the next step
      } else {
        console.error("Salon ID not found in the response.");
      }
      
    } catch (error) {
      setload(false);
      setError("An error occurred during registration.");
      console.error("Registration error:", error);
    }
    // data(4);
  };
  
  function show() {
    data(1);
  }

 // console.log(countries);
  return (
    <>
      <div className="inscription-container">
        <div className="boxis-num-1">
          <div className="boxis-num-1-1">Bienvenue sur Macerise</div>
          <div className="boxis-num-1-2">
            Inscrivez-vous pour commencer Pro.
          </div>
        </div>
        <div className="boxis-num-2">
          <form onSubmit={takeSubmit}>
            <div className="block">
              <div className="block1">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  // required
                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="Nom du salon "
                />
                <input
                  // id="tel"
                  value={user_id}
                  onChange={(e) => setUser_id(e.target.value)}
                  // required

                  type="hidden"
                  className="boxis-num-2-1-S"
                  placeholder="user_id"
                />
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  // required

                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="address du Salon"
                />
                <input
                  value={apropos}
                  onChange={(e) => setApropos(e.target.value)}
                  // required

                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="A propos"
                />
                 <input
                  value={city}
                  onChange={(e) => setville(e.target.value)}
                  // required

                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="ville"
                />
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  // required

                  type="text"
                  className="boxis-num-2-1-S"
                  placeholder="description"
                />
                {/* <div className="boxis-num-2-1-S birth">
                                       <p>Image</p>
                        <input type="hidden" onChange={(e) => setImage(e.target.files[0])} />
                    </div> */}

                <select
                  id="country"
                  value={selectedCountry}
                  className="boxis-num-2-1-S"
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    setCountry_id(e.target.value);
                  }}
                >

                  <option value="">Sélectionnez un pays</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="block2">  
              </div> */}
            </div>
            <button
              className="boxis-num-3 btnload "
              type="submit"
            //   onClick={show}
            >
              S'inscrire
            </button>
            <p className="error"> {error && <p>{error}</p>}</p>
            <p className="succ"> {succ && <p>{succ}</p>}</p>
          </form>
        </div>

        <div className="boxis-num-4">
          <div className="boxis-num-4-1"></div>
          <div className="boxis-num-4-2">Ou se connecter avec</div>
        </div>
        <div className="boxis-num-5">
          <div className="boxis-num-5-1">
            <div className="boxi-num-5-1-1">
              <img alt="" className="logo-google" src="./sary/logoG.png" />
              <div className="text-google">Google</div>
            </div>
            <div className="boxi-num-5-1-2">
              <img alt="" className="logo-facebook" src="./sary/facebook.jpg" />
              <div className="text-facebook">Facebook</div>
            </div>
          </div>
          <div className="boxis-num-5-2">
            <div className="boxi-num-5-2-1">
              <img alt="" className="logo-linkedin" src="./sary/link.png" />
              <div className="text-linkedin">Linkedin</div>{" "}
            </div>
            <div className="boxi-num-5-2-2">
              <img
                alt=""
                className="logo-instagram"
                src="./sary/téléchargement.jpg"
              />
              <div className="text-instagram">Instagram</div>
            </div>
          </div>
        </div>
        <div className="boxis-num-6">
          Vous avez déjà un compte ?
          <span className="boxis-style" onClick={show}>
            {" "}
            Se connecter
          </span>
        </div>
      </div>
    </>
  );
};
export default Inscription3;
