import React, { useState ,useEffect} from 'react';
import { Box, Button, Typography, Paper, Container, styled } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useParams } from 'react-router-dom';
const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  backgroundColor: '#fff',
  color: '#000',
  ':hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#fff',
    cursor: 'pointer'
  }
}));

const ImagePanel = React.memo(({ src, alt, position, children }) => (
  <Paper elevation={2} sx={{ height: '220px', position: 'relative' }}>
    <img src={src} alt={alt} style={{ width: '100%', borderRadius: '4px', height: '100%' }} />
    <Box sx={{ position: 'absolute', display: 'flex', gap: 2, top: position.top, left: position.left, bottom: position.bottom, right: position.right }}>
      {children}
    </Box>
  </Paper>
));

function ImageGallery  ({ service ,ok,sett}) { 

  let { id } = useParams();

  let [tab,setrab]=useState([]);
 
 
  
  const token=localStorage.getItem('token')
  const ajout = async () => {
    let token = localStorage.getItem('token');
    sett(!ok)
    // Check if token exists
    if (!token) {
      console.error('No authorization token found.');
      return; // Exit the function if there's no token
    }
  
    try {
      const response = await axios.post(
        "https://www.api-macerise.masovia-madagascar.com/api/favoris-toggle/"+id,
        {}, // Request body
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      console.log(response.data);
      // Optionally handle state update here
    } catch (err) {
      console.error('Error occurred:', err);
    }
  };
 


  
  return (
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
      <Typography variant="body1" >Accueil  &gt;  {service.salon ? service.salon.address : 'loadin....'} &gt;  {service.salon ? service.salon.name : 'loadin....'}</Typography>
    </Box>

    <Box display="flex" width="100%" flexDirection={['column', 'column', 'row']}>
      <Paper elevation={3} sx={{ position: 'relative', width: ['100%', '100%', '58%'], overflow: 'hidden', borderRadius: 2, height: ['220px', '220px', '455px'] }}>
        <img src="../image/cheri1.png" alt="Main"
          style={{ width: '100%', borderRadius: '8px', height: '100%' }} />
        <Box sx={{ position: 'absolute', top: 16, left: 16 }}>
          <CustomButton href='/' startIcon={<ArrowBackIcon />}>Retour</CustomButton>
        </Box>
      </Paper>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: ['100%', '100%', '40%'], ml: 'auto', mt: [2, 2, 0] }}>
        <ImagePanel src="../image/cherie2.png" position={{ top: 16, left: 16 }} alt="Thumbnail 1">
          <CustomButton startIcon={<ShareIcon />}>Partager</CustomButton>
          <CustomButton startIcon={ ok?<FavoriteBorderIcon color="error"  />:<FavoriteIcon  color="error"  />} onClick={()=>ajout()} >Ajouter aux favoris</CustomButton>
      </ImagePanel>

      <ImagePanel src="../image/cherie3.jpg" position={{ bottom: 16, right: 16 }} alt="Thumbnail 2">
        <CustomButton startIcon={<ZoomOutMapIcon />}>Ouvrir la galerie</CustomButton>
      </ImagePanel>
    </Box>
  </Box>
  </Box >)
};

export default ImageGallery;