import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import CustomParagraph from "../../CustomParagraph/CustomParagraph";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import SecondaryButton from "../../SecondaryButton/SecondaryButton";
import { useParams } from 'react-router-dom';
import { useRef } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,

  TableRow,
  Paper,
  Button,
} from "@mui/material";

const BarContainer = styled(Box)(() => ({
  border: "1px solid #dddddd",
  padding: 20,
  borderRadius: 3,
}));

const FlexArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 15,
}));

const RightBar = ({ service, tel, affiche }) => {
  const [agenda, setagenda] = useState([])
  let token = localStorage.getItem("token");
  const { id } = useParams(); // Récupérer l'ID de l'URL
  const fagenda = async () => {
    const token = localStorage.getItem('token')
    //   let id_users = localStorage.getItem('userId');
    try {
      const response = await axios.get('https://www.api-macerise.masovia-madagascar.com/api/days/salons/' + id, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      //  setsalonid(response.data.salons);

      setagenda(response.data.data)
      // console.log(response.data.data)

    } catch (err) {
      console.log(err)

    }
  };
  useEffect(() => {

    fagenda()

    //  console.log(tel)
  }, [])

  return (
    <>
      <BarContainer>
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          Réservation
          {service.salon ? "   " + service.salon.name : "loadin...."}
        </Typography>

        <FlexArea my={2}>
          <CalendarTodayIcon style={{ color: "#555555" }} />
          <CustomParagraph sx={{ marginTop: 1, lineHeight: 1 }}>
            Réserver des aujourd'hui
          </CustomParagraph>
        </FlexArea>

        <FlexArea my={2}>
          <ChatBubbleOutlineIcon style={{ color: "#555555" }} />
          <CustomParagraph sx={{ marginTop: 1, lineHeight: 1 }}>
            Pour tout autre question tu peut contacter    {service.salon ? "   " + service.salon.name : "loadin...."}
          </CustomParagraph>
        </FlexArea>

        <PrimaryButton
          service={service}
          size={"large"}
          sx={{ width: "100%", marginTop: 2 }}
        >
          {token ? "Choisir une date" : " Connectez-vous pour reserver "}
        </PrimaryButton>

        <SecondaryButton affiche={affiche} tel={tel} size={"large"} sx={{ width: "100%", marginTop: 2 }}>
          Contacter l'agent
        </SecondaryButton>
        <Table sx={{ marginTop: "18px",
          
           maxWidth: { xs: '90vw',sm:'90vw', md: '10vw' }// Table responsive
         
           }}>
          
          <TableHead sx={{  
            
           
            maxWidth: { xs: '80vw',sm:'90vw', md: '10vw' },// Table responsive
            border:'none'}}>
            <TableRow>
              <TableCell align="center" sx={{ padding: '5px', height:'50px', minWidth: { xs: '20vw',sm:'20vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' } }}>
                <Typography sx={{ color: "black" }}>Jours</Typography>
              </TableCell>
              <TableCell align="center" sx={{ padding: '5px', height:'50px', minWidth: { xs: '20vw',sm:'20vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' }}}>
                <Typography sx={{ color: "black" }}>Ouverture</Typography>
              </TableCell>
              <TableCell align="center" sx={{ padding: '5px', height:'50px', minWidth: { xs: '20vw',sm:'20vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' }}}>
                <Typography sx={{ color: "black" }}>Fermeture</Typography>
              </TableCell>
              <TableCell align="center" sx={{ padding: '5px', height:'50px', minWidth: { xs: '20vw',sm:'20vw', md: '7vw' },maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' } }}>
                <Typography sx={{ color: "black" }}>Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{  minWidth: { xs: '80vw',sm:'90vw', md: '10vw' } , maxWidth: { xs: '80vw',sm:'80vw', md: '10vw' }}} >
            <TableRow>
              <TableCell align="center" sx={{ padding: '0',border:'none',  minWidth: { xs: '20vw',sm:'20vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' }}}>
                {agenda && agenda.length > 0 ? (
                  agenda.map((item, index) => {
                    // Compter combien de lignes seront créées pour cet item
                    const rowCount = item.times.length;

                    return item.times.map((time, timeIndex) => (
                      <TableRow key={`${index}-${timeIndex}`}>
                        {timeIndex === 0 ? (
                          <TableCell align="center" sx={{ padding: '5px', height: `${rowCount * 50}px` , minWidth: { xs: '20vw',sm:'22vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'22vw', md: '7vw' } }}>
                            <Typography sx={{ color: "black" }}>{item.name}</Typography>
                          </TableCell>
                        ) : (
                          ''
                        )}


                      </TableRow>
                    ));
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography sx={{ color: "gray" }}>Aucun agenda disponible.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableCell>
              <TableCell align="center" sx={{ padding: '0', minWidth: { xs: '20vw',sm:'20vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'20vw', md: '7vw' },border:'none' }}>
                {agenda && agenda.length > 0 ? (
                  agenda.map((item, index) => (
                    item.times.map((time, timeIndex) => (
                      <TableRow key={`${index}-${timeIndex}`}>

                        <TableCell align="center" sx={{ padding: '5px', height:'50px', minWidth: { xs: '20vw',sm:'22vw', md: '7vw' },maxWidth: { xs: '20vw',sm:'22vw', md: '7vw' } }}>
                          <Typography sx={{ color: "black" }}>{time.start_time}</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '5px' ,height:'50px', minWidth: { xs: '20vw',sm:'22vw', md: '7vw' },maxWidth: { xs: '20vw',sm:'22vw', md: '7vw' }  }}>
                          <Typography sx={{ color: "black" }}>{time.end_time}</Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '5px' , height:'50px', minWidth: { xs: '20vw',sm:'22vw', md: '7vw' } ,maxWidth: { xs: '20vw',sm:'22vw', md: '7vw' }  }}>
                          <Typography sx={{ color: "black" }}>{time.status}</Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ))
                ) : (
                  <TableRow>
                    
                  </TableRow>
                )}
              </TableCell>
            </TableRow>

          </TableBody>



        </Table>
      </BarContainer>
    </>
  );
};

export default RightBar;
