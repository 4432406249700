import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
} from "@mui/material";
import { Delete, Edit, CheckCircle } from "@mui/icons-material";
import { servicemasseur } from "../../../authentification/Authentificationl";
import axios from "axios";
import { update } from "../../../authentification/Authentificationl";
import { duration } from "moment";
import Message from "./Message";

const Servicesalon
    = () => {
        const idser = localStorage.getItem("idsalon");
        const [error, setError] = useState("");
        const [items, setItems] = useState([]);
        const [name, setName] = useState("");
        const [description, setDesc] = useState("");
        const [duration, setDur] = useState("");
        const [price, setPrice] = useState("");
        // const [cat_id, setCat_id] = useState("");
        const [selectC, setSelectC] = useState("");
        const [editingItem, setEditingItem] = useState(null);
        const [editName, setEditName] = useState("");
        const [openAdd, setOpenAdd] = useState(false);
        const [openEdit, setOpenEdit] = useState(false);
        const [categories, setCategories] = useState([]);
        const [message, setMessage] = useState("");
        // modification :
        // const [nameMod, setnameMod] = useState("");
        // const [descMod, setDescMod] = useState("");
        // const [priceMod, setPriceMod] = useState("");
        const [category_id, setCat_id] = useState("");
        // const [durMod, setDurMod] = useState("");
        const [names, setNames] = useState("");
        const [modification, setModification] = useState(null);
        const [selectedCountry, setSelectedCountry] = useState();
        const [pivot, setPivot] = useState("");
        const userId = localStorage.getItem("userId");
        const [salon, setSalon] = useState([]);
        const [selectS, setSelectS] = useState("");
        const [salon_id, setSalon_id] = useState("");
        const [service, Setservice] = useState([]);
        const [tabvalue, setTabvalue] = useState({
            serid: "",
            name: "",
            names: "",
            description: "",
            price: "",
            duration: "",
            category_id: "",
        });

        const [openDel, setOpenDel] = useState(false);
        const [validation, setValidation] = useState(false);
        const [selectedId, setSelectedId] = useState(null);
        // affichage menu categorie dans les select
        const fetchCathegory = async () => {
            try {
                const response = await axios.get("/categories");
                setCategories(response.data.category);
                //   console.log("data cat :", response.data.category);
            } catch (error) {
                console.error("Erreur de récupération des pays", error);
                setError("Erreur de récupération des pays.");
            }
        };
        // fetchSalon
        const fetchSalon = async () => {
            try {
                const response = await axios.get(`/get/salon/user/${userId}`);
                setSalon(response.data.salons);
                //console.log("data salon :", response.data.salons);
            } catch (error) {
                console.error("Erreur de récupération des pays", error);
                setError("Erreur de récupération des pays.");
            }
        };
        // fectService
        const fectService = async () => {

            try {
                const response = await axios.get(`/salon/${idser}/all_services`);
                Setservice(response.data.services);
                // setPivot(response.data.salons.services);
                console.log("service:", response.data.services);
            } catch {
                console.log("erreur ");
            }
        };

        //UPDATE

        const handleEditItem = (
            serid,
            names,
            category_id,
            name,
            description,
            duration,
            price
        ) => {
            setTabvalue((prevForm) => ({
                ...prevForm,
                serid: serid,
                names: names,
                category_id: category_id,
                name: name,
                description: description,
                duration: duration,
                price: price,
            }));
            setOpenEdit(true);
            //   console.log("serid", serid);


        };

        const handleCloseEdit = () => {
            setOpenEdit(false);
            setEditingItem(null);
        };

        const token = localStorage.getItem("token");

        // console.log('tokene',token)
        const convertTimeFormat = (time) => {
            return `${time}:00`; // Ajoute les secondes
        }
        const handleUpdateItem = async (e) => {


            e.preventDefault();

            const { serid, names, category_id, name, description, duration, price } =
                tabvalue;
            // console.log('tablveu',tabvalue)
            try {
                //  console.log("tablveu", tabvalue);
                const response = await axios.put(
                    `https://api-macerise.masovia-madagascar.com/api/update/service/${serid}`,
                    {
                        names,
                        category_id,
                        name,
                        description,
                        duration,
                        price,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            // 'Content-Type': 'application/json'
                        },
                    }
                );

                setOpenEdit(false);
                fetchCathegory();
                fectService();
                fetchSalon();
            } catch (error) {
                console.error(
                    "Erreur lors de la mise à jour :",
                    error.response?.data || error.message
                );
            }
        };
        const [dataSup, setDataSup] = useState("");
        // DELETE
        // overture du modal
        const handleDeleteItem = (id) => {
            setSelectedId(id);
            setOpenDel(true);
        };
        // fonction qui se declenche lorsque le client appuie sur validation
        const handleValidation = () => {
            setValidation(true);
            setOpenDel(false);
        };

        useEffect(() => {
            if (validation && selectedId) {
                handleDeleteItem2(selectedId);
                setValidation(false); // Réinitialiser la validation après suppression
            }
        }, [validation, selectedId]);
        const handleCloseDel = () => {
            setOpenDel(false);
        };

        const handleDeleteItem2 = async (ids) => {
            try {
                const response = await axios.delete(
                    `https://api-macerise.masovia-madagascar.com/api/service/${ids}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Mettre à jour les données après suppression
                fetchCathegory();
                fectService();
                fetchSalon();
            } catch (error) {
                // Gestion des erreurs
                console.error("Erreur lors de la suppression :", error);
            }
        };

        // POST

        const handleCloseAdd = () => {
            setOpenAdd(false);
        };
        const handleAddItem = async (e) => {
            e.preventDefault();
            setError("");
            const id = localStorage.getItem("idsalon");
            try {
                const response = await axios.post(
                    `/add_service/salon`,
                    { salon_id: id, category_id, name, description, duration, price },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                fetchCathegory();
                fectService();
                fetchSalon();
                setName('');
                setDesc('')
                setDur('')
                setPrice('')
                setSelectC('')
                setSelectS('')
                // setCategories('')

                // setMessage("ok");
                console.log('ajout ok');
            } catch (error) {
                console.error("Erreur d'inscription", error);
                throw error;
            }
        };

        useEffect(() => {
            fetchCathegory();
            fectService();
            fetchSalon();
        }, []);

        return (
            <Box
                className="main-content d-flex flex-column"
                sx={{ marginTop: '50px', padding: '15px' }}

            >
                <Box >
                    <h1 >
                        Liste des services massages
                    </h1>
                </Box>
<hr />
                <div style={{ padding: 16 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenAdd(true)}
                        sx={{ background: "#F9194F" }}
                    >
                        Ajouter
                    </Button>
                </div>
                <Table   >
                    <TableHead>
                        <TableRow >
                          
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Categories</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Nom du service</TableCell>

                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Duration</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Price</TableCell>
                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {service.length > 0
                            ? service.map((ser) => (
                                <TableRow key={ser.id}>
                                   

                                    {/* Debugging and filtering categories */}

                                    {
                                        categories.filter((cat) => cat.id == ser.category_id)
                                            .length > 0 ? (
                                            categories
                                                .filter((cat) => cat.id == ser.category_id)
                                                .map((cat) => (
                                                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }} key={cat.id}>{cat.name}</TableCell>
                                                ))
                                        ) : (
                                            <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '1rem', md: '15px' } }}>No category found</TableCell>
                                        ) // Debug output when no category matches
                                    }

                                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{ser.name}</TableCell>

                                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{ser.duration}</TableCell>
                                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>{ser.price}</TableCell>
                                    <TableCell sx={{ fontSize: { xs: '0.7rem', sm: '10px', md: '15px' } }}>
                                        <IconButton
                                            onClick={() =>
                                                handleEditItem(
                                                    ser.id,
                                                    idser,
                                                    ser.category_id,
                                                    ser.name,
                                                    ser.description,
                                                    ser.duration,
                                                    ser.price
                                                )
                                            }
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteItem(ser.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                            : ""}


                    </TableBody>
                </Table>

                {/* Modal pour l'ajout d'un nouvel élément */}
                <Dialog open={openAdd} onClose={handleCloseAdd}>
                    <DialogTitle>Ajouter une nouvelle ligne</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleAddItem} className="dispa">
                         
                             
                            
                            <TextField
                                margin="dense"
                                label="Nom du service"
                                fullWidth
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                label="Description"
                                fullWidth
                                variant="outlined"
                                value={description}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                type="time"
                                label="Durée"
                                fullWidth
                                variant="outlined"
                                value={duration}
                                onChange={(e) => setDur(convertTimeFormat(e.target.value))}
                            />
                            <TextField
                                margin="dense"
                                type="number"
                                label="Prix"
                                fullWidth
                                variant="outlined"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="category-select-label">Category</InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={selectC}
                                    onChange={(e) => {
                                        setSelectC(e.target.value);
                                        setCat_id(e.target.value);
                                    }}
                                    label="Category"

                                // onChange={handleChange}
                                >
                                    {categories.map((cat) => (
                                        <MenuItem key={cat.id} value={cat.id}>
                                            {cat.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                className="buton"
                                sx={{ background: "#F9194F" }}
                                onClick={() => setOpenAdd(false)}
                            >
                                Ajouter
                            </Button>
                        </form>
                    </DialogContent>
                </Dialog>

                {/* Modal pour la modification d'un élément existant */}

                <Dialog open={openEdit} onClose={handleCloseEdit}>
                    <DialogTitle>Modification</DialogTitle>
                    <DialogContent  >
                        <form onSubmit={handleUpdateItem} style={{width:'', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '5px', padding: '5px' }} >
                         

                            <TextField
                                margin="dense"
                                label="Name"
                                fullWidth
                                variant="outlined"
                                value={tabvalue.name}
                                onChange={(e) =>
                                    setTabvalue((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                            />
                            <TextField
                                margin="dense"
                                label="Description"
                                fullWidth
                                variant="outlined"
                                value={tabvalue.description}
                                onChange={(e) =>
                                    setTabvalue((prev) => ({
                                        ...prev,
                                        description: e.target.value,
                                    }))
                                }
                            />
                            <TextField
                                margin="dense"
                                type="time"
                                label="Durée"
                                fullWidth
                                variant="outlined"
                                value={tabvalue.duration}
                                onChange={(e) =>
                                    setTabvalue((prev) => ({
                                        ...prev,
                                        duration: convertTimeFormat(e.target.value),
                                    }))
                                }
                            />
                            <TextField
                                margin="dense"
                                type="number"
                                label="Prix"
                                fullWidth
                                variant="outlined"
                                value={tabvalue.price}
                                onChange={(e) =>
                                    setTabvalue((prev) => ({
                                        ...prev,
                                        price: e.target.value,
                                    }))
                                }
                            />
                            <FormControl fullWidth>
                                <InputLabel id="category-select-label">Category</InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={tabvalue.category_id}
                                    onChange={(e) =>
                                        setTabvalue((prev) => ({
                                            ...prev,
                                            category_id: e.target.value,
                                        }))
                                    }
                                    label="Category"
                                >
                                    {categories.map((cat) => (
                                        <MenuItem key={cat.id} value={cat.id}>
                                            {cat.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  gap: '5px', padding: '5px' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="buton"
                                    sx={{ background: "#F9194F" }}
                                >
                                    Envoyer
                                </Button>
                                <Button
                                    onClick={handleCloseEdit}
                                    variant="contained"
                                    className="buton"
                                    sx={{ background: "#F9194F" }}
                                >
                                    Annuler
                                </Button>
                            </div>

                        </form>
                    </DialogContent>
                </Dialog>

                <Dialog open={openDel} onClose={handleCloseDel}>
                    <DialogTitle>Suppression</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">Voulez vraiment supprimer ?</Typography>

                        <Button
                            variant="contained"
                            className="buton"
                            sx={{ background: "#F9194F" }}
                            onClick={handleValidation}
                        >
                            Supprimer
                        </Button>
                        <Button
                            onClick={handleCloseDel}
                            variant="contained"
                            className="buton"
                            sx={{ background: "#F9194F", marginLeft: "300px" }}
                        >
                            Annuler
                        </Button>
                    </DialogContent>
                </Dialog>
            </Box>
        );
    };

export default Servicesalon;
