import { Box, colors, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import clientImg from "../../static/images/client.png";
import CustomParagraph from "../../CustomParagraph/CustomParagraph";
import { Link } from "react-router-dom";
import RatingStars from "./RatingStars/RatingStars";

const CarouselContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
}));

const FlexArea = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 5,
}));

const Reviews = () => {
  return (
    <CarouselContainer>
      <Box
        sx={{
          width: "50%",
        }}
      >
        <FlexArea>
          <img src={clientImg} alt="Example Image" loading="lazy" />
          <Box>
            <FlexArea>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Rapha
              </Typography>
              <RatingStars value={4} />
            </FlexArea>
            <CustomParagraph>Paris, France</CustomParagraph>
          </Box>
        </FlexArea>
        <CustomParagraph>
          J'ai adoré, l'endroit est propre, les masseuse sont super doué!
        </CustomParagraph>

        <Link to={"/"}>20 Réponses</Link>
      </Box>
      <Box
        sx={{
          width: "50%",
        }}
      >
        <FlexArea>
          <img src={clientImg} alt="Example Image" loading="lazy" />
          <Box>
            <FlexArea>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Rapha
              </Typography>
              <RatingStars value={5} />
            </FlexArea>
            <CustomParagraph>Paris, France</CustomParagraph>
          </Box>
        </FlexArea>
        <CustomParagraph>
          J'ai adoré, l'endroit est propre, les masseuse sont super doué!
        </CustomParagraph>

        <Link to={"/"}>20 Réponses</Link>
      </Box>
    </CarouselContainer>
  );
};

export default Reviews;
