
import { useEffect, useState } from "react";
import "./Auth.css";
import { NavLink } from "react-router-dom";
import Login from "../Compo/Login/Login";
import { logout } from "../../authentification/Authentificationl";
import {useNavigate} from 'react-router-dom';
function AuthConnect({val}) {
  const [token,Settoken]=useState(false);
  const navigate = useNavigate();
   const eventt=document.querySelector('#eventt')
   const eventt2=document.querySelector('#eventt2')
   const login=document.querySelector('.login')
 
  useEffect(() => {
  Settoken(localStorage.getItem('token'))
 
  }, [token]);
 

 const [showLog,Setlogin]=useState(false);
 function showLogin(){
  Setlogin(true)
  if(login){
    login.style.display='flex';
   }
   
 }
 function hideLogin(){
  Setlogin(false)
  if(login){
   login.style.display='none';
  }
  
 
 }
 function deconecter() {
  // Appelle la fonction de déconnexion
  logout(); 

  // Efface les tokens stockés dans localStorage (ou sessionStorage)
  localStorage.removeItem("token"); // Supprime le token d'authentification
  localStorage.removeItem("userId"); // Supprime l'ID utilisateur
  sessionStorage.clear(); // Supprime tous les tokens de session (si utilisés)

  // Change l'état du token à false pour indiquer que l'utilisateur est déconnecté
  Settoken(false);

  // Redirige vers la page d'accueil après déconnexion
  navigate('/');

  // Masque les éléments d'interface associés à l'utilisateur
  if (eventt) {
    eventt.style.display = 'none'; // Cache l'élément eventt si existant
  }

  if (eventt2) {
    eventt2.style.display = 'none'; // Cache l'élément eventt2 si existant
  }
}

 const auth=document.querySelector('.auth');
 const fermm=document.querySelector('.ferm');
    function fermer(){
      if(auth){
        auth.style.transform='translateX(-100vw)';
       }
       if(fermm){
        fermm.style.display='none';
       }
    }
  return (
 <>
 <div className="auth">

       <i  onClick={fermer} className="ti-close ferm"></i>
             
                  <ul>
                  <li>
                    <img alt="fr" src="/image/france.png" />
                  </li>
                  <li className="devpro">
                    <NavLink
                   
                      to="/tarif"
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                    >
                      Devenir Pro
                    </NavLink>
                  </li>
                 
                  {token?
               <li className='conn' >
                         <p className="connecter" onClick={deconecter}>Déconnexion </p>   
                  </li>
                  :''
                 /* <li className='conn' onClick={showLogin}>
                   <p className="connecter" > Se connecter</p> 
                  </li>*/}
               
                </ul>
    </div>
    {showLog || val?
    <div className="login" >
      <div className="transparent" onClick={hideLogin}></div>
       <Login/>
    </div>:''
  }
 </>
  );
}
export default AuthConnect;
