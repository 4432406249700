import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

const Accordeon = ({ title, description }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        background: "#f8f8f8",
        marginBottom: 1,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 3,
        borderTopWidth: 0,
        "&::before": {
          backgroundColor: "transparent",
        },
      }}
      expanded={expanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography>{title ?? "Accordion Title"}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{description ?? `Massage réciproque`}</Typography>
          <PrimaryButton>Reserver</PrimaryButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default Accordeon;
