import { React, useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from "@mui/material";

function TableRdv() {
  const [error, setError] = useState("");
  const [datas, setDatas] = useState([]);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [couleur, setCouleur] = useState("attend");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  // const [statuss,setStatuss] = useState(true)
  // Appel des donnee:

  const fetchDatas = async () => {
    try {
      const response = await axios.get(`/appointments_masseur`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //  console.log("appointments", response.data.appointments);
      setDatas(response.data.appointments);
    } catch (error) {
      //  console.error("Erreur de récupération des pays", error);
      setError("Erreur de récupération des pays.");
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);
  //console.log("datas", datas);

  const handleDelete = (id) => {
    // console.log(`Delete row with id: ${id}`);
    // Logic to delete the row
  };

  const handleUpdate = (id) => {};
  // const getStatusColor = (id) => {
  //   // return selectedIds.includes(id) ? "#43A047" : "gray";
  // };

  const acceptedAppointment = async (id) => {
    // setStatuss(false)
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // Retirer l'ID si déjà sélectionné
        return prevSelectedIds.filter((itemId) => itemId !== id);
      } else {
        // Ajouter l'ID s'il n'est pas déjà sélectionné
        return [...prevSelectedIds, id];
      }
    });

    try {
      const response = await axios.post(
        `/appointments/${id}/accept`,
        {}, // Corps de la requête, vide dans ce cas
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Réponse reçue:", response.data);
      fetchDatas();
      // return response.data;
    } catch (error) {
      console.error("Erreur d'inscription", error);
      throw error;
    }
  };

  const cancelAppointment = async (id) => {
    // setStatuss(false)
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // Retirer l'ID si déjà sélectionné
        return prevSelectedIds.filter((itemId) => itemId !== id);
      } else {
        // Ajouter l'ID s'il n'est pas déjà sélectionné
        return [...prevSelectedIds, id];
      }
    });

    try {
      const response = await axios.post(
        `/appointments/${id}/cancel`,
        {}, // Corps de la requête, vide dans ce cas
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Réponse reçue:", response.data);
      fetchDatas();
      // return response.data;
    } catch (error) {
      console.error("Erreur d'inscription", error);
      throw error;
    } 
  };
  // 
  return (
    <TableContainer
      className="main-content d-flex flex-column"
      component={Paper}
      sx={{
        width: { xs: "0%", sm: "80%", md: "100%" }, // Table responsive
        overflowX: "auto",
        marginLeft: { xs: "0px", sm: "130px", md: "0px" }, // Taille de texte responsive dans la table
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "0px", sm: "10px", md: "20px" },
          marginLeft: { xs: "0px", sm: "20px", md: "15px" },
        }}
      >
        Liste des rendez-vous{" "}
      </Typography>
      <Table sx={{ marginTop: "20px" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Nom du Client
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Service rendez-vous
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Date
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Heure
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Prix
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Status
              </Typography>
            </TableCell>
            <TableCell sx={{ background: "#F9194F" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "0.7rem", sm: "10px", md: "15px" },
                }}
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.length === 0 ? (
            <TableRow>
              <TableCell
                sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                colSpan={6}
                align="center"
              >
                Aucun rendez-vous
              </TableCell>
            </TableRow>
          ) : (
            datas.map((data) => (
              <TableRow key={data.id}>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  {data.client.name}
                </TableCell>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  {data.service.name}
                </TableCell>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  {data.date}
                </TableCell>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  {data.start_time}
                </TableCell>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  {data.service.price}
                </TableCell>
                <TableCell
                  sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                >
                  <Box
                    sx={{
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    {data.status}
                  </Box>
                </TableCell>
                {data.status === "accepted" ||
                data.status === "canceled" ? null : (
                  <TableCell
                    sx={{ fontSize: { xs: "0.7rem", sm: "10px", md: "15px" } }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => acceptedAppointment(data.id)}
                      style={{ marginRight: "8px", background: "#43A047" }}
                    >
                      Accepter
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ background: "#F9194F" }}
                      onClick={() => cancelAppointment(data.id)}
                    >
                      Refuser
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableRdv;
