import React from 'react'
import axios from 'axios';
import { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Favoris.css'
export default function Favoris() {
  const [events, setEvents] = useState([]);
  let [fav,setfav]=useState([])
  const fetchquestion = async () => {
  let token=localStorage.getItem('token')
  try{
    const response = await axios.get("https://www.api-macerise.masovia-madagascar.com/api/favoris_client"
      ,
      {
          headers: {
              'Authorization': `Bearer ${token}`,
          },
      }
  );
 setfav(response.data.favorites_client);
 //console.log(response.data.favorites_client)
  }catch(err){
    console.log(err)
  }
 
};


useEffect(() => {
    fetchquestion();
}, []);
  return (<div>
   <div className='ppp'>Listes Favoris</div>
   <div className='EtablissementImgx'>
   {

fav ?
      fav.map((salon, key) => {
            return <div className='cardd' key={salon.id}>
                  <img src='./image/cheri1.png' alt='' ></img>
               
                  <div className='description'>
                                                      <p className='titre'>{salon.name}</p>
                                                      <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                                                      <p className='payse'>{salon.city}</p>
                                                      <p className='expert'>Chez votre expert</p>
                                                      <hr></hr>

                                                      <NavLink
                                                         className='plusdetaille'
                                                            to={`/detailsalon/${salon.id}`}
                                                          
                                                      >
                                                         

                                                                  <p>

                                                                        Je veux en savoir plus
                                                                 
                                                            </p>
                                                            <i className='ti-angle-down'></i>

                                               
                                                </NavLink>
                                          </div>

            </div>

      })
: 'loading....'
}
  </div>
  </div>
   
  )
}
