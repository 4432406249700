// src/App.js
import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Input
} from "@mui/material";

import { AddAPhoto } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import './Imagesalon.css'
// import './Salon.css'
import { Delete, Edit, CheckCircle, Visibility } from "@mui/icons-material";
import axios from "axios";
import { Margin } from "@mui/icons-material";
import "../Calendrier/cal.css";
import "./Salon.css";
axios.defaults.baseURL = "https://www.api-macerise.masovia-madagascar.com/api";
const Imagesalon = () => {
    const [truee, settrue] = useState(false);
    const [validation, setvalidation] = useState(false);
    const [voire, setvoire] = useState(false);
    const [falsee, setfalsee] = useState(false);
    const [load, setload] = useState(false);
    const [fermer, setferm] = useState(false);
    const [messagex, setmessagex] = useState("");
    const [messagexa, setmessagexa] = useState("");
    const [events, setEvents] = useState([]);
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const messagexx = document.querySelector(".popx");
    const messagexxf = document.querySelector(".popxf");
    const [jour, setjour] = useState("");
    const [selectrolef, setSelectrolef] = useState("");
    const [names, setnames] = useState("");
    const [salonid, setsalonid] = useState([]);
    const navigate = useNavigate();
    //const [midi, setmidi] = useState(false);
    const [valid, setvalid] = useState(false);
    const [countries, setCountries] = useState([]);
    const fetchEventssalon = async () => {
        let id_users = localStorage.getItem("userId");
        const id = localStorage.getItem("idsalon");
        // console.log(id_salon)
        try {
            const response = await axios.get(
                "https://www.api-macerise.masovia-madagascar.com/api/get/salon/user/" +
                id_users
            );
            setsalonid(response.data.salons);
            // console.log(response.data.salons[0].id)
        } catch {
            console.log("erreur");
        }

        //console.log(response.data.salons[0].id)
    };
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get("/countries");
                setCountries(response.data);

            } catch (error) {
                console.error("Erreur de récupération des pays", error);
                // setError("Erreur de récupération des pays.");
            }
        };
        fetchCountries();
        fetchEventssalon()
    }, []);
    const fetchEvents = async () => {
        // console.log(salonid);
        // let idsalon=salonid[0].id ;
        try {
            const response = await axios.get(
                "https://www.api-macerise.masovia-madagascar.com/api/salons_masseur",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data);

            if (response.data) {
                // Décommentez la ligne ci-dessous si vous souhaitez définir l'état des événements
                setEvents(response.data.salons);
                console.log(response.data.salons);
            } else {
                //     console.error('Aucune donnée disponible dans la réponse.');
            }
        } catch (error) {
            // Gérer les erreurs ici
            console.error("Erreur lors de la récupération des événements:", error);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);
    const [contryf, setCountriesf] = useState('');
    const [form, setForm] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",

    });
    const [contryf10, setCountriesf10] = useState('');
    const [form10, setForm10] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",
        id: ''

    });
    const [form5, setForm5] = useState({

        name: "",
        address: "",
        apropos: "",
        description: "",
        city: "",
        country: '',
        image: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };
    const handleChange10 = (e) => {
        const { name, value } = e.target;
        setForm10((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };
    const handleChangeday_id = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id = (e) => {
        //console.log(e.target.value)
        setForm((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus = (e) => {
        setForm((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    const handelOpen = (name, city, country, address, apropos, description, image) => {
        setvoire(true)
        setForm5((prevForm) => ({
            ...prevForm,
            name: name,
            address: address,
            apropos: apropos,
            description: description,
            city: city,
            country: country,
            image: image
        }));


    };
    const [form2, setForm2] = useState({
        day_id: "",
        start_time: "",
        end_time: "",
        salon_id: '',
        status: "",
    });
    const [form90, setForm90] = useState({
        salon_id: '',

    });
    const handleChangeday_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,
            day_id: parseInt(e.target.value),
        }));
    };
    const handleChangestart_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        //console.log(endf)
        setForm2((prevForm) => ({
            ...prevForm,

            start_time: formatTime(e.target.value),
        }));
    };
    const handleChangeend_time2 = (e) => {
        const formatTime = (time) => {
            // Añadir ":00" al final de la cadena de tiempo.
            return `${time}:00`;
        };
        setForm2((prevForm) => ({
            ...prevForm,

            end_time: formatTime(e.target.value),
        }));
    };
    const handleChangesalon_id2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            salon_id: parseInt(e.target.value),
        }));
    };
    const handleChangestatus2 = (e) => {
        setForm2((prevForm) => ({
            ...prevForm,

            status: e.target.value,
        }));
    };
    const [form3, setForm3] = useState({
        timeid: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(form)

        try {
            const res = await axios.post(
                "https://www.api-macerise.masovia-madagascar.com/api/add_salon",
                {
                    name: form.name,
                    address: form.address,
                    description: form.description,
                    apropos: form.apropos,
                    city: form.city,
                    country_id: contryf
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setmessagex("ajout avec success day ");
            setForm(() => ({

                name: "",
                address: "",
                description: "",
                apropos: "",
                city: "",

            }));
            setCountriesf('')
            setfalsee(false);
            if (messagexxf) {
                messagexxf.style.display = "none";
            }
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
            //  console.log(res)
        } catch (error) {
            console.error(
                "Erreur lors de l'envoi des données à l'API:",

            );

            settrue(false);
            if (messagexx) {
                messagexx.style.display = "none";
            }

            setfalsee(true);
            if (messagexxf) {
                messagexxf.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexxf) {
                    messagexxf.style.display = "none";
                }
            }, 6000);
        }
        fetchEvents();
        setferm(false)

    };
    const forma = (time) => {
        // Vérifier si le format d'entrée est valide
        const timeParts = time.split(":");
        if (timeParts.length === 3) {
            return `${timeParts[0]}:${timeParts[1]}`; // Retourner les heures et les minutes
        }
        return time; // Retourner la chaîne d'origine si le format est incorrect
    };

    const handleEdit = async (id, name, city, country_id, address, apropos, description) => {
        setmidi(true);

        setCountriesf10(country_id)
        setForm10((prevForm) => ({
            ...prevForm,
            id: id,
            name: name,
            address: address,
            apropos: apropos,
            description: description,
            city: city,

        }));
    };
    const formatTime = (time) => {
        // Añadir ":00" al final de la cadena de tiempo.
        return `${time}:00`;
    };
    const shandleEdit = async (e) => {
        e.preventDefault();
        setmidi(false);
        let eventidd = form10.id;
        console.log(eventidd);
        try {
            const res = await axios.put(
                "https://www.api-macerise.masovia-madagascar.com/api/update_salon/" +
                eventidd,
                {
                    name: form10.name,
                    address: form10.address,
                    description: form10.description,
                    apropos: form10.apropos,
                    city: form10.city,
                    country_id: contryf10
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setmessagex("modification avec success");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }
            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
            console.log(res);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }
        fetchEvents();
    };
    const [idsup, setidsup] = useState();
    function handleDeletex(ideventx, name) {
        setvalid(true);
        setidsup(ideventx);
        setnames(name);
    }
    const handleDelete = async () => {
        //console.log(ideventx)
        setvalid(false);
        let ideventx = idsup;

        try {
            const res = await axios.delete(
                "https://www.api-macerise.masovia-madagascar.com/api/delete/salon/" +
                ideventx,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès ");
            settrue(true);
            if (messagexx) {
                messagexx.style.display = "flex";
            }

            setTimeout(() => {
                if (messagexx) {
                    messagexx.style.display = "none";
                }
            }, 6000);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }

        // Rafraîchir les événements après la suppression
        fetchEvents();
    };

    const [midi, setmidi] = useState(false);
    const [photoc, setphoto] = useState(false);
    const [more, setmore] = useState(false);
    const [photoplus, setphotoplus] = useState(false);
    const [selectedSalon, setSelectedSalon] = useState(false);
    const [selectedSalonplus, setSelectedSalonplus] = useState(false);
    const [selectedmore, setSelectedmore] = useState(false);
    const [gerer, setgerer] = useState(false);
    const [selectedSalonid, setSelectedSalonid] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [idsalon, setidsalon] = useState(null);


    const handleChangesalonid = (e) => {
        e.preventDefault()
        setidsalon(e.target.value);
        setSelectedSalon(true)
    };
    const handleChangesalonidplus = (e) => {

        setidsalonplus(e.target.value);
        setSelectedSalonplus(true)
    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = async (event) => {
        event.preventDefault()
        const id = localStorage.getItem("idsalon");
        setload(true)
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
            const fileURL = URL.createObjectURL(file);
            setImagePreview(fileURL); // Set the preview URL for the image

            // Préparez les données pour l'upload
            const images = new FormData();
            images.append("images", file); // 'image' doit correspondre à ce que l'API attend

            let token = localStorage.getItem("token");

            try {
                const response = await axios.post(`uploadImage_salon/` + id, images);
                Getsalon()
                console.log("Upload success:", response.data);
                // Peut-être mettre à jour l'état ici si nécessaire pour afficher la nouvelle image
                setSelectedSalon(false)
                setidsalon('')
                setmessagex("ajout avec succès ");
                settrue(true);
                if (messagexx) {
                    messagexx.style.display = "flex";
                }

                setTimeout(() => {
                    if (messagexx) {
                        messagexx.style.display = "none";
                    }
                    settrue(false)
                }, 4000);
                setload(false)
            } catch (error) {
                console.error("Error uploading file:", error);
                // Gérer les erreurs de manière approprié
            }
        }
    };
    const [idsalonplus, setidsalonplus] = useState(null);
    const [idmore, setidmore] = useState(null);
    const [selectedFilesplus, setSelectedFilesplus] = useState([]);
    const [imagePreviewsplus, setImagePreviewsplus] = useState([]);

    const handleFileChangeplus = async (event) => {
        event.preventDefault()
       
        const id = localStorage.getItem("idsalon");
        setload(true)
        const files = Array.from(event.target.files); // Récupérer tous les fichiers sélectionnés

        if (files.length > 0) {
            // Mettre à jour l'état avec les fichiers sélectionnés
            setSelectedFilesplus(files);

            // Créer une URL pour chaque image pour l'aperçu
            const fileURLs = files.map(file => URL.createObjectURL(file));
            setImagePreviewsplus(fileURLs); // Mettre à jour l'aperçu des images

            // Préparer les données pour l'upload
            const images = new FormData();
            files.forEach(file => {
                images.append("image[]", file); // Assurez-vous que le backend attend un tableau d'images
            });


            try {
                const response = await axios.post(`salons/${id}/add_images`, images

                );

                console.log("Upload success:", response.data);
                setSelectedSalonplus(false);
                setidsalonplus('');
                setmessagex("ajout avec succès");
                settrue(true);
                setload(false)
                gallery(id)
             
                if (messagexx) {
                    messagexx.style.display = "flex";
                }

                setTimeout(() => {
                    if (messagexx) {
                        messagexx.style.display = "none";
                    }
                    settrue(false);
                }, 4000);

            } catch (error) {
                console.error("Error uploading file:", error);
                // Gérer les erreurs de manière appropriée
                setmessagex("Erreur lors de l'ajout des images");
                settrue(true);
            }
        }
    };

    const Input = styled('input')({
        display: 'none',
    });
    const handleChangemore = (e) => {
        e.preventDefault()
        console.log(e.target.value)
        setload(true)
        setidmore(e.target.value);
        setSelectedmore(true)
        gallery(e.target.value)
    };
    const handleChangegerer = (e) => {
        e.preventDefault()
        const linkedInShareLink = `http://localhost:3000/dashboardPro/salon/sitting`;
        localStorage.setItem('idsalon', e.target.value)

        window.open(linkedInShareLink, '_blank');
        // navigate('/dashboardPro/salon/sitting')
    };
    const [tabimage, settab] = useState([]);
    const [firstimg, setfirst] = useState('');
    const gallery = async () => {
        const id = localStorage.getItem("idsalon");
        let token = localStorage.getItem('token');
        settab('')
        // Check if token exists
        if (!token) {
            console.error('No authorization token found.');
            return; // Exit the function if there's no token
        }

        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/salons/${id}/pictures`,


            );
            setload(false)
            console.log(response.data);
            // Optionally handle state update here
            settab(response.data)
            setfirst(response.data[0].image)
        } catch (err) {
            console.error('Error occurred:', err);
        }
    };
    const [idimage, setidimage] = useState('');
    const supprimer = async (idm) => {
        try {
            const res = await axios.delete(
                "https://www.api-macerise.masovia-madagascar.com/api/delete_images/" + idm,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log(res);
            setmessagex("supprimé avec succès ");
            settrue(true);
            settab((prevImages) => prevImages.filter(image => image.id !== idm)); // Met à jour l'état pour ne pas inclure l'image supprimée

            setTimeout(() => {
                settrue(false);
            }, 4000);
        } catch (error) {
            console.error("Erreur lors de l'envoi des données à l'API:", error);
        }
    };
    const [namesalon, setnamesalon] = useState('');
    const [couverture, setcouverture] = useState('');
    const Getsalon = async () => {
        const id = localStorage.getItem("idsalon");
        let token = localStorage.getItem('token');
        setcouverture('')
        // Check if token exists
        if (!token) {
            console.error('No authorization token found.');
            return; // Exit the function if there's no token
        }

        try {
            const response = await axios.get(
                `https://www.api-macerise.masovia-madagascar.com/api/salon/${id}`,

            );
            setload(false)
            console.log(response.data.salon.images);

            // Optionally handle state update here
        setcouverture(response.data.salon.images)
        setnamesalon(response.data.salon.name)
            //setfirst(response.data[0].image)
        } catch (err) {
            console.error('Error occurred:', err);
        }
    };
    useEffect(() => {
        gallery()
        Getsalon()
    }, [])
    return (
        <>
            {selectedSalonplus ? (
                <div style={{ position: 'fixed', zIndex: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', backgroundColor: 'rgba(17, 14, 14, 0.925)' }}>
                    <label className="labesalon">
                        <Input accept="image/*" id="file-upload" multiple type="file" onChange={handleFileChangeplus} />
                        <Button variant="contained" component="span" sx={{
                            backgroundColor: '#F9194F', color: 'white', '&:hover': {
                                backgroundColor: '#F9194F', // Couleur de fond lors du hover
                                color: 'white' // Couleur du texte lors du hover
                            },
                        }}>
                            <Typography variant="button" color="inherit">
                                Ajouter des images
                            </Typography>
                        </Button>
                    </label>
                    <p style={{ right: '20px', height: '40px', width: '40px' }} className="fermm" onClick={() => {
                        setSelectedSalonplus(false)

                    }}>
                        x
                    </p>
                </div>
            ) : ''}
            {load ? <div className='loading'> <CircularProgress sx={{
                position: 'fixed',
                top: '100px',
                zIndex: '700'
            }} /></div> : ''}
            {valid ? (
                <div className="validation">
                    <span className="spann">
                        <p>Voulez-vous vraiment le supprimer? </p>
                        <span>
                            <button onClick={handleDelete}>OUI</button>
                            <button onClick={() => setvalid(false)}>NON</button>
                        </span>{" "}
                    </span>{" "}
                </div>
            ) : (
                ""
            )}
            {truee ? <div className="popx">{messagex}</div> : ""}
            {falsee ? <div className="popxf">{messagexa}</div> : ""}

            {fermer ? <div className="transalonx"></div> : ''}
            {midi ? <div className="transalonx"></div> : ''}
            {voire ? <div className="transalonx"></div> : ''}
            {selectedSalon ? (
                              <div style={{ position: 'fixed', zIndex: '500', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', backgroundColor: 'rgba(17, 14, 14, 0.925)' }}>
                                    <label>
                                        <Input accept="image/*" id="file-upload" type="file" onChange={handleFileChange} />
                                        <Button variant="contained" component="span" sx={{
                                            backgroundColor: '#F9194F', color: 'white', '&:hover': {
                                                backgroundColor: '#F9194F', // Couleur de fond lors du hover
                                                color: 'white' // Couleur du texte lors du hover
                                            },
                                        }}>
                                            <Typography variant="button" color="inherit">
                                            Modifier photo de couverture
                                            </Typography>
                                        </Button>
                                    </label>
                                    <p style={{ zIndex: '100',right:'20px',width:'50px',height:'50px' }} className="fermm" onClick={() => setSelectedSalon(false)}>
                                x
                            </p>
                                </div>
                            ) : ''}

            <div className="couverture">
                <img src={`https://api-macerise.masovia-madagascar.com/storage/salon_images/${couverture}`} alt="" />
                <h3 style={{width:'90vw',textAlign:'center'}}>{namesalon}</h3>
                <div className="modifier" onClick={()=>setSelectedSalon(true)} >
                    <AddAPhoto
                        sx={{ color: "white", cursor: "pointer", fontSize: "24px" }}
                    />
                    Modifier Photo de couverture
                </div>

            </div>
            <div className="ajoutphoto">

                <p className="titresalon">Plus de photos</p>
                <div className="imgsalon">
                    <div onClick={() => setSelectedSalonplus(true)} className="ajoutsalonphoto">
                        <span className=' bur'><AddIcon sx={{ color: 'white', fontSize: '5vw' }} /></span> <span className='phoner' style={{ color: 'white', fontSize: '13px' }}><AddIcon sx={{ color: 'white', fontSize: '15px' }} />Ajouter des photos</span>
                    </div>
                    {tabimage && tabimage.map((rol) => (
                        <div className="imagesalonimg" id={`A${rol.id}`} key={rol.id} style={{ position: 'relative' ,boxShadow:'1px 1px 10px gray'}}>
                            <p style={{ zIndex: '100' }} className="fermm" onClick={() => supprimer(rol.id)}>
                                x
                            </p>
                            <img
                                className='imageggs'
                                src={`https://api-macerise.masovia-madagascar.com/storage/${rol.image}`}
                                alt=""
                                style={{ }} />
                        </div>
                    ))}


                </div>
            </div>

        </>
    );
};

export default Imagesalon;
