// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/image/image accueil.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.cardbody{
    position: relative;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-position: center;
    background-size: cover;
  min-width: 500px;
} 
.divm{
    background-color: rgba(214, 208, 208, 0.151);
   margin-top: -80px;
}
.css-z9dkhk-MuiStack-root{
    min-height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/component/card/Card.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;AACA;IACI,kBAAkB;IAClB,6DAAqE;IACrE,2BAA2B;IAC3B,sBAAsB;EACxB,gBAAgB;AAClB;AACA;IACI,4CAA4C;GAC7C,iBAAiB;AACpB;AACA;IACI,iBAAiB;AACrB","sourcesContent":["*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n.cardbody{\n    position: relative;\n    background: url('../../../public/image/image\\ accueil.jpg') no-repeat;\n    background-position: center;\n    background-size: cover;\n  min-width: 500px;\n} \n.divm{\n    background-color: rgba(214, 208, 208, 0.151);\n   margin-top: -80px;\n}\n.css-z9dkhk-MuiStack-root{\n    min-height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
