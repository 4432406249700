import React from 'react'
import { useState } from 'react';
import { salon_recent } from '../../authentification/Authentificationl';
import './NouveauPro.css'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
export default function NouveauPro() {
      const [salon, setsalon] = useState([]);
      const [lengths, setlength] = useState([]);
      const funsalon = async () => {


            try {
                  const userData = await salon_recent();

                  setsalon(userData.salons);
                  setlength(salon)
              //    console.log('Connexion réussie', userData.salons);
                  // Vous pouvez rediriger l'utilisateur ou rafraîchir le state ici

            } catch (error) {
                  console.log(error)

            }
      };
      useEffect(() => {
            funsalon();
      }, []);
      return (
         
            <div className='NeauveauPro'>
                  <h4> Nouveaux Pro</h4>
                  <div className='NeauveauProImg'>
                        {salon ? salon.map((event,key) => (
                         <NavLink   
                         key={key}
                         to={`/detailsalon/${event.id}`}>
                              <div className='card' key={event.id}>
                                    <div className='suivre'>
                                          <p>+  Suivre</p>
                                    </div>
                                    <div className='logoPro'>
                                          <p><img src='./image/logo1.png' alt='' ></img></p>
                                    </div>
                                    <img src='./image/cherie3.jpg' alt='' className='font '></img>
                                    <div className='description'>
                                          <p className='titre'>{event.name}</p>
                                    </div>
                              </div>
                              </NavLink>
                        )) : ''}
                   
                    
                   
                  </div>
            </div>
          
      )
}
