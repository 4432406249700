
import { useEffect, useState } from "react";
import "./Auth.css";
import { NavLink } from "react-router-dom";
import Login from "../Compo/Login/Login";
import { logout } from "../../authentification/Authentificationl";
import {useNavigate} from 'react-router-dom';
function Auth({val}) {
  const [token,Settoken]=useState(false);
  const navigate = useNavigate();
   const eventt=document.querySelector('#eventt')
   const eventt2=document.querySelector('#eventt2')
   const login=document.querySelector('.login')
// const logins=document.querySelector('.login')
  useEffect(() => {
  Settoken(localStorage.getItem('token'))
 
  }, [token]);
 

 const [showLog,Setlogin]=useState(false);
 function showLogin(){
 // Setlogin(true)
  if(login){
    login.style.display='flex';
   }
   
 }
 function hideLogin(){
 // Setlogin(false)
  if(login){
   login.style.display='none';
  }
  
 
 }
 function deconecter(){
  logout()
 Settoken(true)
 navigate('/');

 if(eventt){
  eventt.style.display='none';
  
 }
 if(eventt2){
  eventt2.style.display='none';
  
 }
 }
 const auth=document.querySelector('.auth');
 const fermm=document.querySelector('.ferm');
    function fermer(){
      if(auth){
        auth.style.transform='translateX(-100vw)';
       }
       if(fermm){
        fermm.style.display='none';
       }
    }
  return (
 <>
 <div className="auth">

       <i  onClick={fermer} className="ti-close ferm"></i>
             
                  <ul>
                  <li>
                    <img alt="fr" src="/image/france.png" />
                  </li>
                  <li className="devpro">
                    <NavLink
                   
                      to="/tarif"
                      className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      }
                    >
                      Devenir Pro
                    </NavLink>
                  </li>
                 
                  {token?''
                  :
                  <li className='conn' onClick={showLogin}>
                   <p className="connecter" > Se connecter</p> 
                  </li>}
                </ul>
    </div>
    <div className="login" >
   
       <Login dat={1}/>
    </div>
 
 </>
  );
}
export default Auth;
