import React, { useEffect } from 'react'
import './TempleteFiltre.css'
import { Box, colors, Container, Grid, Typography } from "@mui/material";
import Footer from '../outil/Footer'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import NavFiltre from '../Navigation/NavFiltre'
import { useLocation } from 'react-router-dom';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const HorizontalLine = () => {
     return (
       <Box
         sx={{
           width: "100%",
           height: "1px",
           backgroundColor: "divider",
           mt: 3,
           mb: 3,
         }}
       />
     );
   };
   
export default function TempleteFiltre() {

     
     const mapContainerStyle = {
          height: "400px",
          width: "800px"
     };

     const center = {
          lat: 48.8566, // Paris
          lng: 2.3522
     };

     const { name, country, date } = useParams();

     const [results, setResults] = useState([]);
     const [error, setError] = useState('');

     const useQuery = () => {
          return new URLSearchParams(useLocation().search);
     };

     const query = useQuery();
     const param1 = query.get('name'); // Remplacez 'param1' par le nom du paramètre que vous recherchez
     const param2 = query.get('country'); // Même chose pour d'autres paramètres
     const param3 = query.get('date'); // Remplacez 'param1' par le nom du paramètre que vous recherchez


     const [aucun, setaucun] = useState(false);

     const handleSearch = async () => {
          setError('');

          try {
               const response = await axios.get(`https://www.api-macerise.masovia-madagascar.com/api/search-salons`, {
                    params: {
                         name: param1,
                         city: param2,
                         date: param3

                    }
               });
               /*     if(response.data.data.length=0){
                            setaucun(true);
                       }else{
                            setaucun(false);
                       }*/
               setResults(response.data.data);
               //  console.log(response.data.data); // Adaptez ceci à la structure de votre API
               // console.log(param1)
          } catch (err) {
               setError('Something went wrong!');
               console.error(err);
          }
     };


     useEffect(() => {

          handleSearch()

          // console.log('Params:', { name, country, date }); // Ajoutez cette ligne

     }, [param1, param2, param3])






     function showLocal() {
          const mapp = document.querySelector('.mapp')
          const btnfiltre = document.querySelector('.btnfiltre')
          if (mapp) {
               mapp.style.display = 'flex'
          }
          if (btnfiltre) {
               btnfiltre.style.display = 'none'
          }
     }
     useEffect(() => {
          window.scroll({ top: 0 })
     }, [])

     return (
          <>
               <NavFiltre />
               <div className='TempleteFiltre'>

                    <p><i className='ti-home'></i>  Accueil  {results.length > 0 ? '>' + results[0].city + ' >  ' + results[0].address + ' > ' + results[0].name : ''}</p>
                    {

                         results.length > 0 ?
                              results.map((salon, key) => {
                                   return (

                                        <div className='cardsFiltre' key={key}>

                                             <div className='leftFiltre'>
                                                  <h4>{salon.name}</h4>
                                                  <div className='imgFiltre'>
                                                       <img src='/image/baby1.png' alt='' className='oneImg' ></img>
                                                       <img src='/image/baby2.png' alt='' className='towImg'></img>
                                                       <img src='/image/baby3.png' alt='' className='freeImg' ></img>
                                                  </div>
                                                  <div className='descriptionfiltre'>
                                                       <p className='titre'>{salon.name}</p>
                                                       <p className='avis'><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i><i className='ti-star'></i> 99 avis</p>
                                                       <p className='payse'>{salon.city} </p>
                                                       <p className='expert'><i className='ti-home'></i> Chez votre expert</p>

                                                  </div>
                                                  <div className='filtrejour'>
                                                       <div className='filtresem1'>
                                                            <h4>MATIN</h4>
                                                            <button className='select'>Mar 23</button>
                                                            <button className='select'>Mer 24</button>
                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>APRES-MIDI</h4>
                                                            <button className='select'>Mar 23</button>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                       <div className='filtresem1 '>
                                                            <h4>SOIR</h4>

                                                            <button className='select'>Jeu 25</button>
                                                            <button className='select'>Ven 26</button>
                                                       </div>
                                                  </div>






                                             </div>

                                        </div>



                                   )
                              })
                              : '..............'
                    }
                    <div className='rightFiltre'>
                   

                        

                         <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9503398796586!2d-73.98531003867917!3d40.7594371793141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f711cf39%3A0x6c38ba916571686d!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1624681500506!5m2!1sen!2sus"
                              width="100%"
                              height="500px"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                         />

                     

                    </div>
                    {aucun ? <p>salon non trouver</p> : ''}

               </div>
               <Footer />
          </>
     )
}
